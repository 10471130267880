
<!-- For larger screens -->
<div class="d-none d-md-block">

  <div class="sidebar d-flex flex-column flex-shrink-0 pt-6  px-3" style="width: 250px;">
    <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
        <!--<span class="fs-4">Sidebar</span> -->
    </a>
 
    <h2 class="pt-20">Filters</h2>

    <div *ngIf="showPriceRange">

      <div class="form-group">
        <label for="priceRange"><b>Price Range < {{currencyCode}}:</b> {{ convertCurrency('INR',currencyCode,price)| number:'1.0-0' }}</label>
        <div class="d-flex justify-content-between pt-2">
            <span>{{ convertCurrency('INR',currencyCode,minValue)| number:'1.0-0' }}</span>
            <input type="range" class="form-control-range" id="priceRange" [(ngModel)]="price" (change)="onFilterChange($event)" min="{{minValue}}" max="{{maxValue}}" step="10">
            <span>{{ convertCurrency('INR',currencyCode,maxValue) | number:'1.0-0' }}</span>
        </div>
      </div>
    </div>
    

    <div *ngIf="showRatingCheckboxes">

      <form>
        <div class="form-group pt-10">
          <label><h3><b>Select Rating: </b> </h3></label>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="threeStars" [(ngModel)]="threeStar" name="threeStar" (change)="onFilterChange($event)">
            <label class="form-check-label" for="threeStars">
              3 Stars
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="fourStars" [(ngModel)]="fourStar" name="fourStar" (change)="onFilterChange($event)">
            <label class="form-check-label" for="fourStars">
              4 Stars
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="fiveStars" [(ngModel)]="fiveStar" name="fiveStar" (change)="onFilterChange($event)">
            <label class="form-check-label" for="fiveStars">
              5 Stars
            </label>
          </div>
        </div>
      </form>

    </div>
    

    <div *ngIf="showCapacityRanges">

      <form>
        <div class="form-group pt-10">
          <label><h3><b>Capacity: </b> </h3></label>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="lessthan500" [(ngModel)]="lessthan500" name="lessthan500" (change)="onFilterChange($event)">
            <label class="form-check-label" for="lessthan500">
              0 - 500
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="between500and1000" [(ngModel)]="between500and1000" name="between500and1000" (change)="onFilterChange($event)">
            <label class="form-check-label" for="between500and1000">
              500 - 1000
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="between1000nd2000" [(ngModel)]="between1000nd2000" name="between1000nd2000" (change)="onFilterChange($event)">
            <label class="form-check-label" for="between1000nd2000">
              1000 - 2000
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="greaterthan2000" [(ngModel)]="greaterthan2000" name="fiveStar" (change)="onFilterChange($event)">> 2000
            <label class="form-check-label" for="greaterthan2000">
              
            </label>
          </div>
        </div>
      </form>
      
    </div>
     
</div>


</div>



<!-- For smaller screens -->
<div class="d-block d-md-none">

  <div class=" d-flex flex-column flex-shrink-0" style="width: 100px;">
    <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">

    </a>
 
    <h2 class="pt-6">Filters</h2>

    <div class="row">

      <div class="col-4">

        <div *ngIf="showPriceRange">

          <div class="form-group">
            <label for="priceRange"><b>Price Range < {{currencyCode}}:</b> {{ price }}</label>
            <div class="d-flex justify-content-between pt-2">
                <span>{{ minValue }}</span>
                <input type="range" class="form-control-range" id="priceRange" [(ngModel)]="price" (change)="onFilterChange($event)" min="{{minValue}}" max="{{maxValue}}" step="10">
                <span>{{ maxValue }}</span>
            </div>
          </div>

        </div>

      </div>

      <div class="col-4">

        <div *ngIf="showRatingCheckboxes">

          <form>
            <div class="form-group pt-10">
              <label><h3><b>Select Rating: </b> </h3></label>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="threeStars" [(ngModel)]="threeStar" name="threeStar" (change)="onFilterChange($event)">
                <label class="form-check-label" for="threeStars">
                  3 Stars
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="fourStars" [(ngModel)]="fourStar" name="fourStar" (change)="onFilterChange($event)">
                <label class="form-check-label" for="fourStars">
                  4 Stars
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="fiveStars" [(ngModel)]="fiveStar" name="fiveStar" (change)="onFilterChange($event)">
                <label class="form-check-label" for="fiveStars">
                  5 Stars
                </label>
              </div>
            </div>
          </form>
    
        </div>

      </div>

      <div class="col-4">

        <div *ngIf="showCapacityRanges">

          <form>
            <div class="form-group pt-10">
              <label><h3><b>Capacity: </b> </h3></label>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="lessthan500" [(ngModel)]="lessthan500" name="lessthan500" (change)="onFilterChange($event)">
                <label class="form-check-label" for="lessthan500">
                  0 - 500
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="between500and1000" [(ngModel)]="between500and1000" name="between500and1000" (change)="onFilterChange($event)">
                <label class="form-check-label" for="between500and1000">
                  500 - 1000
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="between1000nd2000" [(ngModel)]="between1000nd2000" name="between1000nd2000" (change)="onFilterChange($event)">
                <label class="form-check-label" for="between1000nd2000">
                  1000 - 2000
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="greaterthan2000" [(ngModel)]="greaterthan2000" name="fiveStar" (change)="onFilterChange($event)">> 2000
                <label class="form-check-label" for="greaterthan2000">
                  
                </label>
              </div>
            </div>
          </form>
          
        </div>

      </div>



    </div>
   
    

    
    

    
     
</div>


</div>

