import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CountryData, CurrencyData, ExchangeRateData, VendorID, VendorSummaryData } from '../../../models/datamodels';
import { EnvironmentDataServiceService } from '../../../services/environment-data-service.service';
import { MasterdataserviceService } from '../../../services/masterdataservice.service';
import { UserServiceService } from '../../../services/user-service.service';
import { VendorDataServiceService } from '../../../services/vendor-data-service.service';
import { SignInComponent } from '../../usermgmt/sign-in/sign-in.component';

@Component({
  selector: 'app-venueslistinghome',
  standalone: true,
  imports: [MatCardModule,CommonModule,RouterModule],
  templateUrl: './venueslistinghome.component.html',
  styleUrl: './venueslistinghome.component.css'
})
export class VenueslistinghomeComponent implements OnInit {
  vendorsListOriginal: VendorSummaryData[] = [];
  vendorsList: VendorSummaryData[] = [];
  serverpath = environment.middletierhost + '/uploads';
  currentIndex: number = 0;
  countriesList: CountryData[] = [];
  favourites: VendorID[] = [];
  iduser = 0;
  currentCurrency: CurrencyData | null = null;
  currentSection: string = 'new'; // Default section is 'new'

  fxList: ExchangeRateData[] = [];
  selectedCurrency: CurrencyData | null = null;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private vendorDataService: VendorDataServiceService,
    private environmentDataService: EnvironmentDataServiceService,
    private masterDataService: MasterdataserviceService,
    private userDataService: UserServiceService
  ) {}

  ngOnInit(): void {
    this.environmentDataService.currentCurrency.subscribe(data => {
      this.selectedCurrency = data;

      this.masterDataService.getExchangeRates().subscribe(data => {
        this.fxList = data;
        console.log('Exchange rates fetched:', this.fxList);
      });
    });

    this.vendorDataService.getVenues().subscribe(data => {
      this.vendorsListOriginal = data;
      console.log('Venues list received with number:', data.length);
      this.updateDisplayedVendors(); // Update displayed vendors based on the current section
    });

    this.masterDataService.getCountries().subscribe(data => {
      this.countriesList = data;
    });

    this.environmentDataService.currentUser.subscribe(user => {
      if (user) {
        this.iduser = user.iduser;

        this.userDataService.getUserFavourites(this.iduser).subscribe(data => {
          this.favourites = data;
        });
      }
    });
  }

  updateDisplayedVendors(): void {
    if (this.currentSection === 'new') {
      // Display the first 6 items for the 'Featured' section
      this.vendorsList = this.vendorsListOriginal.slice(0, 6);
    } else if (this.currentSection === 'featured') {
      // Without a date, simulate sorting by assuming reverse order
      this.vendorsList = [...this.vendorsListOriginal].reverse().slice(0, 6);
    } else if (this.currentSection === 'top-rated') {
      // Sort by rating using the existing 'getRating' logic
      this.vendorsList = [...this.vendorsListOriginal]
        .sort((a, b) => this.getRating(b.vendorrating) - this.getRating(a.vendorrating))
        .slice(0, 6);
    }
  }

  selectSection(section: string): void {
    this.currentSection = section;
    this.updateDisplayedVendors(); // Refresh vendors list based on the new section
  }

  getCountryName(idcountry: number): string {
    const country = this.countriesList.find(c => c.idcountry === idcountry);
    return country ? country.countryName : '';
  }

  onVendorSelect(selected: VendorSummaryData): void {
    this.vendorDataService.changeData(selected);
    this.environmentDataService.changeCountryData(this.countriesList.find(c => c.idcountry === selected.idcountry));
    this.router.navigate(['/vendordetails']);
  }

  getStars(rating: string): string {
    // Convert rating to stars
    return '*'.repeat(this.getRating(rating));
  }

  getRating(rating: string): number {
    // This function converts rating strings to numerical values
    switch (rating) {
      case '5 star':
        return 5;
      case '4 star':
        return 4;
      case '3 star':
        return 3;
      case '2 star':
        return 2;
      case '1 star':
        return 1;
      default:
        return 0;
    }
  }

  toggleFavourites(idvendor: number) {
    this.environmentDataService.currentUser.subscribe(user => {
      if (user) {
        this.iduser = user.iduser;
      } else {
        const dialogRef = this.dialog.open(SignInComponent, { width: '300px' });
        dialogRef.afterClosed().subscribe(() => {
          this.router.navigate(['/home']);
          console.log('The dialog was closed');
        });
      }
    });

    if (this.iduser > 0) {
      if (!this.favourites.find(c => c.idvendor === idvendor)) {
        this.userDataService.addUserFavourites({ iduser: this.iduser, idvendor }).subscribe(() => {
          this.userDataService.getUserFavourites(this.iduser).subscribe(info => {
            this.favourites = info;
            console.log('favourites list:', this.favourites);
          });
        });
      } else {
        this.userDataService.deleteUserFavourites({ iduser: this.iduser, idvendor }).subscribe(() => {
          this.userDataService.getUserFavourites(this.iduser).subscribe(info => {
            this.favourites = info;
            console.log('favourites list:', this.favourites);
          });
        });
      }
    }
  }

  isFavourite(idvendor: number): boolean {
    return !!this.favourites.find(c => c.idvendor === idvendor);
  }

  convertCurrency(fromCurrency:string,toCurrency:string,amount:number):number
   {
      //console.log('Currency conversion from ' ,fromCurrency ,' to ',toCurrency);

      if(fromCurrency==toCurrency)
        return amount;

        const existingRatetoUSD = this.fxList.find(rate =>
          rate.fromCurrency == fromCurrency && rate.toCurrency == 'USD');


          //console.log('existingRatetoUSD ' ,existingRatetoUSD , '. Rate :',existingRatetoUSD!.rate);
          

        if (existingRatetoUSD) 
        {
          const existingRatefromUSD = this.fxList.find(rate =>
            rate.fromCurrency == 'USD' && rate.toCurrency == toCurrency);

            //console.log('existingRatefromUSD ' ,existingRatefromUSD , '. Rate :',existingRatefromUSD!.rate);

            if(existingRatefromUSD)
            {
              return amount*existingRatetoUSD.rate*existingRatefromUSD?.rate!
            }
            else
            return amount;
        
        } 
        else 
        {
            return amount;
            
        }
   }

  encodeUrl(url: string): string {
    return encodeURIComponent(url);
  }

  goToVenues()
  {
    this.environmentDataService.setSessionData('savedCountry', null);
    this.environmentDataService.setSessionData('savedCity', null);
    this.environmentDataService.setSessionData('savedVendor', null);
    this.environmentDataService.setSessionData('savedSearchQuery', null);
    this.environmentDataService.setSessionData('savedShowSearch', null);

    this.router.navigate(['/venues']);

  }
}