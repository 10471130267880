<div class="banner" (click)="goToSakhi()">
    <img src="../../assets/images/newdesign/sakhibanner.png" alt="Banner Image" class="banner-img" />
    <div class="banner-content">
        <div class="soulsWedHeadingFontWhite">Sakhi Service</div>
        <div class="soulsWedSubHeadingFontWhite pt-2">SoulsWed Sakhi can help out!</div>
        <div class="soulsWedNormalFontWhite pt-2" style="margin-bottom: 20px;">Plan the perfect wedding with our help</div>
        <button class="button-style-2"  (click)="goToSakhi()">Learn More</button>
    </div>
</div>

<!-- Small device banner -->
<div class="banner-small" (click)="goToSakhi()">
    <img src="../../assets/images/newdesign/sakhibanner.png" alt="Banner Image" class="banner-img-small" />
    <div class="banner-content-small">
        <div class="soulsWedHeadingFontWhite-sm">Sakhi Service</div>
        <div class="soulsWedSubHeadingFontWhite-sm pt-2">SoulsWed Sakhi can help out!</div>
        <div class="soulsWedNormalFontWhite-sm pt-1">Plan the perfect wedding with our help</div>
        <button class="button-style-small"  (click)="goToSakhi()">Learn more</button>
    </div>
</div>
