import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-inquiry-dialog',
  templateUrl: './inquiry-dialog.component.html',
  styleUrls: ['./inquiry-dialog.component.css']
})
export class InquiryDialogComponent {
  constructor(public dialogRef: MatDialogRef<InquiryDialogComponent>) {}

  closeDialog(): void {
    this.dialogRef.close();
  }

  sendEmail(): void {
    window.location.href = 'mailto:info@soulswed.com';
  }

  startChat(): void {
    window.open('https://wa.me/919445266640?text=Hello%20there!%20I%20would%20like%20to%20inquire%20about...', '_blank');
  }
}
