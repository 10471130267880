import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {provideHttpClient} from "@angular/common/http";
import { provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { initializeApp } from 'firebase/app';
import { CalendarModule, DateAdapter,CalendarDateFormatter,CalendarMomentDateFormatter,CalendarUtils,CalendarA11y } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';


const firebaseConfig = {
  apiKey: "AIzaSyAp6v6tggBq6Z_SVVlGqvRnMyzgPEOVa2I",
  authDomain: "soulswed-7bad8.firebaseapp.com",
   projectId: "soulswed-7bad8",
  storageBucket: "soulswed-7bad8.appspot.com",
  messagingSenderId: "579847020011",
  appId: "1:579847020011:web:0e075c2c99cfd480668dae"
};


/*

const firebaseConfig = {
  apiKey: "AIzaSyCPakULA5lO-igHdHxHJfh8vmrs83WqGAY",
  //authDomain: "soulswed-a082a.firebaseapp.com",
  authDomain: "soulswed.com",
  projectId: "soulswed-a082a",
  storageBucket: "soulswed-a082a.appspot.com",
  messagingSenderId: "352252733193",
  appId: "1:352252733193:web:5e4242cf713febb61c55d8",
  measurementId: "G-X6ZYJBQT7E"
};

*/


export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), 
    {
      provide: DateAdapter,
      useFactory: adapterFactory
    },
    {
      provide: CalendarUtils,
      useClass: CalendarUtils
    },
    provideAnimationsAsync(),provideHttpClient(),provideFirebaseApp(() => initializeApp(firebaseConfig)),
    provideAuth(() => getAuth())]
};
