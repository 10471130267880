<!-- Single Video Element -->
<div class="video-container">
  <video class="d-block w-100 custom-video" autoplay muted style="margin-top: 60px; clip-path: polygon(0% 0%, 0 85%, 100% 85%, 100% 0%);">
    <source src="../../../assets/videos/home/homepagevideo.mp4" type="video/mp4">
    Your browser does not support the video tag.
  </video>
  
  <!-- Curves -->
  <div class="curve-container-ld " style="margin-top: 60px;">
    <!-- Desktop and Mobile curves will be handled via media queries -->
    <svg class="desktop-curve bottom-curve-svg" viewBox="0 0 1440 500" preserveAspectRatio="none">
      <path fill="rgb(245,129,6)" fill-opacity="1" d="M0,30 C500,500 1000,0 1440,70 V280 H0 Z"></path>
    </svg>
    <svg class="desktop-curve2 bottom-curve-svg2" viewBox="0 0 1440 500" preserveAspectRatio="none">
      <path fill="#FDEFD2" fill-opacity="1" d="M0,30 C500,500 1000,0 1440,70 V280 H0 Z"></path>
    </svg>
  </div>

  
    <div class="curve-container-sd" style="margin-top: 120px;">
        <svg class="mobile-curve bottom-curve-svg" viewBox="0 0 1440 500" preserveAspectRatio="none">
            <path fill="rgb(245,129,6)" fill-opacity="1" d="M0,60 C560,380 1000,0 1440,60 V330 H0 Z"></path>
        </svg>

        <svg class="mobile-curve2 bottom-curve-svg2" viewBox="0 0 1440 500" preserveAspectRatio="none">
            <path fill="#FDEFD2" fill-opacity="1" d="M0,60 C560,380 1000,0 1440,60 V330 H0 Z"></path>
        </svg>
        
  </div>


</div>
