import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentDataServiceService } from '../../../services/environment-data-service.service';
@Component({
  selector: 'app-offers',
  standalone: true,
  imports: [],
  templateUrl: './offers.component.html',
  styleUrl: './offers.component.css'
})
export class OffersComponent {
  constructor(private router: Router,private envData:EnvironmentDataServiceService) {} 
  
  goToDecorators() {
    this.envData.setSessionData('savedCountry', null);
    this.envData.setSessionData('savedCity', null);
    this.envData.setSessionData('savedVendor', null);
    this.envData.setSessionData('savedSearchQuery', null);
    this.envData.setSessionData('savedShowSearch', null);

    this.router.navigate(['/decorators']);
  }

  goToVenues() {
    this.envData.setSessionData('savedCountry', null);
    this.envData.setSessionData('savedCity', null);
    this.envData.setSessionData('savedVendor', null);
    this.envData.setSessionData('savedSearchQuery', null);
    this.envData.setSessionData('savedShowSearch', null);

    this.router.navigate(['/venues']);
  }

  goToSakhi() {
    this.router.navigate(['/sakhiservice']);
  }
}
