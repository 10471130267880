import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

//--- import project components -----
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { CountryData } from '../../models/datamodels';
import { EnvironmentDataServiceService } from '../../services/environment-data-service.service';
import { MasterdataserviceService } from '../../services/masterdataservice.service';
import { AccordianComponent } from '../accordian/accordian.component';
import { FootersectionComponent } from '../footersection/footersection.component';
import { Header2Component } from '../header2/header2.component';
import { SakhibannerComponent } from "../sakhibanner/sakhibanner.component";
import { SakhiserviceComponent } from '../sakhiservice/sakhiservice.component';
import { SearchComponent } from '../search/search.component';
import { SearchmobileComponent } from '../searchmobile/searchmobile.component';
import { CategoriesComponent } from "./categories/categories.component";
import { CharterslistinghomeComponent } from './charterslistinghome/charterslistinghome.component';
import { DecoratorslistinghomeComponent } from './decoratorslistinghome/decoratorslistinghome.component';
import { HotelslistinghomeComponent } from './hotelslistinghome/hotelslistinghome.component';
import { ImagesliderComponent } from './imageslider/imageslider.component';
import { ImgcarouselComponent } from './imgcarousel/imgcarousel.component';
import { MakeupartistslistinghomeComponent } from './makeupartistslistinghome/makeupartistslistinghome.component';
import { OffersComponent } from "./offers/offers.component";
import { PhotographerslistinghomeComponent } from './photographerslistinghome/photographerslistinghome.component';
import { VendorslistingComponent } from './vendorslisting/vendorslisting.component';
import { VenueslistinghomeComponent } from './venueslistinghome/venueslistinghome.component';
import { WeddingstorieslistingComponent } from './weddingstorieslisting/weddingstorieslisting.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [AccordianComponent, SakhiserviceComponent, CommonModule, DecoratorslistinghomeComponent, MakeupartistslistinghomeComponent, SearchComponent, HttpClientModule, Header2Component, ImagesliderComponent, VenueslistinghomeComponent, HotelslistinghomeComponent, VendorslistingComponent, WeddingstorieslistingComponent, FootersectionComponent, ImgcarouselComponent, SearchmobileComponent, CharterslistinghomeComponent, PhotographerslistinghomeComponent, CategoriesComponent, OffersComponent, SakhibannerComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})


export class HomeComponent implements OnInit 
{
  @ViewChild('homeContainer', { static: true }) homeContainer?: ElementRef;

  countries:CountryData[]|null=null;
  showSearch:boolean=false;
  searchButtonText:string='Search Vendors';
  featuredButtonText:string='Top Picks';

  constructor(private environmentDataService:EnvironmentDataServiceService,private masterDataService:MasterdataserviceService, private router:Router)
  {

  };

  ngOnInit(): void 
  {
      this.initializeComponent();
  }

  initializeComponent()
  {
    this.masterDataService.getCountries().subscribe
    (data=>
      {
        this.countries=data;

       // this.environmentDataService.changeCountryData(this.countries[0]);
  
      }
    );
  }

  handleVisibilityChange() 
  {
    if (this?.homeContainer?.nativeElement.offsetParent !== null) 
      {
        // The component is now visible
        this.initializeComponent();
      }
  }

  enableSearch()
  {
    console.log('Enable Search Clicked');
    this.showSearch=!this.showSearch;

    if(this.showSearch)
      this.searchButtonText="Hide search panel"
    else
      this.searchButtonText="Search Vendors"
    
  }
  goToVendor()
  {
    this.router.navigate(['/vendor'])
  }
}
