import { Injectable,OnInit} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CategoryData,CountryData,CurrencyData,VendorSummaryData, UserData,CityData} from '../models/datamodels';

@Injectable({
  providedIn: 'root'
})


export class EnvironmentDataServiceService implements OnInit
{


  //-------- Current User Information -----------------
  //private userDataSource=new BehaviorSubject<UserData|null>(null);
  private userDataSource = new BehaviorSubject<UserData | null>(this.getSessionData<UserData>('currentUserData'));
  currentUser=this.userDataSource.asObservable();
  changeUserData(data: UserData|null) 
  {
    console.log('Change user data called :',data)
    this.userDataSource.next(data);
    this.setSessionData('currentUserData', data);
  }


  //-------- Current Country Information -----------------
  //private countryDataSource=new BehaviorSubject<CountryData|null>(null);
  private countryDataSource = new BehaviorSubject<CountryData | null>(this.getSessionData<CountryData>('currentCountryData'));
  currentCountry=this.countryDataSource.asObservable();
  changeCountryData(data: CountryData|null|undefined) 
  {
    if(data)
    {
      this.countryDataSource.next(data);
      this.setSessionData('currentCountryData', data);
      console.log('Env current country changed to :',data?.countryName,data?.idcountry);
      //this.changeCurrencyData(data?.currency);  // change current currency based on new country
    }
    
  }


  //-------- Current City Information ----------------- 
  private cityDataSource = new BehaviorSubject<CityData | null>(this.getSessionData<CityData>('currentCityData'));
  currentCity=this.cityDataSource.asObservable();
  changeCity(data: CityData|null|undefined) 
  {
    if(data)
    {
      this.cityDataSource.next(data);
      this.setSessionData('currentCityData', data);
      console.log('Env current city changed to :',data?.cityname,data?.idcity);
    }
    
  }

  
  //-------- Current Category Information -----------------
  //private categoryDataSource=new BehaviorSubject<CategoryData|null>(null);
  private categoryDataSource = new BehaviorSubject<CategoryData | null>(this.getSessionData<CategoryData>('currentCategoryData'));
  currentCategory=this.categoryDataSource.asObservable();
  changeCategoryData(data: CategoryData) 
  {
    this.categoryDataSource.next(data);
    this.setSessionData('currentCategoryData', data);
    console.log('Env current category changed to :',data.categoryName,data.idcategory);
  }

  
  //-------- Current Currency Information -----------------
  //private currencyDataSource=new BehaviorSubject<CurrencyData|null>(null);
  private currencyDataSource = new BehaviorSubject<CurrencyData | null>(this.getSessionData<CurrencyData>('currentCurrencyData'));
  currentCurrency=this.currencyDataSource.asObservable();
  changeCurrencyData(data: CurrencyData|null) 
  {
    //console.log('change currency called')
    if (data)
    {
      this.currencyDataSource.next(data);
      this.setSessionData('currentCurrencyData', data);
    }
    
  }

  
  //-------- Current Vendor Information -----------------
  //private vendorDataSource=new BehaviorSubject<VendorSummaryData|null>(null);

  private vendorDataSource = new BehaviorSubject<VendorSummaryData | null>(this.getSessionData<VendorSummaryData>('currentVendorData'));
  currentVendor=this.vendorDataSource.asObservable();

  changeVendorData(data: VendorSummaryData) 
  {
    this.vendorDataSource.next(data);
    this.setSessionData('currentVendorData', data);
  }


  public getSessionData<T>(key: string): T | null 
  {
    const data = sessionStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }

  public setSessionData<T>(key: string, data: T) 
  {
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  userCountry:string='';
  userCurrency:string='';

  


  constructor() 
  { }

  ngOnInit(): void 
  {

    
      
  }



  
}
