import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { CalendarOptions } from '@fullcalendar/core'; // Correct import
import { CalendarA11y, CalendarDateFormatter, CalendarEvent, CalendarEventTitleFormatter, CalendarModule, CalendarMonthViewDay, CalendarView } from 'angular-calendar';
import { differenceInDays, differenceInHours, isSameDay } from 'date-fns';
import { CurrencyData, ExchangeRateData, RazorpayErrorResponse, RazorpayPaymentResponse, UserData, VendorCalendar, VendorSummaryData } from '../../../models/datamodels';
import { EnvironmentDataServiceService } from '../../../services/environment-data-service.service';
import { MasterdataserviceService } from '../../../services/masterdataservice.service';
import { VendorDataServiceService } from '../../../services/vendor-data-service.service';
import { FootersectionComponent } from '../../footersection/footersection.component';
import { GobackComponent } from '../../goback/goback.component';
import { Header2Component } from '../../header2/header2.component';





declare var Razorpay:any;


@Component({
  selector: 'app-paynbook',
  standalone: true,
  imports: [ FormsModule,GobackComponent,Header2Component,FootersectionComponent,CalendarModule,CommonModule,MatDatepickerModule,MatFormFieldModule,MatInputModule,MatNativeDateModule],
  templateUrl: './paynbook.component.html',
  styleUrl: './paynbook.component.css',
  providers: [DatePipe,CalendarA11y,CalendarDateFormatter,CalendarEventTitleFormatter,DecimalPipe ] ,
  encapsulation: ViewEncapsulation.None
})

export class PaynbookComponent implements OnInit
{

  titleText:string='Booking ';
  showCalendar:boolean=false;
  showSelectionPanel:boolean=false;
  showPaymentPanel:boolean=false;
  showreceipt:boolean=false;

  currentVendor:VendorSummaryData|null=null;
  calendarList:VendorCalendar[]=[];
  calendarListLocal:VendorCalendar[]=[];
  currentCurrency:CurrencyData|null=null;
  currentUser:UserData|null=null;

  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  events: CalendarEvent[] = [];

  startDateString:string|null=null;
  minStartDate: string='';

  selectedStartDate:string|null=null;
  selectedStartTime:string|null=null;
  selectedEndDate:string|null=null;
  selectedEndTime:string|null=null;
  guests:number=0;
  description:string|null=null;
  errorMessage:string|null=null;

  combinedStartDate:string|null=null;
  combinedEndDate:string|null=null;

  startDatetoPrint:Date|null=null;
  endDatetoPrint:Date|null=null;

  startDateStr:string|null=null;
  endDateStr:string|null=null;
  hours:number=0;
  days:number=0;

  pricebasis:string|null=null;
  basepricevc:number|null=null;
  baseprice:number|null=null;
  vendorcurrency:string|null=null;
  applicablecurrency:string|null=null;
  tax: number=18;
  totalBeforeTax: number=0;
  totalAfterTax: number=0;
  finalamount:number=0;
  selectedPercentage=10;
  amounttopay:number=0;
  balancePaymentDuedate:string='';
  balancePaymentDuedatetoPrint:Date|null=null;

  advancepercentage:number=10;
  daysprior:number=5;
  paymentpercentageoptions:number[]=[];
  actualDaysPriortoEvent:number=0;
  showPaymentPercentages:boolean=false;

  idvendor:number=0;
  iduser:number=0;
  vendorname:string='';
  username:string='';
  useremail:string='';
  transactiondate:string='';

  fxList:ExchangeRateData[]=[];



   // Example event dates
   eventss: Date[] = [
    new Date(2024, 9, 1),  // September 1, 2024
    new Date(2024, 9, 5),  // September 5, 2024
    new Date(2024, 9, 10)  // September 10, 2024
  ];

  calevents = [
    { title: 'Event 1', start: '2024-09-01' },
    { title: 'Event 2', start: '2024-09-05' },
    { title: 'Event 3', start: '2024-09-10' }
  ];

  calendarOptions: CalendarOptions|null=null;

    // Define the view's hour range
    dayStartHour = 7; // Start the day view at 7 AM
    dayEndHour = 18; // End the day view at 6 PM

    selectedDateTime:Date|null=null;


  constructor(private cdr: ChangeDetectorRef,private vendorDataService:VendorDataServiceService,private router:Router,private datePipe:DatePipe,private envDataService:EnvironmentDataServiceService,private masterDataService:MasterdataserviceService,private decimalPipe: DecimalPipe)
  {}

  ngOnInit(): void 
  {
    this.setMinStartDate() ;

      this.vendorDataService.currentVendor.subscribe(data=>
        {
          this.currentVendor=data;
          this.titleText=this.titleText+' : '+ this.currentVendor?.vendorname;
          this.idvendor=data?.idvendor!;
          this.vendorname=data?.vendorname!;

          this.pricebasis=data?.pricebasis!;
          this.basepricevc=data?.startingprice!;
          this.vendorcurrency=data?.currency!;

          if(this.currentVendor?.advancepercentage)
          {
              this.advancepercentage=this.currentVendor?.advancepercentage;
          }

          if(this.currentVendor?.balancepriordays)
          {
              this.daysprior=this.currentVendor.balancepriordays;
          }

          this.envDataService.currentCurrency.subscribe(currency=>
          {
            this.currentCurrency=currency;

            this.masterDataService.getExchangeRates().subscribe(fxlist=>
            {
              this.fxList=fxlist

              if(this.currentCurrency && this.vendorcurrency && this.fxList && this.basepricevc)
              {
                this.baseprice=this.convertCurrency(this.vendorcurrency,this.currentCurrency.currencycode,this.basepricevc);
                this.applicablecurrency=this.currentCurrency.currencycode;
              }
              else
              {
                this.baseprice=this.basepricevc;
                this.applicablecurrency=this.vendorcurrency;
              }
            }
            )

            //------------ Get current user -----------------

            this.envDataService.currentUser.subscribe(user=>
            {
              this.currentUser=user;
              this.iduser=user?.iduser!;
              this.username=user?.username!;
              this.useremail=user?.email!;
            }
            )

          })




          //--------- Get calendar for vendor -----------------
          this.vendorDataService.getVendorCalendar(data?.idvendor!).subscribe(list=>
            {
              this.calendarList=list;
              this.calendarListLocal=this.convertToLocalTimeArray(this.calendarList);
          
             this.loadBlockedDates();
              console.log('Events :',this.events);
            }        
            )
          
        });


     
  }


  beforeMonthViewRender(event: any): void 
  {

    console.log('Called beforeMonthViewRender');

    const list=<CalendarMonthViewDay[]>event.body;

    list.forEach(day => {
      const eventsOnDay = day.events.length;

      console.log('Events on day:',day.events);

      if (eventsOnDay > 0) {
        day.cssClass = 'bg-orange';
      } else {
        day.cssClass = 'bg-green';
      }
    });

   


  }

  convertUTCToLocal(utcDateTime: string): string 
  {
    const utcDate = new Date(utcDateTime);
    // return utcDate.toLocaleString(); // Converts back to the local time zone
    return this.datePipe.transform(utcDate, 'dd/MM/yyyy, HH:mm:ss')!;
  }

  convertToLocalTimeArray(vendorCalendarArray: VendorCalendar[]): VendorCalendar[] 
  {
    return vendorCalendarArray.map(item => {

      console.log('Start date passed :',item.startDate);
      const localStartDate = this.convertUTCToLocal(item.startDate);
      console.log('Local date got :',localStartDate);
      const [datePart, timePart] = localStartDate.split(', ');
      const [day, month, year] = datePart.split('/').map(part => parseInt(part, 10));
      const [hours, minutes, seconds] = timePart.split(':').map(part => parseInt(part, 10));  
      const startdate = new Date(year, month - 1, day, hours, minutes, seconds);

      const localEndDate = this.convertUTCToLocal(item.endDate);
      const [dateParte, timeParte] = localEndDate.split(', ');
      const [daye, monthe, yeare] = dateParte.split('/').map(part => parseInt(part, 10));
      const [hourse, minutese, secondse] = timeParte.split(':').map(part => parseInt(part, 10));  
      const enddate = new Date(yeare, monthe - 1, daye, hourse, minutese, secondse);
  
      return {
        ...item, // Copy the other fields as is
        startDate: this.datePipe.transform(startdate, 'dd-MMM-yyyy, h:mm a')!,
        endDate: this.datePipe.transform(enddate, 'dd-MMM-yyyy, h:mm a')!
      };
    });
  }

  
  loadBlockedDates(): void 
  {
    // Example data: Replace with your data from the backend
    const blocks = [
      { date: new Date('2024-09-01'), hoursBlocked: 2 },
      { date: new Date('2024-09-02'), hoursBlocked: 7 },
      { date: new Date('2024-09-10'), hoursBlocked: 5 },
      { date: new Date('2024-09-10'), hoursBlocked: 2 }
    ];

    this.events = this.calendarListLocal.map(block => 
      {
      //let color = '#00ff00'; // Green by default


      return {
        start: new Date(block.startDate),
        end: new Date(block.endDate),
        title: ''
      
      };
    });
  }

  // Handler for day click event
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    this.viewDate = date;  // Set the clicked date as the view date
    //this.view = CalendarView.Day;  // Change the view to 'day'
  }
 

  

  setMinStartDate() 
  {
    const now = new Date();
    this.minStartDate = now.toISOString().slice(0, 10);
   // this.minStartDate=this.formatDateForInput(this.minStartDate);
  }



  onStartDateChange()
  {
    if(!this.startDateString)
    {
      alert('Please select a date');
      return;
    }
     this.viewDate=new Date (this.startDateString!);
     this.showCalendar=true;
     this.showSelectionPanel=false;
     this.showPaymentPanel=false;
     this.showreceipt=false;
     this.selectedEndDate=null;
     this.selectedEndTime=null;
     this.guests=0;
     this.description=null;
  }

  // Handle the click event on a time slot
  hourSegmentClicked(event: { date: Date }): void 
  {
    const slotTime = event.date;

    // Check if the clicked slot overlaps with an existing event
    const overlappingEvent = this.events.find(ev => {
      return (
        isSameDay(ev.start, slotTime) &&
        (slotTime >= ev.start && slotTime < ev.end!)
      );
    });

    if (overlappingEvent) {
      // Slot is occupied by an event, do not proceed
      alert('This slot is occupied');
      return;
    }

    this.selectedDateTime = event.date; // Store the clicked date and time in the variable
    console.log('Selected Date and Time:', this.selectedDateTime); // For debugging purposes

    this.selectedStartDate=this.formatDateForInput(this.formatDateForCustomFunctions(this.selectedDateTime));
    this.selectedStartTime=this.formatTimeForInput(this.formatDateForCustomFunctions(this.selectedDateTime));

    this.showCalendar=false;
    this.showSelectionPanel=true;
  }

  formatDateForCustomFunctions(date: Date): string 
  {
    const day = String(date.getDate()).padStart(2, '0');
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
  
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';
  
    hours = hours % 12 || 12; // Convert 24-hour time to 12-hour time
    const formattedTime = `${String(hours).padStart(2, '0')}:${minutes} ${period}`;
  
    return `${day}-${month}-${year}, ${formattedTime}`;
  }

  formatDateForInput(dateString: string): string 
  {
    const [datePart, timePart] = dateString.split(', ');
    const [day, monthStr, year] = datePart.split('-');

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames.indexOf(monthStr) + 1;

    return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
  }

  formatTimeForInput(dateString: string): string 
  {
    const [datePart, timePart] = dateString.split(', ');

    const [time, period] = timePart.split(' ');
    let [hours, minutes] = time.split(':').map(Number);

    if (period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  }

  onSelectedPercentageChanged()
  {
    this.amounttopay=this.totalAfterTax *(this.selectedPercentage/100);
  }

  processPayment()
  {
    this.errorMessage='';

    if (!this.selectedStartDate)
    {
      this.errorMessage='Please select start date';
      return;
    }

    if (!this.selectedStartTime)
    {
        this.errorMessage='Please select start time';
        return;
    }

    
    if (!this.selectedEndDate)
      {
        this.errorMessage='Please select end date';
        return;
      }
  
      if (!this.selectedEndTime)
      {
          this.errorMessage='Please select end time';
          return;
      }
      if(!(this.guests>0))
      {
        this.errorMessage='Please enter guest count';
        return;
      }

      if(!this.description)
      {
          this.errorMessage='Please enter description';
          return;
      }
  


    this.combinedStartDate= this.combineDateAndTime(this.selectedStartDate!, this.selectedStartTime!);
    this.combinedEndDate=this.combineDateAndTime(this.selectedEndDate!, this.selectedEndTime!);

    console.log('Combined Start date:',this.combinedStartDate);
    console.log('Combined End Date: ',this.combinedEndDate);

    this.startDatetoPrint=new Date(this.combinedStartDate);
    this.endDatetoPrint=new Date(this.combinedEndDate);

    this.startDateStr=new Date(this.combinedStartDate).toISOString();
    this.endDateStr=new Date(this.combinedEndDate).toISOString();
    this.transactiondate=new Date().toISOString();

    console.log('Start date:',this.startDateStr);
    console.log('End Date: ',this.endDateStr);

    //--------find actual days prior to the event from today ---------------

    const today: Date = new Date(); // Get today's date
    const startDate: Date = new Date(this.selectedStartDate);
    const diffInTime: number = startDate.getTime() - today.getTime();
    const diffInDays: number = Math.ceil(diffInTime / (1000 * 3600 * 24));
    this.actualDaysPriortoEvent=diffInDays;

    if(this.actualDaysPriortoEvent>this.daysprior)
    {


      this.paymentpercentageoptions.push(this.advancepercentage);
 
      if(this.advancepercentage < 25)
        this.paymentpercentageoptions.push(25);

      if(this.advancepercentage < 50)
        this.paymentpercentageoptions.push(50);

      if(this.advancepercentage < 75)
        this.paymentpercentageoptions.push(75);

      if(this.advancepercentage < 100)
        this.paymentpercentageoptions.push(100);

      this.selectedPercentage=this.advancepercentage;

      this.showPaymentPercentages=true;

      const dueDate: Date = new Date(startDate);
      dueDate.setDate(startDate.getDate() - this.daysprior);

      this.balancePaymentDuedate=dueDate.toISOString();
      this.balancePaymentDuedatetoPrint=dueDate;

    }
    else
    {
      this.selectedPercentage=100;
      this.balancePaymentDuedate=this.transactiondate;
    }

    // Output the number of days
    console.log('Days between today and selectedStartDate:', this.actualDaysPriortoEvent);

    if(!this.startDateStr)
    {
      this.errorMessage='Please select start date';
      return;
    }

    if(!this.endDateStr)
    {
      this.errorMessage='Please select end date';
      return;
    }

    if(new Date(this.combinedStartDate) >= new Date(this.combinedEndDate))
    {
        this.errorMessage='End date-time should be greater than start date-time';
        return;
    }

    //------------ Check availability ------------------------------------------

    this.vendorDataService.checkVendorAvailability(this.idvendor,this.startDateStr,this.endDateStr).subscribe(data=>
      {
        console.log('Check availability data recieved :',data);

        if(data.length>0)
        {
          this.errorMessage='Selected slot overlaps with another event.';
          return;
        }
        else
        {
           //------------- Calculation of final amount and payment --------------------

            this.days=differenceInDays(new Date(this.combinedEndDate!), new Date(this.combinedStartDate!))+1;
            this.hours=differenceInHours(new Date(this.combinedEndDate!), new Date(this.combinedStartDate!));

            if(this.pricebasis=='Per Day')
            {
              this.totalBeforeTax=this.baseprice!*this.days;
            }

            if(this.pricebasis=='Per Person')
            {

                this.totalBeforeTax=this.baseprice!*this.guests*this.days;
            }

            if(this.pricebasis=='Per Hour')
            {
  
                this.totalBeforeTax=this.baseprice!*Math.ceil(this.hours);
            }

            if(this.pricebasis=='Per Engagement')
            {
 
                this.totalBeforeTax=this.baseprice!;
            }

   
            this.totalAfterTax = this.totalBeforeTax + (this.totalBeforeTax * this.tax / 100);

            this.amounttopay=this.totalAfterTax *(this.selectedPercentage/100);
   
            this.showPaymentPanel=true;
        }
      } )


  }



  cancelSelections()
  {
    this.selectedStartDate=null;
    this.selectedStartTime=null;
    this.selectedEndDate=null;
    this.selectedEndTime=null;
    this.description=null;

    this.showSelectionPanel=false;

  }

  convertToUTC(date: Date): Date {
    // Convert the date to UTC
    const utcDate = new Date(Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    ));
  
    // Format the UTC date as a string if needed
    return utcDate;
  }

  
  combineDateAndTime(date: string, time: string): string 
  {
    return `${date}T${time}`;
  }

  convertCurrency(fromCurrency:string,toCurrency:string,amount:number):number
   {
    //  console.log('Currency conversion from ' ,fromCurrency ,' to ',toCurrency);

        if(fromCurrency==toCurrency)
            return amount;

        const existingRatetoUSD = this.fxList.find(rate =>
          rate.fromCurrency == fromCurrency && rate.toCurrency == 'USD');


      //    console.log('existingRatetoUSD ' ,existingRatetoUSD , '. Rate :',existingRatetoUSD!.rate);
          

        if (existingRatetoUSD) 
        {
          const existingRatefromUSD = this.fxList.find(rate =>
            rate.fromCurrency == 'USD' && rate.toCurrency == toCurrency);

        //    console.log('existingRatefromUSD ' ,existingRatefromUSD , '. Rate :',existingRatefromUSD!.rate);

            if(existingRatefromUSD)
            {
              return amount*existingRatetoUSD.rate*existingRatefromUSD?.rate!
            }
            else
            return amount;
        
        } 
        else 
        {
            return amount;
            
        }
   }

     
   
   makePaymentwithCreateOrder()
   {
      this.finalamount=this.amounttopay*100;
      this.formatNumber();

      if(this.finalamount>0)
        {
            

            const successCallBack = (paymentId: RazorpayPaymentResponse)=>{
                console.log('Payment successful with payment id :',paymentId.razorpay_payment_id);
  
                this.vendorDataService.postTransaction(
                  {idtransaction:0,iduser:this.iduser,idvendor:this.idvendor,amount:this.totalAfterTax,vendorname:this.vendorname,username:this.username,useremail:this.useremail,transactiondate:this.transactiondate,currency:this.applicablecurrency!, bookingstart:this.startDateStr!,bookingend:this.endDateStr!,paidamount:this.amounttopay,balanceamount:this.totalAfterTax-this.amounttopay,balanceduedate:this. balancePaymentDuedate
                    
                  }).subscribe(data=>
                  {
                    console.log('Response from post transaction :',data[0]);

                    if(data[0].idtransaction>0)
                    {

                           //---------Add calendar entry for vendor ----------------------

                          const vendorCalendar:VendorCalendar=
                          {
                                idvendorNonavailabilities:0,
                                idvendor:this.currentVendor?.idvendor!,
                                startDate:this.startDateStr!,
                                endDate:this.endDateStr!,
                                description:this.description!
                          }

    
                          this.vendorDataService.addVendorCalendar(vendorCalendar).subscribe(data=>
                          {
                                console.log('Returned response of add calendar :',data);
        
                                if(data==='Success')
                                {
                                    console.log('Calendar entry added successfully !!!');

                                }
                                else
                                {
                                    console.log('Failed to add :'+data);
                                }

                            })

                            this.vendorDataService.changeTransactionData(data[0]).subscribe(ts=>
                            {
                                  /*this.showreceipt=true;
                                  this.cdr.detectChanges();*/

                                  this.viewReceipt();

                              })

 
                      }
                    
                  })

                
                 
              // Close the Razorpay modal manually if needed
              //rzp.close();
              
                
            }


            //----------------- New code ---------------------

            this.vendorDataService.createOrder(this.finalamount,this.applicablecurrency!).subscribe(order=>
            {
                  const RazorpayOptions=
                  {
                      description: "Soulswed Payment",
                      currency:this.applicablecurrency,
                      amount:this.finalamount,
                      name:'SoulsWed',
                      key:'rzp_test_cpXOU6uqzQHCc6',
                      //key:'rzp_live_dxvVlB5dXdaJNO',    // live key
                      image:'../../../assets/images/Logo/Faveicon.png',
                      order_id: order.id,
             
                      prefill:
                      {
                          name:this.currentUser?.username,
                          email:this.currentUser?.email,
                          phone:this.currentUser?.phonenumber
                      },
                      theme:
                      {
                          color:'#ebe834'   //#f37254'
                      },
                      handler: (response: RazorpayPaymentResponse) => 
                      {
                          console.log('Payment success event:', response);

                          const paymentData = 
                          {
                            razorpay_order_id: response.razorpay_order_id,
                            razorpay_payment_id: response.razorpay_payment_id,
                            razorpay_signature: response.razorpay_signature,
                          };

                          this.vendorDataService.verifyPayment(paymentData).subscribe(
                            (verificationResponse) => 
                            {
                              if (verificationResponse.status === 'success') 
                              {
                                alert('Payment successful');
                                successCallBack(response);
                              } 
                              else 
                              {
                                alert('Payment verification failed');
                              }

                            });
                          
                      },
                  
                      modal:
                      {
                          ondismiss:()=>
                          {
                              console.log('dismissed')
                          }
                      }
                  }

                  //------------ Define failure call back -----------------------
                  const failureCallBack = (error: RazorpayErrorResponse) => 
                  {
                        console.log('Payment failed');
                       // console.log(error.error);
                  };

                  ///Razorpay.open(RazorpayOptions,successCallBack,failureCallBack);
                  console.log('Called Razor pay for payment ....')
  
                  const rzp = new Razorpay(RazorpayOptions);
                  rzp.on('payment.error', (response: RazorpayErrorResponse) => 
                  {
                      console.log('Payment error event:', response);
                      failureCallBack(response);
                  });
  
                  //------ Open razor pay modal ---------------------------
                  rzp.open();



              })

            // ------------- New code end ---------------------

        }
        else
        {
          alert('No amount to pay')
        }
       
        
   }

   formatNumber() 
   {
     this.baseprice = parseFloat(this.decimalPipe.transform(this.baseprice, '1.0-0')!.replace(/,/g, ''));
     this.finalamount = parseFloat(this.decimalPipe.transform(this.finalamount, '1.0-0')!.replace(/,/g, ''));
   }

   viewReceipt() 
   {
    this.router.navigate(['/viewreceipt']).then(() => 
    {
      window.location.reload();
      //this.cdr.detectChanges();
    });
  }

  disableManualInput(event: KeyboardEvent) {
    event.preventDefault(); // Prevent manual keyboard input
  }
  

}
