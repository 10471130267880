<head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
</head>


<!-- For larger screens-->
<div class="d-none d-md-block"> 

    <div class="container-fluid negative-margin-top">

        <div class="row ">

            <div class="col-md-1">
                <div class="row pl-3">
                    <app-goback></app-goback>
                 </div>
            </div>

            <div class="col-md-3"></div>
            <div class="col-md-4 pt-3">
                <span class="flex justify-center soulsWedHeadingFont align-top">{{currentVendor?.vendorname}}{{titleText}}</span>
            </div>
            <div class="col-md-2">
                <button class="border button-style-5" (click)="addEntry()" >Add Calendar Entry&nbsp;+</button>
            </div>

          
            
        </div>

        <div *ngIf="showAddBlock">

            <div class="row pt-5">

               <div class="row">

                    <div class="col-md-2 ml-6">
                        <label for="startDate" class="form-label soulsWedNormalFont">Start Date </label>
                        <input type="date" class="form-control me-2" id="startDate" [(ngModel)]="startDateString" (change)="onStartDateChange()" [min]="minStartDate" (keydown)="disableManualInput($event)">      
                    </div>

                    <div class="col-md-2">
                        <label for="startTime" class="form-label soulsWedNormalFont">Start Time </label>
                        <input type="time" class="form-control me-2" id="startTime" [(ngModel)]="startTimeString" (keydown)="disableManualInput($event)">           
                    </div>
                
                    <div class="col-md-2 ml-14">
                        <label for="endDate" class="form-label soulsWedNormalFont">End Date</label>
                        <input type="date" class="form-control" id="endDate" [(ngModel)]="endDateString" [min]="startDateString" (keydown)="disableManualInput($event)">   
                    </div>

                    <div class="col-md-2">
                        <label for="endTime" class="form-label soulsWedNormalFont">End Time</label>
                        <input type="time" class="form-control" id="endTime" [(ngModel)]="endTimeString" (keydown)="disableManualInput($event)">
                    </div>
                
               </div>

               <div class="row pt-4">

                    <div class="col-md-4 ml-6">
                        <label for="description" class="form-label soulsWedNormalFont">Description</label>
                        <input type="text" class="form-control" id="description" [(ngModel)]="description" maxlength="100" >
                    </div>

                    <div class="col-md-1 pt-4">
                        <button class="border button-style-5" id="submitbutton" (click)="addCalendarEntry()">Submit</button>
                    </div>

                    <div class="col-md-1 pt-4">
                        <button class="border button-style-5" id="cancelbutton" (click)="cancelAdd()">Cancel</button>
                    </div>

               </div>

                
                
               
                
           </div>

           
           <div class="row pt-1">

            <div class="col-md-1"></div>
        
            <div class="col-md-8 text-red-800 mb-0 pb-0 bg-orange-200">
              {{errorMessage}}
            </div>
        
            <div class="col-md-2"></div>
        
          </div>
        
        </div>
        

        <!--  Show Calendar   -->
        <div class="row pt-4">
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <div class="row text-center">
                    <div class="col-md-12 pt-2">
                      <span class="soulsWedSubTitleFont">{{ viewDate | date: 'MMMM yyyy' }}</span><br>
                      <button class="button-style-5 mt-4" mwlCalendarPreviousView   [view]="view" [(viewDate)]="viewDate">Previous</button>
                      
                      <button class="button-style-5 ml-12 mt-4" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate">&nbsp;&nbsp;Next&nbsp;&nbsp;</button>
                    </div>
                  </div>

                
        
                  <div class="calendar-container mt-6 ">
                    <mwl-calendar-month-view
                      [viewDate]="viewDate"
                      [events]="events"
                      [activeDayIsOpen]="false"
                      (dayClicked)="dayClicked($event.day)"
                      >
                    </mwl-calendar-month-view>
                  </div>

                  

            </div>
            <div class="col-md-2"></div>
        </div>


     
        <div class="row text-center">

          <div class="row position-relative pt-5 pl-5" *ngIf="calendarListLocalFiltered.length==0" > 
            <p>No calendar entries present currently</p>
          </div>

         <div class="row position-relative pt-5" *ngIf="calendarListLocalFiltered.length>0" >        
 
            <div class="row pb-8 flex align-middle">
                <div class="col-md-4"></div>
                <div class="col-md-4 soulsWedHeadingFont flex justify-center ">List of Events</div>
              <!--  <div class="col-md-2 flex justify-start align-middle">
                    <button class="border button-style-4" (click)="clearFilters()" >Clear Filters</button>
                </div>-->
                
            </div>

            
            <div *ngIf="showEditBlock">

                    <div class="row pt-2 pb-4">

                    <div class="col-md-2"></div>

                    <div class="col-md-1">
                        <label for="startDate" class="form-label soulsWedNormalFont">Start Date </label>
                         <input type="date" class="form-control me-2" id="startDate" [(ngModel)]="editStartDateString" (change)="onStartDateChange()" [min]="minStartDate" (keydown)="disableManualInput($event)">  
                         
                    </div>

                    <div class="col-md-1">
                        <label for="startTime" class="form-label soulsWedNormalFont">Start Time </label>
                        <input type="time" class="form-control me-2" id="startTime" [(ngModel)]="editStartTimeString" (keydown)="disableManualInput($event)">           
                    </div>
                
                    <div class="col-md-1 ml-14">
                        <label for="endDate" class="form-label soulsWedNormalFont">End Date</label>
                        <input type="date" class="form-control" id="endDate" [(ngModel)]="editStartDateString" [min]="startDateString" (keydown)="disableManualInput($event)">
                    </div>

                    <div class="col-md-1">
                        <label for="endTime" class="form-label soulsWedNormalFont">End Time</label>
                        <input type="time" class="form-control" id="endTime" [(ngModel)]="editEndTimeString" (keydown)="disableManualInput($event)">
                    </div>
                
                    <div class="col-md-3 ml-14">
                        <label for="description" class="form-label soulsWedNormalFont">Description</label>
                        <input type="text" class="form-control" id="description" [(ngModel)]="editDescription" maxlength="100" >
                    </div>

                     <div class="col-md-1 pt-4">
                        <button class="border button-style-5" id="submitbutton" (click)="editCalendarEntry()">Submit</button>
                    </div>

                    <div class="col-md-1 pt-4">
                        <button class="border button-style-5" id="cancelbutton" (click)="cancelEdit()">Cancel</button>
                    </div>
                
                </div>

           
                <div class="row pt-1">

                    <div class="col-md-1"></div>
        
                    <div class="col-md-8 text-red-800 mb-0 pb-0 bg-orange-200">
                        {{errorMessage}}
                    </div>
        
                    <div class="col-md-2"></div>
        
                 </div>
        
            </div>
            

            <div class="row pb-3 ml-10">
                <div class="col-md-2"></div>
                <div class="col-md-2 soulsWedNormalFont font-bold">Start Date</div>
                <div class="col-md-2 soulsWedNormalFont font-bold">End Date Date</div>
                <div class="col-md-4 soulsWedNormalFont font-bold">Description</div>
                

            </div>

            <div class="d-none d-md-block" *ngFor="let calendar of calendarListLocalFiltered">
                <div class="row pt-2 ml-10">

                    <div class="col-md-2"></div>
                    <div class="col-md-2 soulsWedNormalFont">{{calendar.startDate }}</div> 
                    <div class="col-md-2 soulsWedNormalFont">{{calendar.endDate }}</div>
                    <div class="col-md-3 soulsWedNormalFont">{{calendar.description}}</div>
                    <div class="col-md-1">
                        <button class="border button-style-5" id="editbutton" (click)="editEntry(calendar)">Edit</button>
                    </div>
                    <div class="col-md-1">
                        <button class="border button-style-5" id="deletebutton" (click)="deleteCalendarEntry(calendar)">Delete</button>
                    </div>
               
                         
                </div>
            </div>
         </div>



        
        </div>
    </div>
</div>



<div class="d-block d-md-none container-fluid">

        <div class="row">
            <div class="col-12">
                <div class="row ">
                    <app-goback></app-goback>
                 </div>
            </div>
        </div>

        <div class="row pt-3">
            <div class="col-12">
                <span class="flex justify-center soulsWedHeadingFont">{{currentVendor?.vendorname}}{{titleText}}</span>
            </div>
        </div>
        
        <div class="row pt-3">
            <div class="col-2"></div>
             <div class="col-10">
                    <button class="border button-style-5" (click)="addEntry()" >Add Calendar Entry&nbsp;+</button>
            </div>
        </div>

        <div *ngIf="showAddBlock">

            <div class="row pt-4">

                <div class="col-11">
                    <label for="startDate" class="form-label soulsWedNormalFont">Start Date </label>
                    <input type="date" class="form-control me-2" id="startDate" [(ngModel)]="startDateString" (change)="onStartDateChange()" [min]="minStartDate" (keydown)="disableManualInput($event)"> 
                              
                </div>

                <div class="col-11 pt-2">
                    <label for="startTime" class="form-label soulsWedNormalFont">Start Time </label>
                    <input type="time" class="form-control me-2" id="startTime" [(ngModel)]="startTimeString" (keydown)="disableManualInput($event)">           
                </div>
                
                <div class="col-11 pt-2">
                    <label for="endDate" class="form-label soulsWedNormalFont">End Date</label>
                    <input type="date" class="form-control" id="endDate" [(ngModel)]="endDateString" [min]="startDateString" (keydown)="disableManualInput($event)">               
                </div>

                <div class="col-11 pt-2">
                    <label for="endTime" class="form-label soulsWedNormalFont">End Time</label>
                    <input type="time" class="form-control" id="endTime" [(ngModel)]="endTimeString" (keydown)="disableManualInput($event)">
                </div>
                
                <div class="col-11 pt-2">
                    <label for="description" class="form-label soulsWedNormalFont">Description</label>
                    <input type="text" class="form-control" id="description" [(ngModel)]="description" maxlength="100" >
                </div>

                <div class="row  pt-4">
                    <div class="col-5">
                        <button class="border button-style-5" id="submitbutton" (click)="addCalendarEntry()">Submit</button>
                    </div>

    
                    <div class="col-5">
                        <button class="border button-style-5" id="cancelbutton" (click)="cancelAdd()">Cancel</button>
                    </div>
                </div>

                
                
           </div>
        
           
            <div class="col-md-8 text-red-800 mb-0 pb-0 bg-orange-200">
              {{errorMessage}}
            </div>
        
          
        
        </div>

         <!--  Show Calendar   -->
         <div class="row pt-4">
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <div class="row text-center">
                    <div class="col-md-12 pt-2">
                      <span class="soulsWedSubTitleFont">{{ viewDate | date: 'MMMM yyyy' }}</span><br>
                      <button class="button-style-5 mt-4" mwlCalendarPreviousView   [view]="view" [(viewDate)]="viewDate">Previous</button>
                      
                      <button class="button-style-5 ml-12 mt-4" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate">&nbsp;&nbsp;Next&nbsp;&nbsp;</button>
                    </div>
                  </div>

                
        
                  <div class="calendar-container mt-6 ">
                    <mwl-calendar-month-view
                      [viewDate]="viewDate"
                      [events]="events"
                      [activeDayIsOpen]="false"
                      (dayClicked)="dayClicked($event.day)"
                      >
                    </mwl-calendar-month-view>
                  </div>

                  

            </div>
            <div class="col-md-2"></div>
        </div>

        <div class="row text-center">
            <div class="row position-relative pt-5 pl-5" *ngIf="calendarListLocalFiltered.length==0" > 
              <p>No calendar entries present currently</p>
            </div>
  
            <div class="row position-relative pt-5" *ngIf="calendarListLocalFiltered.length>0" >        
   
              <div class="row pb-8">
                  <div class="col-7 soulsWedHeadingFont">List of Events</div>
                <!--  <div class="col-5">
                      <button class="border button-style-4" (click)="clearFilters()" >Clear</button>
                  </div>-->
                  
              </div>

              <div *ngIf="showEditBlock">

                <div class="row pt-2 pb-4">
    
                    
    
                    <div class="col-11">
                        <label for="startDate" class="form-label soulsWedNormalFont">Start Date </label>
                        <input type="date" class="form-control me-2" id="startDate" [(ngModel)]="editStartDateString" (change)="onStartDateChange()" [min]="minStartDate" (keydown)="disableManualInput($event)">  
                             
                    </div>
                    <div class="col-11 pt-2">
                        <label for="startTime" class="form-label soulsWedNormalFont">Start Time </label>
                        <input type="time" class="form-control me-2" id="startTime" [(ngModel)]="editStartTimeString" (keydown)="disableManualInput($event)">           
                    </div>
                    
                    <div class="col-11 pt-2">
                        <label for="endDate" class="form-label soulsWedNormalFont">End Date</label>
                        <input type="date" class="form-control" id="endDate" [(ngModel)]="editStartDateString" [min]="startDateString" (keydown)="disableManualInput($event)">
                    </div>

                    <div class="col-11 pt-2">
                        <label for="endTime" class="form-label soulsWedNormalFont">End Time</label>
                        <input type="time" class="form-control" id="endTime" [(ngModel)]="editEndTimeString" (keydown)="disableManualInput($event)">
                    </div>
                    
                    <div class="col-11pt-2">
                        <label for="description" class="form-label soulsWedNormalFont">Description</label>
                        <input type="text" class="form-control" id="description" [(ngModel)]="editDescription" maxlength="100" >
                    </div>

                    <div class="row pt-4">

                        <div class="col-5 ">
                            <button class="border button-style-5" id="submitbutton" (click)="editCalendarEntry()">Submit</button>
                        </div>
        
                        <div class="col-5">
                            <button class="border button-style-5" id="cancelbutton" (click)="cancelEdit()">Cancel</button>
                        </div>

                    </div>
                    
                    
               </div>
    
               
               <div class="row pt-1">
    
                <div class="col-md-1"></div>
            
                <div class="col-md-8 text-red-800 mb-0 pb-0 bg-orange-200">
                  {{errorMessage}}
                </div>
            
                <div class="col-md-2"></div>
            
              </div>
            
            </div>

            <div *ngFor="let calendar of calendarListLocalFiltered">

                <div class="row pt-6 pb-4 border-1 ">

                    <div class="row">
                        <div class="col-5">Start Date</div>
                        <div class="col-5 ">{{calendar.startDate }}</div> 
                    </div>

                    <div class="row pt-2">
                        <div class="col-5">End Date</div>
                        <div class="col-5 ">{{calendar.endDate }}</div> 
                    </div>

                     <div class="row pt-2">
                        <div class="col-5">Description</div>
                        <div class="col-5 ">{{calendar.description}}</div> 
                    </div>
                    
                    <div class="row pt-4">
                        <div class="col-5">
                            <button class="border button-style-5" id="editbutton" (click)="editEntry(calendar)">Edit</button>
                        </div>
                        <div class="col-5">
                            <button class="border button-style-5" id="deletebutton" (click)="deleteCalendarEntry(calendar)">Delete</button>
                        </div>
                    </div>
              

                   
               
                         
                </div>
             </div>
            
        </div>
</div>




<!--================================================================================-->




        

    


    



     

    


       
