import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CountryData, CurrencyData, ExchangeRateData, VendorID, VendorSummaryData } from '../../../models/datamodels';
import { EnvironmentDataServiceService } from '../../../services/environment-data-service.service';
import { MasterdataserviceService } from '../../../services/masterdataservice.service';
import { UserServiceService } from '../../../services/user-service.service';
import { VendorDataServiceService } from '../../../services/vendor-data-service.service';
import { GobackComponent } from '../../goback/goback.component';
import { SignInComponent } from '../../usermgmt/sign-in/sign-in.component';
import { DeleteconfirmationmodalComponent } from './deleteconfirmationmodal/deleteconfirmationmodal.component';


@Component({
  selector: 'app-uservendorlisting',
  standalone: true,
  imports: [MatCardModule,CommonModule,RouterModule,DeleteconfirmationmodalComponent,FormsModule,GobackComponent],
  templateUrl: './uservendorlisting.component.html',
  styleUrl: './uservendorlisting.component.css'
})

export class UservendorlistingComponent implements AfterViewInit,OnInit
{

  @ViewChild('deleteModal') deleteModal!: DeleteconfirmationmodalComponent;

  vendorsListOriginal:VendorSummaryData[]=[];
  vendorsList:VendorSummaryData[]=[];
  serverpath= environment.middletierhost+'/uploads';  //---serverpath for images----   
  countriesList:CountryData[] =[];  
  countriesListFiltered: CountryData[] =[]; 
  cityList:string[]=[];
  currentIndex: number = 0;    
  favourites:VendorID[]=[];  
  iduser=0;  
  isAdmin:boolean=false;

  selectedVendor:VendorSummaryData|null=null;
  selectedCountry:CountryData|null=null;
  selectedCity:string|null=null;

  showSearch:boolean=false;
  searchButtonText:string='Search Vendors';
  searchQuery: string = '';
  fxList:ExchangeRateData[]=[];
  selectedCurrency:CurrencyData|null=null;

  titleText:string='Manage Vendors';
  
  constructor(private router:Router,public dialog: MatDialog,private vendorDataService:VendorDataServiceService , private environmentDataService : EnvironmentDataServiceService,private masterDataService:MasterdataserviceService,private userDataService:UserServiceService){}

  ngAfterViewInit() 
  {
    // ViewChild is set after the view has been initialized
  }

  ngOnInit(): void 
  {

   
    
    this.environmentDataService.currentCurrency.subscribe(data=>
      {
            this.selectedCurrency=data;
  
            this.masterDataService.getExchangeRates().subscribe(data=>
            {
              this.fxList=data;
              console.log('Exchange rates fetched :',this.fxList);
            })
        });


    //--------- get user favourites -------------

    this.environmentDataService.currentUser.subscribe(user=>
      {
          if (user)
          {
              this.iduser=user.iduser;

              let useridtouse=this.iduser; //if admin user, retrieve all vendors

              if(user.usertype=='Admin')
                  this.isAdmin=true;

              if(this.isAdmin)
                  useridtouse=0;

             this.vendorDataService.getUserVendors(useridtouse,0,0).subscribe
             //  this.vendorDataService.getUserVendors(37,0,0).subscribe
             (data=>
              {
                this.vendorsListOriginal=data;       
                console.log('Hotels list revieved with number ' , data.length);
                console.log('Hotels list copied with number ' , this.vendorsListOriginal.length);
        
                this.vendorsList = this.vendorsListOriginal;
        
                this.masterDataService.getCountries().subscribe
                (
                    data=>
                    {
                      this.countriesList=data;
        
                      // Extract unique idcountry from vendorsList
                      const vendorCountries = new Set(this.vendorsList.map(vendor => vendor.idcountry));
        
                         // Filter countriesList based on idcountry in vendorCountries
                      this.countriesListFiltered = this.countriesList.filter(country => vendorCountries.has(country.idcountry)).sort();
  
                      //this.cityList= this.vendorsList.map(vendor => vendor.city);
                      this.cityList = [...new Set(this.vendorsList.map(vendor => vendor.city).filter(city => city && city.trim() !== ''))].sort();
        
                    })
        
                    
                
              })  

              //------- populate current user favourites list ------------------
              this.userDataService.getUserFavourites(this.iduser).subscribe
              (data=>
               {
                  this.favourites=data;
              })

          }
       })

    

  }


  updateDisplayedVendors(): void 
  {
    //this.vendorsList = this.vendorsListOriginal.slice(this.currentIndex, this.currentIndex + 3);
    this.vendorsList = this.vendorsListOriginal.sort((a, b) => {
      return a.vendorname.localeCompare(b.vendorname);
    });;

    if(this.selectedCountry)
    {
        this.vendorsList=this.vendorsList.filter(v=>v.idcountry==this.selectedCountry?.idcountry).sort((a, b) => {
          return a.vendorname.localeCompare(b.vendorname);
        });;

       // this.cityList= this.vendorsList.map(vendor => vendor.city);
      // this.cityList = [...new Set(this.vendorsList.map(vendor => vendor.city).filter(city => city && city.trim() !== ''))].sort();
      
  
       
      }
  
      if(this.selectedCity)
      {
        this.vendorsList=this.vendorsList.filter(v=>v.city==this.selectedCity).sort((a, b) => {
          return a.vendorname.localeCompare(b.vendorname);
        });;

      }
  
      if(this.selectedVendor)
      {
        this.vendorsList=this.vendorsList.filter(v=>v.idvendor==this.selectedVendor?.idvendor).sort((a, b) => {
          return a.vendorname.localeCompare(b.vendorname);
        });;
      }

  }



  //============== Function to updateselected country ============================
  onVendorSelect(selected: VendorSummaryData): void 
  {
  
    this.vendorDataService.changeData(selected);
    
    this.environmentDataService.changeCountryData(this.countriesList.find(c=>c.idcountry=selected.idcountry));
    
        
       
  }

  getStars(rating:string):string
  {
    if (rating=='5 star')
      return '*****'
      else if (rating=='4 star')
      return '****'
      else if (rating=='3 star')
        return '***'
      else if (rating=='2 star')
        return '**'
      else if (rating=='1 star')
        return '*'
      else
        return '';
  }

  toggleFavourites(idvendor:number)
  {


    //-------- Ask to sign in if not signed in --------------------

    this.environmentDataService.currentUser.subscribe(user=>
      {
        if (user)
        {
            this.iduser=user.iduser;
        }
        else
        {
          //-------- If not logged in, ask for user to log-in ------------------
          const dialogRef = this.dialog.open(SignInComponent, 
          {
            width: '250px'
          });
      
          dialogRef.afterClosed().subscribe(result => 
          {
            this.router.navigate(['/home']);
            console.log('The dialog was closed');
          });

        }
      }
    )

    if(this.iduser>0)
    {
        //--------------- Add or remove user favourites as applicable -------------------
    if(!this.favourites.find(c=>c.idvendor==idvendor))
      {
        //this.favourites.push(idvendor);
        this.userDataService.addUserFavourites({iduser:this.iduser,idvendor:idvendor}).subscribe
        (data=>
          {
            this.userDataService.getUserFavourites(this.iduser).subscribe
            (
              info=>
                {
                  this.favourites=info;
                  console.log('favourites list :',this.favourites);
                }
            )
          }
        )
      }
      else
      {
        //this.favourites=this.favourites.filter(n=>n!=idvendor)
        //this.favourites.push(idvendor);
        this.userDataService.deleteUserFavourites({iduser:this.iduser,idvendor:idvendor}).subscribe
        (data=>
          {
            this.userDataService.getUserFavourites(this.iduser).subscribe
            (
              info=>
                {
                  this.favourites=info;
                  console.log('favourites list :',this.favourites);
                }
            )
          }
        )
      }
    }

    

    
  }

  isFavourite(idvendor:number):boolean
  {
    if(this.favourites.find(c=>c.idvendor==idvendor))
      return true;
    else
    return false;
  }


   //============== Function to updateselected country ============================
   onVendorSelected(selected: VendorSummaryData): void 
   {
     this.selectedVendor = selected;

     if(this.selectedVendor)
      {
        this.vendorsList=this.vendorsList.filter(v=>v.idvendor==this.selectedVendor?.idvendor).sort((a, b) => {
          return a.vendorname.localeCompare(b.vendorname);
        });;
      }


    // this.updateDisplayedVendors();
      
   }

   //============== Function to updateselected country ============================
   onCountrySelected(selected: CountryData): void 
   {
     this.selectedCountry = selected;
     this.selectedCity=null;
     this.selectedVendor=null;

     //this.vendorsList = this.vendorsListOriginal.slice(this.currentIndex, this.currentIndex + 3);
    this.vendorsList = this.vendorsListOriginal.sort((a, b) => 
    {
      return a.vendorname.localeCompare(b.vendorname);
    });;

    if(this.selectedCountry)
    {
        this.vendorsList=this.vendorsList.filter(v=>v.idcountry==this.selectedCountry?.idcountry).sort((a, b) => {
          return a.vendorname.localeCompare(b.vendorname);
        });;
        
       // this.cityList= this.vendorsList.map(vendor => vendor.city);
       this.cityList = [...new Set(this.vendorsList.map(vendor => vendor.city).filter(city => city && city.trim() !== ''))].sort();
      
  
       
      }

     //this.updateDisplayedVendors();    
   
     
     
      
   }

   //============== Function to updateselected country ============================
   onCitySelected(selected: string): void 
   {
     this.selectedCity = selected;
     this.selectedVendor=null;

     if(this.selectedCity)
      {
        this.vendorsList=this.vendorsList.filter(v=>v.city==this.selectedCity).sort((a, b) => {
          return a.vendorname.localeCompare(b.vendorname);
        });;

      }

    // this.updateDisplayedVendors();
    
      
   }

   //============Clear filters ====================================

   clearFilters()
   {

    this.selectedCountry = null;
    this.selectedVendor=null;
    this.selectedCity=null;
    this.searchQuery='';

   // this.updateDisplayedVendors();

    
     //this.cityList= this.vendorsList.map(vendor => vendor.city);
     this.cityList = [...new Set(this.vendorsListOriginal.map(vendor => vendor.city).filter(city => city && city.trim() !== ''))].sort();
     this.vendorsList = this.vendorsListOriginal.sort((a, b) => {
      return a.vendorname.localeCompare(b.vendorname);
    });

    

    
   }

   enableSearch()
   {
     console.log('Enable Search Clicked');
     this.showSearch=!this.showSearch;
 
     if(this.showSearch)
       this.searchButtonText="Hide search panel"
     else
       this.searchButtonText="Search Venues"
     
   }

  convertCurrency(fromCurrency:string,toCurrency:string,amount:number):number
   {
      //console.log('Currency conversion from ' ,fromCurrency ,' to ',toCurrency);

      if(fromCurrency==toCurrency)
        return amount;
      
      const existingRatetoUSD = this.fxList.find(rate =>
          rate.fromCurrency == fromCurrency && rate.toCurrency == 'USD');


       //   console.log('existingRatetoUSD ' ,existingRatetoUSD , '. Rate :',existingRatetoUSD!.rate);
          

        if (existingRatetoUSD) 
        {
          const existingRatefromUSD = this.fxList.find(rate =>
            rate.fromCurrency == 'USD' && rate.toCurrency == toCurrency);

        //    console.log('existingRatefromUSD ' ,existingRatefromUSD , '. Rate :',existingRatefromUSD!.rate);

            if(existingRatefromUSD)
            {
              return amount*existingRatetoUSD.rate*existingRatefromUSD?.rate!
            }
            else
            return amount;
        
        } 
        else 
        {
            return amount;
            
        }
   }

   openDeleteModal()
  {
    this.deleteModal?.openModal();
  }

  editVendor(vendor:VendorSummaryData)
  {
    console.log('Edit vendor called with id :',vendor.idvendor);

    this.vendorDataService.changeData(vendor);
  }

  deleteVendor(vendor:VendorSummaryData)
  {
    console.log('Delete vendor called with id :',vendor);
    
    this.vendorDataService.deleteVendor(vendor.idvendor).subscribe(
      data=>
        {
          alert("Vendor Deleted");

          let useridtouse=this.iduser;

          if(this.isAdmin)
            useridtouse=0;

          this.vendorDataService.getUserVendors(useridtouse,0,0).subscribe
          // this.vendorDataService.getUserVendors(37,0,0).subscribe
          (data=>
          {
                this.vendorsListOriginal=data;       
                console.log('User vendors list revieved with number ' , data.length);
                console.log('User vendors list copied with number ' , this.vendorsListOriginal.length);

                this.updateDisplayedVendors();
    
          }) 

          //------- populate current user favourites list ------------------
          this.userDataService.getUserFavourites(this.iduser).subscribe
          (data=>
           {
              this.favourites=data;
          })

        }
    )
        
    
  }

  searchVenuesByName(): void 
  {
     if (this.searchQuery.trim()) 
     {
         this.vendorsList = this.vendorsList.filter(vendor =>
         vendor.vendorname.toLowerCase().includes(this.searchQuery.toLowerCase()));
     } 
   }

}
