import { Component,OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { DecimalPipe } from '@angular/common';
import { RazorpayErrorResponse,RazorpayPaymentResponse } from '../../models/datamodels';
import { VendorDataServiceService } from '../../services/vendor-data-service.service';
import { EnvironmentDataServiceService } from '../../services/environment-data-service.service';
import { MatDialog } from '@angular/material/dialog';
import { SignInComponent } from '../usermgmt/sign-in/sign-in.component';
import { Router } from '@angular/router';
import { GobackComponent } from '../goback/goback.component';
import { ExchangeRateData } from '../../models/datamodels';
import { MasterdataserviceService } from '../../services/masterdataservice.service';

declare var Razorpay:any;

@Component({
  selector: 'app-sakhiservice',
  standalone: true,
  imports: [MatCardModule,CommonModule,DecimalPipe,SignInComponent,GobackComponent],
  templateUrl: './sakhiservice.component.html',
  styleUrl: './sakhiservice.component.css',
  providers: [DecimalPipe]
})

export class SakhiserviceComponent implements OnInit
{

  titleText:string='Sakhi Service';
   idvendor:number=0;
  iduser:number=0;
  vendorname:string='';
  username:string='';
  useremail:string='';
  transactiondate:string='';
  finalamount:number=0;
  isOnPage:boolean=false;

  defaultCurrency:string='INR';
  svc1OriginalPrice:number=399;
  svc1DiscountPrice:number=249;
  svc2OriginalPrice:number=999;
  svc2DiscountPrice:number=492;
  svc3OriginalPrice:number=4999;
  svc3DiscountPrice:number=2499;

  currency:string|null=null;

  fxList: ExchangeRateData[] = [];



  constructor(private masterDataService:MasterdataserviceService,private router:Router,public dialog: MatDialog,private vendorDataService:VendorDataServiceService,private decimalPipe: DecimalPipe,private environmentDataService:EnvironmentDataServiceService)
  {}

  ngOnInit(): void 
  {
      this.environmentDataService.currentCurrency.subscribe(data=>
      {
        this.currency=data?.currencycode!;

        this.masterDataService.getExchangeRates().subscribe(data => 
        {
            this.fxList = data;
            console.log('Exchange rates fetched:', this.fxList);
        });

      })



  }

  convertCurrency(fromCurrency:string,toCurrency:string,amount:number):number
   {
     // console.log('Currency conversion from ' ,fromCurrency ,' to ',toCurrency);

      if(fromCurrency==toCurrency)
        return amount;
    
     const existingRatetoUSD = this.fxList.find(rate =>
          rate.fromCurrency == fromCurrency && rate.toCurrency == 'USD');


      //    console.log('existingRatetoUSD ' ,existingRatetoUSD , '. Rate :',existingRatetoUSD!.rate);
          

        if (existingRatetoUSD) 
        {
          const existingRatefromUSD = this.fxList.find(rate =>
            rate.fromCurrency == 'USD' && rate.toCurrency == toCurrency);

        //    console.log('existingRatefromUSD ' ,existingRatefromUSD , '. Rate :',existingRatefromUSD!.rate);

            if(existingRatefromUSD)
            {
              return amount*existingRatetoUSD.rate*existingRatefromUSD?.rate!
            }
            else
            return amount;
        
        } 
        else 
        {
            return amount;
            
        }
   }

  makePayment(amount:number)
  {

      this.environmentDataService.currentUser.subscribe(user=>
        {
          if (user)
          {
              this.iduser=user.iduser;
              this.username=user.username;
              this.useremail=user.email;

              this.isOnPage=true;

              this.invokeRazorPay(amount);
          }
          else
          {
            if(!this.isOnPage)
            {
               //alert('Log in dialog invoked from ngoninit');
              //-------- If not logged in, ask for user to log-in ------------------
              const dialogRef = this.dialog.open(SignInComponent, 
              {
                width: '300px'
              });
          
              dialogRef.afterClosed().subscribe(result => 
              {
                this.router.navigate(['/home']);
                console.log('The dialog was closed');
              });

            }
            
          }
        })
          
    }

    invokeRazorPayold(amount:number)
    {

      if(amount>0)
        {
  
            this.finalamount=amount*100*1.18;
            this.formatNumber();
  
            const successCallBack = (paymentId: RazorpayPaymentResponse)=>
            {
                console.log('Payment successful with payment id :',paymentId.razorpay_payment_id);
                this.transactiondate=new Date().toISOString();
  
              this.vendorDataService.postTransaction(
                  {idtransaction:0,iduser:this.iduser,idvendor:this.idvendor,amount:this.finalamount/100,vendorname:'Sakhi Service',username:this.username,useremail:this.useremail,transactiondate:this.transactiondate,currency:this.currency!,bookingstart:this.transactiondate,bookingend:this.transactiondate,paidamount:this.finalamount/100,balanceamount:0,balanceduedate:this.transactiondate
                    
                  });
                
            }
  
            const RazorpayOptions=
            {
              description: "Soulswed Payment",
              currency:this.currency,
              amount:this.finalamount,
              name:'SoulsWed',
              key:'rzp_test_cpXOU6uqzQHCc6',
              //key:'rzp_live_dxvVlB5dXdaJNO',    // live key
              image:'../../assets/images/Logo/Faveicon.png',
           
              prefill:
                {
                    name:'',
                    email:'',
                    phone:''
                },
              theme:
                {
                  color:'#ebe834'   //#f37254'
                },
              handler: (response: RazorpayPaymentResponse) => 
                {
                  console.log('Payment success event:', response);
                  successCallBack(response);
                },
                
              modal:
                {
                  ondismiss:()=>
                    {
                      console.log('dismissed')
                    }
                }
            }
    
            
    
            const failureCallBack = (error: RazorpayErrorResponse) => 
              {
                  console.log('Payment failed');
                 // console.log(error.error);
              };
    
            ///Razorpay.open(RazorpayOptions,successCallBack,failureCallBack);
            console.log('Called Razor pay for payment ....')
  
            const rzp = new Razorpay(RazorpayOptions);
  
         /*   rzp.on('payment.success', (response: RazorpayPaymentResponse) => {
              console.log('Payment success event:', response);
              successCallBack(response);
            });*/
  
            rzp.on('payment.error', (response: RazorpayErrorResponse) => {
              console.log('Payment error event:', response);
              failureCallBack(response);
            });
  
            rzp.open();
  
          
        }
        else
        {
          alert('No amount to pay')
        }
    }

    invokeRazorPay(amount:number)
    {

      if(amount>0)
      {
  
            this.finalamount=amount*100*1.18;
            this.formatNumber();
            this.transactiondate=new Date().toISOString();
            
  

            const successCallBack = (paymentId: RazorpayPaymentResponse)=>
            {
              console.log('Payment successful with payment id :',paymentId.razorpay_payment_id);

              this.vendorDataService.postTransaction(
                {idtransaction:0,iduser:this.iduser,idvendor:this.idvendor,amount:this.finalamount/100,vendorname:'Sakhi Service',username:this.username,useremail:this.useremail,transactiondate:this.transactiondate,currency:this.currency!,bookingstart:this.transactiondate,bookingend:this.transactiondate,paidamount:this.finalamount/100,balanceamount:0,balanceduedate:this.transactiondate
                    
                }).subscribe(data=>
                {
                  console.log('Response from post transaction :',data[0]);

                  if(data[0].idtransaction>0)
                  {
                         //---------Change Transaction ----------------------
                          this.vendorDataService.changeTransactionData(data[0]).subscribe(ts=>
                          {
                              this.viewReceipt();

                            })

                   }
                  
                })
              
            }


            //----------------- New code ---------------------

            this.vendorDataService.createOrder(this.finalamount,this.currency!).subscribe(order=>
              {
                    const RazorpayOptions=
                    {
                        description: "Soulswed Payment",
                        currency:this.currency,
                        amount:this.finalamount,
                        name:'SoulsWed',
                        key:'rzp_test_cpXOU6uqzQHCc6',
                        //key:'rzp_live_dxvVlB5dXdaJNO',    // live key
                        image:'../../../assets/images/Logo/Faveicon.png',
                        order_id: order.id,
               
                        prefill:
                        {
                            name:this.username,
                            email:this.useremail
                          
                        },
                        theme:
                        {
                            color:'#ebe834'   //#f37254'
                        },
                        handler: (response: RazorpayPaymentResponse) => 
                        {
                            console.log('Payment success event:', response);
  
                            const paymentData = 
                            {
                              razorpay_order_id: response.razorpay_order_id,
                              razorpay_payment_id: response.razorpay_payment_id,
                              razorpay_signature: response.razorpay_signature,
                            };
  
                            this.vendorDataService.verifyPayment(paymentData).subscribe(
                              (verificationResponse) => 
                              {
                                if (verificationResponse.status === 'success') 
                                {
                                  alert('Payment successful');
                                  successCallBack(response);
                                } 
                                else 
                                {
                                  alert('Payment verification failed');
                                }
  
                              });
                            
                        },
                    
                        modal:
                        {
                            ondismiss:()=>
                            {
                                console.log('dismissed')
                            }
                        }
                    }
  
                    //------------ Define failure call back -----------------------
                    const failureCallBack = (error: RazorpayErrorResponse) => 
                    {
                          console.log('Payment failed');
                         // console.log(error.error);
                    };
  
                    ///Razorpay.open(RazorpayOptions,successCallBack,failureCallBack);
                    console.log('Called Razor pay for payment ....')
    
                    const rzp = new Razorpay(RazorpayOptions);
                    rzp.on('payment.error', (response: RazorpayErrorResponse) => 
                    {
                        console.log('Payment error event:', response);
                        failureCallBack(response);
                    });
    
                    //------ Open razor pay modal ---------------------------
                    rzp.open();
  
  
  
                })

            
          
        }
        else
        {
          alert('No amount to pay')
        }
    }


    formatNumber() 
    {
      this.finalamount = parseFloat(this.decimalPipe.transform(this.finalamount, '1.0-0')!.replace(/,/g, ''));
    }

    viewReceipt() 
   {
    this.router.navigate(['/viewreceipt']).then(() => 
    {
      window.location.reload();
      //this.cdr.detectChanges();
    });
  }

}
