import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { CategoryData, CityData, CountryData, SearchData, VendorSummaryData } from '../../models/datamodels';
import { EnvironmentDataServiceService } from '../../services/environment-data-service.service';
import { FilterDataServiceService } from '../../services/filter-data-service.service';
import { MasterdataserviceService } from '../../services/masterdataservice.service';
import { VendorDataServiceService } from '../../services/vendor-data-service.service';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule],
  templateUrl: './search.component.html',
  styleUrl: './search.component.css'
})

export class SearchComponent implements OnInit {

  countries: CountryData[] = [];
  categories: CategoryData[] = [];
  cities: CityData[] = [];
  vendorsList: VendorSummaryData[] = [];
  searchQuery: string = '';
  filteredVendors: VendorSummaryData[] = [];  // List of vendors filtered by search input
  showDropdown: boolean = false;  // Control whether the dropdown should be shown

  countriesFiltered: CountryData[] = [];
  categoriesFiltered: CategoryData[] = [];
  citiesFiltered: CityData[] = [];
  vendorsListFiltered: VendorSummaryData[] = [];

  selectedCountry: CountryData | null = null;
  selectedCategory: CategoryData | null = null;
  selectedCity: CityData | null = null;
  selectedVendor: VendorSummaryData | null = null;
  startDate: Date | null = null;
  endDate: Date | null = null;
  guests: number | null = 0;

  errorMessage: string = "";

  constructor(private router: Router, private filterDataService: FilterDataServiceService,
    private masterDataService: MasterdataserviceService, private environmentDataService: EnvironmentDataServiceService,private vendorDataService:VendorDataServiceService) { }

  ngOnInit(): void {
    // Fetch and handle countries
    this.masterDataService.getVendorCountries().subscribe(data => 
    {
      this.countries = data;
      this.countriesFiltered=this.countries;


    });


    // Fetch and handle categories
    this.masterDataService.getCategories().subscribe(data => 
    {
      this.categories = data;
      this.categoriesFiltered=this.categories;

      
      
    });


    // Fetch and handle cities
    this.masterDataService.getVendorCities().subscribe(data => 
    {
        this.cities = data;
        this.citiesFiltered=this.cities;

    });

    

    // Fetch and handle vendors (names)
    this.vendorDataService.getAllVendors().subscribe(data => 
    {
      this.vendorsList = data;
      this.vendorsListFiltered=this.vendorsList;

    });

  }

  // Search function
  search() 
  {

    this.errorMessage = "";

    if(!this.selectedVendor)
    {

        if (!this.selectedCategory) 
        {
          this.errorMessage = "Please select a category";
          return;
        }
  
  
        if (!this.selectedCountry) 
        {
            this.errorMessage = "Please select a country";
            return;
        }
    }


    const data: SearchData = 
    {
      country: this.selectedCountry,
      category: this.selectedCategory,
      city: this.selectedCity,
      vendor: this.selectedVendor,
    };

    this.filterDataService.changeData(data);
    this.router.navigate(['/vendor']);
  }

  // Update selected category
  onCategorySelect(selected: CategoryData): void 
  {
    this.selectedCategory = selected;
    this.environmentDataService.changeCategoryData(selected);

    this.selectedCountry=null;
    this.selectedCity=null;
    this.selectedVendor=null;
    this.errorMessage = "";
    this.searchQuery="";

/*
    this.vendorsListFiltered=this.vendorsList;
    this.countriesFiltered=this.countries;
    this.citiesFiltered=this.cities;*/

    if(this.selectedCategory)
    {
        this.vendorsListFiltered=this.vendorsList.filter(v=>v.idcategory==this.selectedCategory?.idcategory);

        this.countriesFiltered=this.countries.filter(country => 
          this.vendorsListFiltered.some(vendor => vendor.idcountry === country.idcountry));

        this.citiesFiltered=this.cities.filter(city => 
          this.vendorsListFiltered.some(vendor => vendor.city === city.cityname));       

    }

    

  }

  // Compare categories
  compareCategories(c1: CategoryData, c2: CategoryData): boolean {
    return c1 && c2 ? c1.idcategory === c2.idcategory : c1 === c2;
  }


  // Update selected country
  onCountrySelect(selected: CountryData): void 
  {
    this.selectedCountry = selected;
    this.environmentDataService.changeCountryData(selected);

    this.selectedCity=null;
    this.selectedVendor=null;

    /*
    this.citiesFiltered=this.cities;
    this.vendorsListFiltered=this.vendorsList;*/

    this.errorMessage = "";
    this.searchQuery="";

    if(this.selectedCountry)
    {
      this.citiesFiltered=this.cities.filter(v=>v.idcountry==this.selectedCountry?.idcountry);
      this.vendorsListFiltered=this.vendorsList.filter(v=>v.idcountry==this.selectedCountry?.idcountry);
    }

    if(this.selectedCategory)
    {
        this.vendorsListFiltered=this.vendorsListFiltered.filter(v=>v.idcategory==this.selectedCategory?.idcategory);

        this.citiesFiltered=this.cities.filter(city => 
        this.vendorsListFiltered.some(vendor => vendor.city === city.cityname));     
        
       
    }
    

  }

  // Compare countries
  compareCountries(c1: CountryData, c2: CountryData): boolean 
  {
    return c1 && c2 ? c1.idcountry === c2.idcountry : c1 === c2;
  }

  
  // Update selected city
  onCitySelect(selected: CityData): void 
  {
    this.selectedCity = selected;
    this.environmentDataService.changeCity(selected);
    this.searchQuery="";

    this.selectedVendor=null;

   // this.vendorsListFiltered=this.vendorsList;

    if(this.selectedCity)
    {
      this.vendorsListFiltered=this.vendorsListFiltered.filter(v=>v.city==this.selectedCity?.cityname);
    }

    if(this.selectedCategory)
    {
        this.vendorsListFiltered=this.vendorsListFiltered.filter(v=>v.idcategory==this.selectedCategory?.idcategory);
         
    }

    if(this.selectedCountry)
    {
        
        this.vendorsListFiltered=this.vendorsListFiltered.filter(v=>v.idcountry==this.selectedCountry?.idcountry);
    }


  }

  // Compare cities
  compareCities(c1: CityData, c2: CityData): boolean {
    return c1 && c2 ? c1.idcity === c2.idcity : c1 === c2;
  }

  // Update selected vendor
  onVendorSelect(selected: VendorSummaryData): void 
  {
    this.selectedVendor = selected;
    this.environmentDataService.changeVendorData(selected);
  }

  // Compare vendors
  compareVendors(v1: VendorSummaryData, v2: VendorSummaryData): boolean 
  {
    return v1 && v2 ? v1.idvendor === v2.idvendor : v1 === v2;
  }

  searchVenuesByName(): void 
  {
    if (this.searchQuery.trim()) 
    {
      this.vendorsListFiltered = this.vendorsListFiltered.filter(vendor =>
        vendor.vendorname.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
      this.filteredVendors = this.vendorsListFiltered; // Update dropdown list
    } 
    else 
    {
      // Reset list if no search
     // this.vendorsListFiltered = this.vendorsListFiltered.slice();
      this.filteredVendors = [];
    }
  }

  clearFilters()
  {
    this.selectedCategory=null;
    this.selectedCountry=null;
    this.selectedCity=null;
    this.selectedVendor=null;
    this.searchQuery="";

    this.categoriesFiltered=this.categories;
    this.countriesFiltered=this.countries;
    this.citiesFiltered=this.cities;
    this.vendorsListFiltered=this.vendorsList;
  }

  // Function to handle vendor selection from the dropdown
  selectVendor(vendor: VendorSummaryData): void 
  {
    this.searchQuery = vendor.vendorname; // Set selected vendor's name in input
    this.showDropdown = false; // Hide the dropdown after selection
    this.selectedVendor = vendor; // Store selected vendor
  }

  // Function to navigate to the selected vendor's page
  // goToVendorPage(): void {
  //   if (this.selectedVendor) {
  //     this.router.navigate(['/vendor', this.selectedVendor.idvendor]);
  //   } else {
  //     this.errorMessage = "Please select a vendor to proceed";
  //   }
  // }
}
