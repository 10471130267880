import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CountryData, CurrencyData, ExchangeRateData, VendorID, VendorSummaryData } from '../../../models/datamodels';
import { EnvironmentDataServiceService } from '../../../services/environment-data-service.service';
import { MasterdataserviceService } from '../../../services/masterdataservice.service';
import { UserServiceService } from '../../../services/user-service.service';
import { VendorDataServiceService } from '../../../services/vendor-data-service.service';
import { SignInComponent } from '../../usermgmt/sign-in/sign-in.component';

@Component({
  selector: 'app-decoratorslistinghome',
  standalone: true,
  imports: [MatCardModule,CommonModule,RouterModule],
  templateUrl: './decoratorslistinghome.component.html',
  styleUrl: './decoratorslistinghome.component.css'
})
export class DecoratorslistinghomeComponent implements OnInit
{
  vendorsListOriginal:VendorSummaryData[]=[];
  vendorsList:VendorSummaryData[]=[];
  serverpath= environment.middletierhost+'/uploads';  //---serverpath for images----      
  currentIndex: number = 0; 
  countriesList:CountryData[] =[]; 
  favourites:VendorID[]=[];  
  iduser=0;  
  currentCurrency:CurrencyData|null=null;

  fxList:ExchangeRateData[]=[];
  selectedCurrency:CurrencyData|null=null;
  currentSection: string = 'new'; // Default section is 'new'

  constructor(private router:Router,public dialog: MatDialog,private vendorDataService:VendorDataServiceService , private environmentDataService : EnvironmentDataServiceService,private masterDataService:MasterdataserviceService,private userDataService:UserServiceService){}

  ngOnInit(): void 
  {
      this.environmentDataService.currentCurrency.subscribe(data=>
      {
            this.selectedCurrency=data;
  
            this.masterDataService.getExchangeRates().subscribe(data=>
            {
              this.fxList=data;
              console.log('Exchange rates fetched :',this.fxList);
            })
        });


        this.vendorDataService.getDecorators().subscribe
        (data=>
        {
          this.vendorsListOriginal=data;       
          console.log('Hotels list revieved with number ' , data.length);
          console.log('Hotels list copied with number ' , this.vendorsListOriginal.length);
  
          this.updateDisplayedVendors();
          
        }) 
  
        this.masterDataService.getCountries().subscribe
        (
          data=>
            {
              this.countriesList=data;
            }
        )
      //--------- get user favourites -------------

      this.environmentDataService.currentUser.subscribe(user=>
      {
          if (user)
          {
              this.iduser=user.iduser;

              this.userDataService.getUserFavourites(this.iduser).subscribe
              (data=>
               {
                  this.favourites=data;
              })
          }
       })


       
      
      

  }


  updateDisplayedVendors(): void {
    if (this.currentSection === 'new') {
      // Display the first 6 items for the 'Featured' section
      this.vendorsList = this.vendorsListOriginal.slice(0, 3);
    } else if (this.currentSection === 'featured') {
      // Without a date, simulate sorting by assuming reverse order
      this.vendorsList = [...this.vendorsListOriginal].reverse().slice(0, 3);
    } else if (this.currentSection === 'top-rated') {
      // Sort by rating using the existing 'getRating' logic
      this.vendorsList = [...this.vendorsListOriginal]
        .sort((a, b) => this.getRating(b.vendorrating) - this.getRating(a.vendorrating))
        .slice(0, 3);
    }
  }

  selectSection(section: string): void {
    this.currentSection = section;
    this.updateDisplayedVendors(); // Refresh vendors list based on the new section
  }

  getCountryName(idcountry: number): string 
  {
    const country = this.countriesList.find(c => c.idcountry === idcountry);
    return country ? country.countryName : '';
  }

  nextSlide(): void 
  {
    if (this.currentIndex + 3 < this.vendorsListOriginal.length) 
    {
      this.currentIndex += 3;
      this.updateDisplayedVendors();
    }
  }

  previousSlide(): void 
  {
    if (this.currentIndex - 3 >= 0) 
    {
      this.currentIndex -= 3;
      this.updateDisplayedVendors();
    }
  }

  //============== Function to updateselected country ============================
  onVendorSelect(selected: VendorSummaryData): void 
  {
  
    this.vendorDataService.changeData(selected);
    
    this.environmentDataService.changeCountryData(this.countriesList.find(c=>c.idcountry=selected.idcountry));

    this.router.navigate(['/vendordetails/'+selected.idvendor.toString()]);
    //this.router.navigate(['/vendordetails']);
    
        
       
  }

  getStars(rating:string):string
  {
    if (rating=='5 star')
      return '*****'
      else if (rating=='4 star')
      return '****'
      else if (rating=='3 star')
        return '***'
      else if (rating=='2 star')
        return '**'
      else if (rating=='1 star')
        return '*'
      else
        return '';
  }

  getRating(rating: string): number {
    // This function converts rating strings to numerical values
    switch (rating) {
      case '5 star':
        return 5;
      case '4 star':
        return 4;
      case '3 star':
        return 3;
      case '2 star':
        return 2;
      case '1 star':
        return 1;
      default:
        return 0;
    }
  }


  toggleFavourites(idvendor:number)
  {


    //-------- Ask to sign in if not signed in --------------------

    this.environmentDataService.currentUser.subscribe(user=>
      {
        if (user)
        {
            this.iduser=user.iduser;
        }
        else
        {
          //-------- If not logged in, ask for user to log-in ------------------
          const dialogRef = this.dialog.open(SignInComponent, 
          {
            width: '300px'
          });
      
          dialogRef.afterClosed().subscribe(result => 
          {
            this.router.navigate(['/home']);
            console.log('The dialog was closed');
          });

        }
      }
    )

    if(this.iduser>0)
    {
        //--------------- Add or remove user favourites as applicable -------------------
    if(!this.favourites.find(c=>c.idvendor==idvendor))
      {
        //this.favourites.push(idvendor);
        this.userDataService.addUserFavourites({iduser:this.iduser,idvendor:idvendor}).subscribe
        (data=>
          {
            this.userDataService.getUserFavourites(this.iduser).subscribe
            (
              info=>
                {
                  this.favourites=info;
                  console.log('favourites list :',this.favourites);
                }
            )
          }
        )
      }
      else
      {
        //this.favourites=this.favourites.filter(n=>n!=idvendor)
        //this.favourites.push(idvendor);
        this.userDataService.deleteUserFavourites({iduser:this.iduser,idvendor:idvendor}).subscribe
        (data=>
          {
            this.userDataService.getUserFavourites(this.iduser).subscribe
            (
              info=>
                {
                  this.favourites=info;
                  console.log('favourites list :',this.favourites);
                }
            )
          }
        )
      }
    }

    

    
  }

  isFavourite(idvendor:number):boolean
  {
    if(this.favourites.find(c=>c.idvendor==idvendor))
      return true;
    else
    return false;
  }


  convertCurrency(fromCurrency:string,toCurrency:string,amount:number):number
   {
      //console.log('Currency conversion from ' ,fromCurrency ,' to ',toCurrency);

      if(fromCurrency==toCurrency)
        return amount;

        const existingRatetoUSD = this.fxList.find(rate =>
          rate.fromCurrency == fromCurrency && rate.toCurrency == 'USD');


          //console.log('existingRatetoUSD ' ,existingRatetoUSD , '. Rate :',existingRatetoUSD!.rate);
          

        if (existingRatetoUSD) 
        {
          const existingRatefromUSD = this.fxList.find(rate =>
            rate.fromCurrency == 'USD' && rate.toCurrency == toCurrency);

            //console.log('existingRatefromUSD ' ,existingRatefromUSD , '. Rate :',existingRatefromUSD!.rate);

            if(existingRatefromUSD)
            {
              return amount*existingRatetoUSD.rate*existingRatefromUSD?.rate!
            }
            else
            return amount;
        
        } 
        else 
        {
            return amount;
            
        }
   }

   encodeUrl(url:string):string
   {
    return encodeURIComponent(url);
   }

   goToDecorators()
  {

    this.environmentDataService.setSessionData('savedCountry', null);
    this.environmentDataService.setSessionData('savedCity', null);
    this.environmentDataService.setSessionData('savedVendor', null);
    this.environmentDataService.setSessionData('savedSearchQuery', null);
    this.environmentDataService.setSessionData('savedShowSearch', null);
    this.router.navigate(['/decorators']);

  }

}
