<head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
</head>


<div class="container-fluid border-1">

    <!--
    <div class="row pt-3">
        <div class="col-md-12">
            <span class="flex justify-center soulsWedHeadingFont">Manage Users</span>
        </div>
    </div>

    -->

    <div class="row">
        <div class="col-md-1">
            <div class="row pt-3 pl-3">
                <app-goback></app-goback>
             </div>
        </div>
        <div class="col-md-10 pt-3">
            <span class="flex justify-center soulsWedHeadingFont">View Transactions</span>
        </div>
        <div class="col-md-1">
        </div>
    </div>

    <div class="row py-1 position-relative pl-8 " >        

        <div class=" d-none d-md-block">

            <!-- Fixed header row -->
            <div class="row pt-4 pb-1 fixed-header">
                <div class="col-md-1 soulsWedSubheadingFont2Orange"> Customer Name</div>
                <div class="col-md-1 soulsWedSubheadingFont2Orange"> Customer Email</div>
                <div class="col-md-2 soulsWedSubheadingFont2Orange"> Vendor Name</div>
                <div class="col-md-1 soulsWedSubheadingFont2Orange"> Currency</div>
                <div class="col-md-1 soulsWedSubheadingFont2Orange"> Amount</div>
                <div class="col-md-2 soulsWedSubheadingFont2Orange"> Booking Start</div>
                <div class="col-md-2 soulsWedSubheadingFont2Orange"> Booking End</div>
                <div class="col-md-2 soulsWedSubheadingFont2Orange"> Transaction Date</div>
            </div>
            
            <div class="scrollable-content">

                <div class="row pb-1 flex items-center" *ngFor="let rec of transactionsList">
                    <div class="col-md-1 text-wrap" style="word-wrap: break-word; white-space: normal;"> {{rec.username}}</div>
                    <div class="col-md-1 text-wrap" style="word-wrap: break-word; white-space: normal;"> {{rec.useremail}}</div>
                    <div class="col-md-2 text-wrap" style="word-wrap: break-word; white-space: normal;"> {{rec.vendorname}}</div>
                    <div class="col-md-1 text-wrap" style="word-wrap: break-word; white-space: normal;"> {{rec.currency}}</div>
                    <div class="col-md-1 text-wrap" style="word-wrap: break-word; white-space: normal;"> {{rec.paidamount| number:'1.2-2'}}</div>
                    <div class="col-md-2 text-wrap" style="word-wrap: break-word; white-space: normal;">{{rec.bookingstart}}</div>
                    <div class="col-md-2 text-wrap" style="word-wrap: break-word; white-space: normal;">{{rec.bookingend}}</div>
                    <div class="col-md-2 text-wrap" style="word-wrap: break-word; white-space: normal;">{{rec.transactiondate}}</div>
    
                    <br><br>
    
                </div>

            </div>
        </div>

        <div class="d-block d-md-none">

                             
                <div class="scrollable-content">
    
                    <div class="row " *ngFor="let rec of transactionsList">

                        <div class="row">
                            <div class="col-4">Customer :&nbsp;</div>
                            <div class="col-8 text-wrap" style="word-wrap: break-word; white-space: normal;"> {{rec.username}}</div>
                        </div>

                        <div class="row">
                            <div class="col-4">Email :&nbsp;</div>
                            <div class="col-8 text-wrap" style="word-wrap: break-word; white-space: normal;"> {{rec.useremail}}</div>
                        </div>

                        <div class="row">
                            <div class="col-4">Vendor :&nbsp;</div>
                            <div class="col-8 text-wrap" style="word-wrap: break-word; white-space: normal;"> {{rec.vendorname}}</div>
                        </div>

                        <div class="row">
                            <div class="col-4">Currency :&nbsp;</div>
                            <div class="col-8 text-wrap" style="word-wrap: break-word; white-space: normal;"> {{rec.currency}}</div>
                        </div>

                        <div class="row">
                            <div class="col-4">Amount :&nbsp;</div>
                            <div class="col-8 text-wrap" style="word-wrap: break-word; white-space: normal;"> {{rec.paidamount| number:'1.2-2'}}</div>
                        </div>

                        <div class="row">
                            <div class="col-4">Start :&nbsp;</div>
                            <div class="col-8 text-wrap" style="word-wrap: break-word; white-space: normal;"> {{rec.bookingstart}}</div>
                        </div>

                        <div class="row">
                            <div class="col-4">End :&nbsp;</div>
                            <div class="col-8 text-wrap" style="word-wrap: break-word; white-space: normal;"> {{rec.bookingend}}</div>
                        </div>

                        <div class="row">
                            <div class="col-4">BookDt :&nbsp;</div>
                            <div class="col-8 text-wrap" style="word-wrap: break-word; white-space: normal;"> {{rec.transactiondate}}</div>
                        </div>


                        <div class ="row">
                            <div class="col-12">&nbsp;</div>
                            <hr>
                            <div class="col-12">&nbsp;</div>
                        </div>
        
                    </div>
    
                </div>

            

        </div>
        
   </div>
           

 </div>
