
import { Component,OnInit } from '@angular/core';
import { CategoryData, CountryData, SearchData, VendorSummaryData,VendorID,ExchangeRateData, CurrencyData,CityData } from '../../../models/datamodels';
import { VendorDataServiceService } from '../../../services/vendor-data-service.service';
import { RouterModule } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { EnvironmentDataServiceService } from '../../../services/environment-data-service.service';
import { MasterdataserviceService } from '../../../services/masterdataservice.service';
import { UserServiceService } from '../../../services/user-service.service';
import { MatDialog } from '@angular/material/dialog';
import { SignInComponent } from '../../usermgmt/sign-in/sign-in.component';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { GobackComponent } from '../../goback/goback.component';
import { FilterDataServiceService } from '../../../services/filter-data-service.service';


@Component({
  selector: 'app-vendorsummarydisplay',
  standalone: true,
  imports: [CommonModule,RouterModule,MatCardModule,CommonModule,FormsModule,GobackComponent],
  templateUrl: './vendorsummarydisplay.component.html',
  styleUrl: './vendorsummarydisplay.component.css'
})


export class VendorsummarydisplayComponent implements OnInit
{
  
  // ------ List of entities -----------------
  
  serverpath= environment.middletierhost+'/uploads';  //---serverpath for images----   
  
  categoriesList:CategoryData[]=[];
  categoriesListFiltered:CategoryData[]=[];
  countriesList:CountryData[] =[]; 
  countriesListFiltered: CountryData[] =[]; 
  cityList:CityData[]=[]; 
  cityListFiltered:CityData[]=[]; 
  vendorsListOriginal:VendorSummaryData[]=[];
  vendorsListFiltered:VendorSummaryData[]=[];

  vendorsList:VendorSummaryData[]=[];

  currentIndex: number = 0;    
  favourites:VendorID[]=[];  
  iduser=0;       
  
  selectedCategory:CategoryData|null=null;
  selectedVendor:VendorSummaryData|null=null;
  selectedCountry:CountryData|null=null;
  selectedCity:CityData|null=null;


  showSearch:boolean=false;
  searchButtonText:string='Search Vendors';

  fxList:ExchangeRateData[]=[];
  selectedCurrency:CurrencyData|null=null;

  titleText:string='Vendors based on your search';
  subTitleText:string='Enjoy your special day while the experts take care of the details';
 

  //---------Main Filter values -----------------------


  //-------- Variables to check which sidebar filters are applicable -----
  isPriceRangeApplicable:boolean=true;
  isHotelRatingApplicable:boolean=true;
  isCapacityApplicable:boolean=true;

  //--------Sidebar filter values --------------------
  
  maxPrice:number=0;
  
  //------------ Variables for hotel ratings -------------------
  threeStar: boolean=true;
  fourStar:  boolean=true;
  fiveStar:  boolean=true;

  //------------ Variables for capacity -------------------
  lessthan500: boolean=true;
  between500and1000:  boolean=true;
  between1000nd2000:  boolean=true;
  greaterthan2000:  boolean=true;

  
  constructor(private router:Router,public dialog: MatDialog,private userDataService:UserServiceService,private vendorDataService:VendorDataServiceService,private filterDataService:FilterDataServiceService,private masterDataService:MasterdataserviceService,private environmentDataService : EnvironmentDataServiceService)
  {}

  ngOnInit(): void 
  {
    
    this.environmentDataService.currentCurrency.subscribe(data=>
    {
            this.selectedCurrency=data;
  
            this.masterDataService.getExchangeRates().subscribe(data=>
            {
              this.fxList=data;
              console.log('Exchange rates fetched :',this.fxList);
            })
    });


     //--------- get user favourites -------------

     this.environmentDataService.currentUser.subscribe(user=>
      {
          if (user)
          {
              this.iduser=user.iduser;

              this.userDataService.getUserFavourites(this.iduser).subscribe
              (data=>
               {
                  this.favourites=data;
              })
          }
       })

  
    this.masterDataService.getVendorCountries().subscribe
    (data=>
    {
         this.countriesList=data;
         this.countriesListFiltered=this.countriesList;

         this.masterDataService.getCategories().subscribe
        (data=>
        {
            this.categoriesList=data;
            this.categoriesListFiltered=this.categoriesList;


            this.masterDataService.getVendorCities().subscribe
            (data=>
            {
              this.cityList=data;
              this.cityListFiltered=this.cityList;

              //-------------- Get filtering information -----------------

              this.filterDataService.currentData.subscribe(
              data=>
              {
                  this.selectedCategory=data?.category!;
                  this.selectedCountry=data?.country!;
                  this.selectedCity=data?.city!;
                  this.selectedVendor=data?.vendor!;

                  console.log('Filter data retrieved');
                  console.log('Category:',this.selectedCategory);
                  console.log('Country:',this.selectedCountry);
                  console.log('City:',this.selectedCity);
                  console.log('Vendor:',this.selectedVendor);

                  this.vendorDataService.getAllVendors().subscribe
                  (data=>
                  {
                        this.vendorsListOriginal=data;
      
                        console.log('Vendors list revieved with number ' , data.length);
                        console.log('Vendors list copied with number ' , this.vendorsListOriginal.length);
        
                        this.vendorsListFiltered = this.vendorsListOriginal;

                        if(this.selectedCategory)
                        {
                              console.log('Selected category found at init:',this.selectedCategory.categoryName);
      
                              this.vendorsListFiltered=this.vendorsListFiltered.filter(v=>v.idcategory==this.selectedCategory?.idcategory);
      
                               this.countriesListFiltered=this.countriesList.filter(country => 
                               this.vendorsListFiltered.some(vendor => vendor.idcountry === country.idcountry));
          
                              this.cityListFiltered=this.cityList.filter(city => 
                              this.vendorsListFiltered.some(vendor => vendor.city === city.cityname)); 
      
                        }

                        if(this.selectedCountry)
                        {

                              console.log('Selected category found at init:',this.selectedCountry.countryName);

                              this.vendorsListFiltered=this.vendorsListFiltered.filter(v=>v.idcountry==this.selectedCountry?.idcountry);
            
                              this.cityListFiltered=this.cityList.filter(city => 
                              this.vendorsListFiltered.some(vendor => vendor.city === city.cityname));      
                        }

                        if(this.selectedCity)
                        {
                            console.log('Selected city found at init:',this.selectedCity.cityname);

                            this.vendorsListFiltered=this.vendorsListFiltered.filter(v=>v.city==this.selectedCity?.cityname);
                        }
      
                      
                  });
         

                this.filterDataService.currentSidebarData.subscribe(sidebardata=>
                {
                      console.log('Sidebar data change recieved !!!');
        
                      if(sidebardata)
                      {
                          this.maxPrice=sidebardata.maxPrice||0;
                  
                          this.threeStar=sidebardata.threeStar;
                          this.fourStar=sidebardata.fourStar;
                          this.fiveStar=sidebardata.fiveStar;
        
                          this.lessthan500=sidebardata.lessthan500;
                          this.between500and1000=sidebardata.between500and1000;
                          this.between1000nd2000=sidebardata.between1000nd2000;
                          this.greaterthan2000=sidebardata.greaterthan2000;
        
                          this.isPriceRangeApplicable=sidebardata.showPriceRange;
                          this.isHotelRatingApplicable=sidebardata.showRatingCheckboxes;
                          this.isCapacityApplicable=sidebardata.showCapacityRanges;
        
                      }

                      this.vendorDataService.getAllVendors().subscribe
                      (data=>
                      {
                          this.vendorsListOriginal=data;

                          console.log('Vendors list revieved with number ' , data.length);
                          console.log('Vendors list copied with number ' , this.vendorsListOriginal.length);
  
                          this.updateDisplayedVendors();
         
                      });

                  })
    
                })

            })

        });


    })

      
  }

  updateDisplayedVendors(): void 
  {
    //this.vendorsList = this.vendorsListOriginal.slice(this.currentIndex, this.currentIndex + 3);
    this.vendorsList = this.vendorsListOriginal;

    console.log('Vendors list at start of udv',this.vendorsList);

    if(this.selectedCategory)
    {
        this.vendorsList=this.vendorsList.filter(v=>v.idcategory==this.selectedCategory?.idcategory);
       
    }

    if(this.selectedCountry)
    {
        this.vendorsList=this.vendorsList.filter(v=>v.idcountry==this.selectedCountry?.idcountry);
       
    }
  
      if(this.selectedCity)
      {
        this.vendorsList=this.vendorsList.filter(v=>v.city==this.selectedCity?.cityname);
      }
  
      if(this.selectedVendor)
      {
        this.vendorsList=this.vendorsList.filter(v=>v.idvendor==this.selectedVendor?.idvendor);
      }

      console.log('Vendors list middle of udv',this.vendorsList);
      
      //----------- Filter for max price ------------------
      console.log ('Max price :',this.maxPrice)

      if(this.isPriceRangeApplicable)
      {
            console.log ('Inside max price filter');
  
            this.vendorsList=this.vendorsList.filter
            (
              //vendor => vendor.startingprice <= this.maxPrice
              vendor => vendor.startingprice <= this.convertCurrency('INR',vendor.currency,this.maxPrice)
            )
          
      }

      console.log('filtered list after max price ',this.vendorsList.length);
    
      //---------- Filter for hotel ratings ------------------

      if(this.isHotelRatingApplicable)
      {
          if(!this.threeStar)
          {
            this.vendorsList=this.vendorsList.filter
            (
              vendor => vendor.vendorrating != '3 Star'
            )
            
          }
        

          if(!this.fourStar)
          {
              this.vendorsList=this.vendorsList.filter
              (
                vendor => vendor.vendorrating != '4 Star'
              )
              
          }

          if(!this.fiveStar)
          {
                this.vendorsList=this.vendorsList.filter
                (
                  vendor => vendor.vendorrating != '5 star'
                )
                
          }
      }

      console.log('filtered list after star ratings count ',this.vendorsList.length);

        //---------- Filter for capacity ------------------
        if(this.isCapacityApplicable)
        {

            if(!this.lessthan500)
            {
              this.vendorsList=this.vendorsList.filter
              (
                vendor => vendor.capacity >=500
              )

            }

            if(!this.between500and1000)
            {
              this.vendorsList=this.vendorsList.filter
                (
                  vendor => vendor.capacity <=500 || vendor.capacity >=1000
                )
  
            }

            if(!this.between1000nd2000)
            {
              this.vendorsList=this.vendorsList.filter
                  (
                    vendor => vendor.capacity <=1000 || vendor.capacity >=2000
                  )
    
            }

            if(!this.greaterthan2000)
              {
                this.vendorsList=this.vendorsList.filter
                (
                  vendor => vendor.capacity <=2000
                )
  
              }
            
        }

        
       console.log('filtered list after capacity coount ',this.vendorsList.length);



  }

   


  //============== Function to updateselected country ============================
  onVendorSelect(selected: VendorSummaryData): void 
  {
  
    this.vendorDataService.changeData(selected);
    this.router.navigate(['/vendordetails/'+selected.idvendor.toString()]);
    //this.router.navigate(['/vendordetails']);
       
  }

  getStars(rating:string):string
  {
    if (rating=='5 star')
      return '*****'
      else if (rating=='4 star')
      return '****'
      else if (rating=='3 star')
        return '***'
      else if (rating=='2 star')
        return '**'
      else if (rating=='1 star')
        return '*'
      else
        return '';
  }

  toggleFavourites(idvendor:number)
  {


    //-------- Ask to sign in if not signed in --------------------

    this.environmentDataService.currentUser.subscribe(user=>
      {
        if (user)
        {
            this.iduser=user.iduser;
        }
        else
        {
          //-------- If not logged in, ask for user to log-in ------------------
          const dialogRef = this.dialog.open(SignInComponent, 
          {
            width: '300px'
          });
      
          dialogRef.afterClosed().subscribe(result => 
          {
            this.router.navigate(['/home']);
            console.log('The dialog was closed');
          });

        }
      }
    )

    if(this.iduser>0)
    {
        //--------------- Add or remove user favourites as applicable -------------------
    if(!this.favourites.find(c=>c.idvendor==idvendor))
      {
        //this.favourites.push(idvendor);
        this.userDataService.addUserFavourites({iduser:this.iduser,idvendor:idvendor}).subscribe
        (data=>
          {
            this.userDataService.getUserFavourites(this.iduser).subscribe
            (
              info=>
                {
                  this.favourites=info;
                  console.log('favourites list :',this.favourites);
                }
            )
          }
        )
      }
      else
      {
        //this.favourites=this.favourites.filter(n=>n!=idvendor)
        //this.favourites.push(idvendor);
        this.userDataService.deleteUserFavourites({iduser:this.iduser,idvendor:idvendor}).subscribe
        (data=>
          {
            this.userDataService.getUserFavourites(this.iduser).subscribe
            (
              info=>
                {
                  this.favourites=info;
                  console.log('favourites list :',this.favourites);
                }
            )
          }
        )
      }
    }

    

    
  }

  isFavourite(idvendor:number):boolean
  {
    if(this.favourites.find(c=>c.idvendor==idvendor))
      return true;
    else
    return false;
  }

   //============== Function to updateselected country ============================
   onCategorySelected(selected: CategoryData): void 
   {
      this.selectedCategory = selected;
      this.selectedCountry = null;
      this.selectedVendor=null;
      this.selectedCity=null;

      this.environmentDataService.changeCategoryData(selected);

      this.vendorsListFiltered=this.vendorsListOriginal;
      this.countriesListFiltered=this.countriesList;
      this.cityListFiltered=this.cityList;

    if(this.selectedCategory)
    {
        this.vendorsListFiltered=this.vendorsListFiltered.filter(v=>v.idcategory==this.selectedCategory?.idcategory);

        this.countriesListFiltered=this.countriesList.filter(country => 
          this.vendorsListFiltered.some(vendor => vendor.idcountry === country.idcountry));

        this.cityListFiltered=this.cityList.filter(city => 
          this.vendorsListFiltered.some(vendor => vendor.city === city.cityname));       

    }


    this.updateDisplayedVendors();
   
   }

    //============== Function to updateselected country ============================
    onCountrySelected(selected: CountryData): void 
    {
      this.selectedCountry = selected;
      this.selectedVendor=null;
      this.selectedCity=null;

      if(this.selectedCountry)
        {
          this.vendorsListFiltered=this.vendorsListOriginal.filter(v=>v.idcountry==this.selectedCountry?.idcountry);

          this.cityListFiltered=this.cityList.filter(city => 
          this.vendorsListFiltered.some(vendor => vendor.city === city.cityname));  
          
        }
    
        if(this.selectedCategory)
        {
            this.vendorsListFiltered=this.vendorsListFiltered.filter(v=>v.idcategory==this.selectedCategory?.idcategory);
    
            this.cityListFiltered=this.cityList.filter(city => 
              this.vendorsListFiltered.some(vendor => vendor.city === city.cityname));      
            
           
        }
 
      this.updateDisplayedVendors();
       
    }
 
    //============== Function to updateselected country ============================
    onCitySelected(selected: CityData): void 
    {
      this.selectedCity = selected;

      if(this.selectedCity)
        {
          this.vendorsListFiltered=this.vendorsListOriginal.filter(v=>v.city==this.selectedCity?.cityname);
        }
    
        if(this.selectedCategory)
        {
            this.vendorsListFiltered=this.vendorsListFiltered.filter(v=>v.idcategory==this.selectedCategory?.idcategory);
             
        }
    
        if(this.selectedCountry)
        {
            
            this.vendorsListFiltered=this.vendorsListFiltered.filter(v=>v.idcountry==this.selectedCountry?.idcountry);
        }

      this.updateDisplayedVendors();
       
    }
  
   //============== Function to updateselected country ============================
   onVendorSelected(selected: VendorSummaryData): void 
   {
     this.selectedVendor = selected;
     this.updateDisplayedVendors();
      
   }

  

   //============Clear filters ====================================

   clearFilters()
   {
    this.selectedCategory=null;
    this.selectedCountry = null;
    this.selectedVendor=null;
    this.selectedCity=null;

    this.categoriesListFiltered=this.categoriesList;
    this.countriesListFiltered=this.countriesList;
    this.cityListFiltered=this.cityList;
    this.vendorsListFiltered=this.vendorsListOriginal;

    this.updateDisplayedVendors();
   }

   enableSearch()
   {
     console.log('Enable Search Clicked');
     this.showSearch=!this.showSearch;
 
     if(this.showSearch)
       this.searchButtonText="Hide search panel"
     else
       this.searchButtonText="Search Planners"
     
   }

   convertCurrency(fromCurrency:string,toCurrency:string,amount:number):number
   {
     // console.log('Currency conversion from ' ,fromCurrency ,' to ',toCurrency);

        const existingRatetoUSD = this.fxList.find(rate =>
          rate.fromCurrency == fromCurrency && rate.toCurrency == 'USD');


       //   console.log('existingRatetoUSD ' ,existingRatetoUSD , '. Rate :',existingRatetoUSD!.rate);
          

        if (existingRatetoUSD) 
        {
          const existingRatefromUSD = this.fxList.find(rate =>
            rate.fromCurrency == 'USD' && rate.toCurrency == toCurrency);

       //     console.log('existingRatefromUSD ' ,existingRatefromUSD , '. Rate :',existingRatefromUSD!.rate);

            if(existingRatefromUSD)
            {
              return amount*existingRatetoUSD.rate*existingRatefromUSD?.rate!
            }
            else
            return amount;
        
        } 
        else 
        {
            return amount;
            
        }
   }

   // Compare categories
  compareCategories(c1: CategoryData, c2: CategoryData): boolean {
    return c1 && c2 ? c1.idcategory === c2.idcategory : c1 === c2;
  }
  
  // Compare countries
  compareCountries(c1: CountryData, c2: CountryData): boolean {
    return c1 && c2 ? c1.idcountry === c2.idcountry : c1 === c2;
  }

  // Compare cities
  compareCities(c1: CityData, c2: CityData): boolean {
    return c1 && c2 ? c1.idcity === c2.idcity : c1 === c2;
  }

  // Compare vendors
  compareVendors(v1: VendorSummaryData, v2: VendorSummaryData): boolean 
  {
    return v1 && v2 ? v1.idvendor === v2.idvendor : v1 === v2;
  }
   

  
}
