<div class="container">
   <!-- <div class="soulsWedHeadingFontalt" style="margin-bottom: 35px;" >Shop by Categories</div>-->
    <div class="card-container">
      <div class="card" (click)="goToVenues()">
        <div class="image-container">
          <img  src="../../../assets/images/PageImages/venues.jpg" alt="Venues">
        </div>
        <h3>Venues</h3>
      </div>
      <div class="card" (click)="goToPlanners()">
        <div class="image-container">
          <img src="../../../assets/images/newdesign/planners.png" alt="Planners">
        </div>
        <h3>Planners</h3>
      </div>
      <!-- <div class="card" (click)="goToCharters()">
        <div class="image-container">
          <img src="../../../assets/images/newdesign/charters.png" alt="Charters">
        </div>
        <h3>Charters</h3>
      </div> -->
      <div class="card" (click)="goToPhotographers()">
        <div class="image-container">
          <img src="../../../assets/images/newdesign/photographer.png" alt="Photographers">
        </div>
        <h3>Photographers</h3>
      </div>
      <div class="card" (click)="goToDecorators()">
        <div class="image-container">
          <img src="../../../assets/images/newdesign/decoraters.png" alt="Decorators">
        </div>
        <h3>Decorators</h3>
      </div>
      <div class="card" (click)="goToMakeupArtists()">
        <div class="image-container">
          <img src="../../../assets/images/newdesign/Makeupartists.jpg" alt="Makeup Artists">
        </div>
        <h3>Makeup Artist</h3>
      </div>
    </div>
  </div>
  