<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
<!-- Include Bootstrap CSS -->
<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">

<!-- Include Font Awesome CSS -->
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">

<div>

    <!-- Dialog Header -->
<div class="modal-header border-3" style="background-color: rgb(252,203,17);border-radius: 10px 10px 0 0;"> <!--rgb(238,116,41)-->
  <h3 class="modal-title">Please sign in to proceed</h3>
  <button mat-icon-button (click)="closeDialog()" style="float: right;background-color: white;border-radius: 50%;">
    <mat-icon>close</mat-icon>
  </button>
</div>

<!-- Dialog Content -->
<div class="modal-body" style="border-radius: 0 0 10px 10px;">
  <form (ngSubmit)="onSubmit()">
    <div class="form-group">
      <div>
        <label for="username">Registered Email</label>
        <input type="text" class="form-control" id="username" [(ngModel)]="username" name="username" required>
      </div>     
      </div>

   

    <div class="form-group position-relative">
      <label for="password">Password</label>
      <input [type]="showPassword ? 'text' : 'password'" class="form-control" id="password" [(ngModel)]="password" name="password" required>
      <button type="button" (click)="togglePasswordVisibility()" class="btn btn-outline-secondary toggle-password">
        <i [class]="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
      </button>
    </div>
    
       
    
    <div class="flex justify-center">
      <button type="submit" class="button-style-2">Sign in</button>
    </div>
    
  </form>

  <p class="p-1" style="color: red;">{{errorMessage}}</p>
  
  <div class="mt-3 flex justify-center">
    <a class="clickable" (click)="registerUser()">New User? Register here  </a>
   <!-- &nbsp; |&nbsp; <a routerLink="/forgot-password">Forgot Password</a> -->
  </div>

  <div class="mt-3 flex justify-center">
    <a class="clickable" (click)="forgotPassword()">Forgot password </a>
   <!-- &nbsp; |&nbsp; <a routerLink="/forgot-password">Forgot Password</a> -->
  </div>




  <div class="row p-2">
    <div class="col-md-5"></div>
    <div class ='col-md-2'>or</div>
    <div class="col-md-5"></div>
  </div>

 <div class="mt-3">
  <button class="btn google-btn btn-block mb-1" (click)="signInWithGoogle()">
    <i class="fab fa-google mr-2"></i>Sign In with Google
  </button>

  <button class="btn facebook-btn btn-block mb-1 mt-3" (click)="signInWithFacebook()">
    &nbsp;&nbsp;<i class="fab fa-facebook-f mr-2"></i>Sign In with Facebook
  </button>
</div>







<!-- Include Bootstrap JS and dependencies -->
<script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>
<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
