<div class="row">
   <div class="col-md-12 header">
      <app-header2></app-header2>
   </div>
</div>



<div class="d-block d-md-none">
   <div class="row pt-12 pb-1">
      <img mat-card-image [src]="headerImagePath">
   </div>
</div>

<div class="d-none d-md-block">
   <div class="row pb-3">
      <img mat-card-image [src]="headerImagePath">
   </div>
</div>

<!--

<div class="d-block d-md-none">
   <div class="row pt-16 pb-1">
      <video mat-card-image autoplay muted loop class="custom-video-mobile">
         <source src="../../assets/videos/PageVideos/venues.mp4" type="video/mp4">
         Your browser does not support the video tag.
       </video>
   </div>
</div>

<div class="d-none d-md-block">
   <div class="row pb-3">
      <video mat-card-image autoplay muted loop class="custom-video">
         <source src="../../assets/videos/PageVideos/venues.mp4" type="video/mp4">
         Your browser does not support the video tag.
       </video>
   </div>
</div>

-->

<div class="container-fluid px-3 pt-1">
   <div class="row pt-2 pl-3">
      <app-goback></app-goback>
   </div>

   <!-- Desktop Version -->
   <div class="row pt-3 pb-1 pl-5">
      <div class="d-none d-md-block">

         <div class="col-md-12">

            <div class="row">
   
               <!-- Vendor Details Section - Right Side -->
               <div class="col-md-5">
                  
                  <div class="col-md-10 soulsWedSubheadingFont3">{{ currentVendor?.vendorname }} &nbsp;{{ getStars(currentVendor?.vendorrating ?? '') }}</div>
                  <div class="col-md-10 soulsWedNormalFont pt-2">{{ currentVendor?.cityname }}&nbsp;,{{ currentVendor?.countryname }}</div>
                  
                  <!--
                  <div class="row pt-4">
                     <google-map height="200px" width="300px" [center]="center" [zoom]="zoom">
                        <map-marker [position]="center"></map-marker>
                     </google-map>
                  </div>


                  -->
               </div>

               <!-- Image Gallery Section - Left Side -->
               <!-- <div class="col-md-4"> -->
                  <!--
                  <div class="image-gallery">
                     <div class="main-image">
                        <img [src]="selectedImage" alt="Product Image">
                     </div>
                     Thumbnails section when there's more than one image 
                     <div class="thumbnails-container" *ngIf="vendorImages.length > 1">
                        <div class="thumbnails">
                           <img *ngFor="let image of vendorImages" [src]="imagesPath + image.imagename"
                              (click)="changeImage(image.imagename)" alt="Thumbnail">
                        </div>
                     </div>
                      Single thumbnail section when there's only one image 
                     <div class="single-thumbnail-container" *ngIf="vendorImages.length === 1">
                        <div class="thumbnails">
                           <img *ngFor="let image of vendorImages" [src]="imagesPath + image.imagename"
                              (click)="changeImage(image.imagename)" alt="Thumbnail">
                        </div>
                     </div>
                  </div>

               -->

               <!-- </div> -->

               
            </div>
         </div>

         <div class="col-md-12">
            <div class="row">
              <div class="col-md-4 p-2" *ngFor="let image of vendorImages">
               <img class="img-fluid  w-100 image-fixed-height" [src]="imagesPath + image.imagename" loading="lazy">

               <!--
                <div class="image-container">
                  <img class="img-fluid  w-100 image-fixed-height" [src]="imagesPath + image.imagename" loading="lazy">
                </div>

                -->
                
              </div>
            </div>
         </div>

          <!-- Video section -->
   <div class="row pt-2" *ngIf="vendorVideos.length > 0">
      <div class="col-md-12">
      <div class="video-section">
        <!-- <div class="row soulsWedSubTitleFont pt-2">Check out our videos</div>-->
         <div class="video-container">
            <div class="row">
               <!-- Loop through the first 3 videos -->
               <ng-container *ngFor="let video of vendorVideos; let i = index">
                  <div class="col-md-4 col-sm-6 video-spacing">
                     <video class="img-fluid video-fit" height="300px" width="100%" controls [src]="videosPath + video.videoname">
                        Your browser does not support the video tag.
                     </video>
                  </div>
               </ng-container>
            </div>
         </div>
      </div>
   </div>
</div>

   </div>


       




      <!-- Mobile Version (visible on small screens) -->
      <div class="d-block d-md-none">
         <!-- Vendor details -->
         <div class="vendor-details mt-3 text-center">
            <div class="soulsWedSubheadingFont3">{{ currentVendor?.vendorname }}</div>
            <div class="soulsWedSubheadingFont3">{{ getStars(currentVendor?.vendorrating ?? '') }}</div>
            <div class="soulsWedNormalFont">{{ currentVendor?.cityname }}</div>
            <div class="soulsWedNormalFont">{{ currentVendor?.countryname }}</div>
         </div>
         <!-- Stack the image and details vertically -->
         <!-- <div class="image-gallery text-center">
            <div class="main-image">
               <img [src]="selectedImage" alt="Product Image" class="img-fluid image-fit main-image-style">
            </div>
           Thumbnails for Mobile -->
            <!-- <div class="thumbnails-container mt-2">
               <div class="thumbnails d-flex justify-content-center">
                  <img *ngFor="let image of vendorImages" [src]="imagesPath + image.imagename"
                     (click)="changeImage(image.imagename)" alt="Thumbnail" class="thumbnail-img thumbnail-style">
               </div>
            </div>
         </div> 
          -->

          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4 p-2" *ngFor="let image of vendorImages">
                <img class="img-fluid w-100 image-fixed-height" [src]="imagesPath + image.imagename" loading="lazy">
              </div>
            </div>
          </div>
         <!-- Share Icons
               <div class="share-section text-center mt-3">
                  <p class="share-title">Share</p>
                  <div class="share-icons">
                     <i class="icon fab fa-facebook-f"></i>
                     <i class="icon fab fa-twitter"></i>
                     <i class="icon fab fa-instagram"></i>
                  </div>
               </div> -->
         <!-- Video section -->
          <div class="row  video-section-mobile-center" *ngIf="vendorVideos.length > 0">
               <div class="col-md-12">
                  <div class="video-section">
                    <!-- <div class="row soulsWedSubTitleFont">Check out our videos</div>-->
                     <div class="video-container">
                        <div class="row">
                           <div class="col-md-4 col-sm-6 p-2" *ngFor="let video of vendorVideos">
                              <video class="img-fluid video-fit" height="300px" width="300px" controls
                                 [src]="videosPath + video.videoname">
                                 Your browser does not support the video tag.
                              </video>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div> 
         <!-- map-section for mobile -->
         <!-- <div class="row pt-4">
            <google-map height="200px" width="350px" [center]="center" [zoom]="zoom">
               <map-marker [position]="center"></map-marker>
            </google-map>
         </div> -->
      </div>
     

      <!-- Tabs Section -->
      <div class="row pt-4">
         <div class="col-md-12">
            <!-- Tab Headers -->
            <ul class="nav nav-tabs" id="myTab" role="tablist">

               <li class="nav-item">
                  <button class="nav-link" [ngClass]="{ 'active': activeTab === 'description' }"
                     (click)="setActiveTab('description')" id="description-tab" role="tab" aria-controls="description"
                     [attr.aria-selected]="activeTab === 'description'">Description</button>
               </li>

               <li class="nav-item">
                  <button class="nav-link" [ngClass]="{ 'active': activeTab === 'additional-info' }"
                     (click)="setActiveTab('additional-info')" id="additional-info-tab" role="tab"
                     aria-controls="additional-info" [attr.aria-selected]="activeTab === 'additional-info'">Basic
                     Information</button>
               </li>

               <li class="nav-item">
                  <button class="nav-link" [ngClass]="{ 'active': activeTab === 'reviews' }"
                     (click)="setActiveTab('reviews')" id="reviews-tab" role="tab" aria-controls="reviews"
                     [attr.aria-selected]="activeTab === 'reviews'">Reviews</button>
               </li>
               <!-- Conditionally render the "Amenities" tab button if amenities are available -->
               <li class="nav-item" *ngIf="amenities && amenities.length > 0">
                  <button class="nav-link" [ngClass]="{ 'active': activeTab === 'amenities' }"
                     (click)="setActiveTab('amenities')" id="amenities-tab" role="tab" aria-controls="amenities"
                     [attr.aria-selected]="activeTab === 'amenities'">Amenities</button>
               </li>
            </ul>

            <!-- Tab Content -->
            <div class="tab-content pt-3" id="myTabContent">
               <!-- Descriptions Tab -->
               <div *ngIf="activeTab === 'description'" class="tab-pane fade show active" id="description"
                  role="tabpanel" aria-labelledby="description-tab">
                 
                  <div class="row mb-3">

                     <div class="col-md-12 ml-4 mr-4">
                        <div class="description-container">
                            <!-- Description Section -->
                           <p *ngIf="currentVendor?.description" class="wrap-text">{{ currentVendor?.description }}</p>
                           <p *ngIf="!currentVendor?.description">No description available</p>   
                           
                        </div>
                       

                     </div>

                  </div>
               </div>
            </div>
         </div>
      </div>

   </div>

   <!-- Basic Information Tab -->
   <div *ngIf="activeTab === 'additional-info'" class="tab-pane fade show active" id="additional-info" role="tabpanel"
      aria-labelledby="additional-info-tab">

      <div class="col-md-12 ml-8 mr-8">

         <div *ngIf="currentVendor?.idcategory==3 && hasFaqInfo">
            <div class="row">
               <div class="col-md-4">

                  <div *ngIf="currentVendorDetails?.venueFaq?.venue_primaryDescription" class="mb-3">
                     <p><b>Venue Description</b>:&nbsp;{{currentVendorDetails?.venueFaq?.venue_primaryDescription}}</p>
                  </div>

                  <div *ngIf="currentVendorDetails?.venueFaq?.venue_venueTypeOptions" class="mb-3">
                     <p><b>Venue Type</b>:&nbsp;{{currentVendorDetails?.venueFaq?.venue_venueTypeOptions}}</p>
                  </div>

                  <div *ngIf="currentVendorDetails?.venueFaq?.venue_startyear" class="mb-3">
                     <p><b>Started in</b>:&nbsp;{{currentVendorDetails?.venueFaq?.venue_startyear}}</p>
                  </div>

                  <div *ngIf="currentVendorDetails?.venueFaq?.venue_alchoholpolicy" class="mb-3">
                     <p><b>Alcohol Policy</b>:&nbsp;{{currentVendorDetails?.venueFaq?.venue_alchoholpolicy}}</p>
                  </div>

                  <div *ngIf="currentVendorDetails?.venueFaq?.venue_cateringpolicy" class="mb-3">
                     <p><b>Catering Policy</b>:&nbsp;{{currentVendorDetails?.venueFaq?.venue_cateringpolicy}}</p>
                  </div>

                  <div *ngIf="currentVendorDetails?.venueFaq?.venue_onsiteaccomodation" class="mb-3">
                     <p><b>Onsite accommodation
                           available</b>:&nbsp;{{currentVendorDetails?.venueFaq?.venue_onsiteaccomodation}}
                     </p>
                  </div>

                  <div *ngIf="currentVendorDetails?.venueFaq?.venue_venueType" class="mb-3">
                     <p><b>Is there rental cost along with per plate
                           cost?</b>:&nbsp;{{currentVendorDetails?.venueFaq?.venue_venueType}}</p>
                  </div>

                  <div *ngIf="currentVendorDetails?.venueFaq?.venue_roomsavailable" class="mb-3">
                     <p><b>Total rooms available</b>:&nbsp;{{currentVendorDetails?.venueFaq?.venue_roomsavailable}}</p>
                  </div>

               </div>

               <div class="col-md-4">
                  <div *ngIf="currentVendorDetails?.venueFaq?.venue_roomsprice" class="mb-3">
                     <p><b>Room starting
                           Price</b>:&nbsp;{{currency}}{{currentVendorDetails?.venueFaq?.venue_roomsprice}}</p>
                  </div>

                  <div *ngIf="currentVendorDetails?.venueFaq?.venue_vegStartPrice" class="mb-3">
                     <p><b>Veg menu starting
                           price</b>:&nbsp;{{currency}}{{currentVendorDetails?.venueFaq?.venue_vegStartPrice}}</p>
                  </div>

                  <div *ngIf="currentVendorDetails?.venueFaq?.venue_nonvegStartPrice" class="mb-3">
                     <p><b>Non-veg menu starting
                           price</b>:&nbsp;{{currency}}{{currentVendorDetails?.venueFaq?.venue_nonvegStartPrice}}</p>
                  </div>


                  <div *ngIf="currentVendorDetails?.venueFaq?.venue_djpolicy" class="mb-3">
                     <p><b>DJ Policy</b>:&nbsp;{{currentVendorDetails?.venueFaq?.venue_djpolicy}}</p>
                  </div>

                  <div *ngIf="currentVendorDetails?.venueFaq?.venue_decorpolicy" class="mb-3">
                     <p><b>Decor Policy</b>:&nbsp;{{currentVendorDetails?.venueFaq?.venue_decorpolicy}}</p>
                  </div>

                  <div *ngIf="currentVendorDetails?.venueFaq?.venue_decorprice" class="mb-3">
                     <p><b>Approx price for venue
                           decoration</b>:&nbsp;{{currency}}{{currentVendorDetails?.venueFaq?.venue_decorprice}}</p>
                  </div>

                  <div *ngIf="currentVendorDetails?.venueFaq?.venue_smallgatherings" class="mb-3">
                     <p><b>Small size gatherings (&lt;50)
                           allowed?</b>:&nbsp;{{currentVendorDetails?.venueFaq?.venue_smallgatherings}}</p>
                  </div>

                  <div *ngIf="currentVendorDetails?.venueFaq?.venue_parking" class="mb-3">
                     <p><b>Parking Available?</b>:&nbsp;{{currentVendorDetails?.venueFaq?.venue_parking}}</p>
                  </div>
               </div>

               <div class="col-md-4">
                  <div *ngIf="currentVendorDetails?.venueFaq?.venue_USP" class="mb-3">
                     <p><b>USP</b>:&nbsp;{{currentVendorDetails?.venueFaq?.venue_USP}}</p>
                  </div>

                  <div *ngIf="currentVendorDetails?.venueFaq?.venue_weeksadvance" class="mb-3">
                     <p><b>How many weeks in advance should a booking be
                           made?</b>:&nbsp;{{currentVendorDetails?.venueFaq?.venue_weeksadvance}}</p>
                  </div>

                  <div *ngIf="currentVendorDetails?.venueFaq?.venue_allapplicable" class="mb-3">
                     <p><b>Venue features</b>:&nbsp;{{currentVendorDetails?.venueFaq?.venue_allapplicable}}</p>
                  </div>

                  <div *ngIf="currentVendorDetails?.venueFaq?.venue_cancellationpolicyuser" class="mb-3">
                     <p><b>Cancellation policy (if customer
                           cancels)</b>:&nbsp;{{currentVendorDetails?.venueFaq?.venue_cancellationpolicyuser}}</p>
                  </div>

                  <div *ngIf="currentVendorDetails?.venueFaq?.venue_cancellationpolicyvendor" class="mb-3">
                     <p><b>Cancellation policy (if vendor
                           cancels)</b>:&nbsp;{{currentVendorDetails?.venueFaq?.venue_cancellationpolicyvendor}}</p>
                  </div>

                  <div *ngIf="currentVendorDetails?.venueFaq?.venue_cancellationtnc" class="mb-3">
                     <p><b>Cancellation Terms and
                           Conditions</b>:&nbsp;{{currentVendorDetails?.venueFaq?.venue_cancellationtnc}}</p>
                  </div>

               </div>
            </div>

         </div>

         <!--====================== Hotel Faq Information -------------------------------->
         <div *ngIf="currentVendor?.idcategory==4 && hasFaqInfo">

            <div class="row">
               <div *ngIf="currentVendorDetails?.hotelsFaq?.couple_policy" class="col-md-4 ">
                  <div class="row">
                     <p><b>Couple policy :</b> &nbsp;{{currentVendorDetails?.hotelsFaq?.couple_policy}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.hotelsFaq?.smoking_policy" class="row">
                     <p><b>Smoking Policy :</b> &nbsp;{{currentVendorDetails?.hotelsFaq?.smoking_policy}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.hotelsFaq?.parking_available" class="row">
                     <p><b>Is parking available for guests? </b>
                        &nbsp;{{currentVendorDetails?.hotelsFaq?.parking_available}}</p>
                  </div>
               </div>

               <div class="col-md-1"></div>

               <div class="col-md-4 ">

                  <div *ngIf="currentVendorDetails?.hotelsFaq?.extra_beds" class="row flex justify-center">
                     <p><b>Are extra beds available on request? </b>
                        &nbsp;{{currentVendorDetails?.hotelsFaq?.extra_beds}}</p>
                  </div>

                  <div *ngIf="currentVendorDetails?.hotelsFaq?.days_cancellation" class="row flex justify-center">
                     <p><b>Number of days in advance for free cancellation? </b>
                        &nbsp;{{currentVendorDetails?.hotelsFaq?.days_cancellation}}</p>
                  </div>

                  <div *ngIf="currentVendorDetails?.hotelsFaq?.pets_policy" class="row flex justify-center">
                     <p><b>Are pets allowed?</b> &nbsp;{{currentVendorDetails?.hotelsFaq?.pets_policy}}</p>
                  </div>

               </div>

               <div class="col-md-3 ">

                  <div *ngIf="currentVendorDetails?.hotelsFaq?.creditcards_policy" class="row flex justify-center">
                     <p><b>Are credit cards accepted at the property? </b>
                        &nbsp;{{currentVendorDetails?.hotelsFaq?.creditcards_policy}}</p>
                  </div>

                  <div *ngIf="currentVendorDetails?.hotelsFaq?.otherpayment_methods" class="row flex justify-center">
                     <p><b>Other payment methods accepted :</b>
                        &nbsp;{{currentVendorDetails?.hotelsFaq?.otherpayment_methods}}</p>
                  </div>
               </div>
               <div class="col-md-1"></div>
            </div>

         </div>

         <!--====================== Planner Faq Information -------------------------------->
         <div *ngIf="currentVendor?.idcategory==5 && hasFaqInfo">

            <div class="row">
               <div class="col-md-4 ">
                  <div *ngIf="currentVendorDetails?.plannerFaq?.planner_USP" class="row">
                     <p><b>Planner USP :</b> &nbsp;{{currentVendorDetails?.plannerFaq?.planner_USP}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.plannerFaq?.planner_hotelslist" class="row">
                     <p><b>Hotels/venues where this vendor is enlisted :</b>
                        &nbsp;{{currentVendorDetails?.plannerFaq?.planner_hotelslist}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.plannerFaq?.planner_cities" class="row">
                     <p><b>Cities where this vendor has planned weddings/parties :</b>
                        &nbsp;{{currentVendorDetails?.plannerFaq?.planner_cities}}</p>
                  </div>
               </div>

               <div class="col-md-1"></div>

               <div class="col-md-3 ">

                  <div *ngIf="currentVendorDetails?.plannerFaq?.planner_cities" class="row flex justify-center">
                     <p><b>Prominent clients this vendor has worked with :</b>
                        &nbsp;{{currentVendorDetails?.plannerFaq?.planner_cities}}</p>
                  </div>

                  <div *ngIf="currentVendorDetails?.plannerFaq?.planner_startingPackage"
                     class="row flex justify-center">
                     <p><b>Approximate starting price for a 3-day wedding :</b>
                        &nbsp;{{currency}}{{currentVendorDetails?.plannerFaq?.planner_startingPackage}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.plannerFaq?.planner_decorPolicy" class="row flex justify-center">
                     <p><b>Decor Policy :</b> &nbsp;{{currentVendorDetails?.plannerFaq?.planner_decorPolicy}}</p>
                  </div>

               </div>

               <div class="col-md-4 ">

                  <div *ngIf="currentVendorDetails?.plannerFaq?.planner_commercialPlan" class="row">
                     <p><b>Commercial Plan :</b> &nbsp;{{currentVendorDetails?.plannerFaq?.planner_commercialPlan}}</p>
                     <div
                        *ngIf="currentVendorDetails?.plannerFaq?.planner_commercialPlan=='We charge a percentage of the wedding cost'">
                        <span>&nbsp;<b>Approx (negotiable) :</b>
                           {{currentVendorDetails?.plannerFaq?.planner_commercialPlanPerc}}</span>
                     </div>
                  </div>
               </div>
               <div class="col-md-1"></div>
            </div>

         </div>

         <!--====================== Photographer Faq Information -------------------------------->
         <div *ngIf="currentVendor?.idcategory==7 && hasFaqInfo">

            <div class="row">
               <div class="col-md-4 ">
                  <div *ngIf="currentVendorDetails?.photographerFaq?.ph_mostBookedPackageValue" class="row">
                     <p><b>Most booked package rate :</b>
                        &nbsp;{{currency}}{{currentVendorDetails?.photographerFaq?.ph_mostBookedPackageValue}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.photographerFaq?.ph_mostBookedPackageDays" class="row">
                     <p><b>Number of days included in above package :</b>
                        &nbsp;{{currentVendorDetails?.photographerFaq?.ph_mostBookedPackageDays}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.photographerFaq?.ph_mostBookedPackageServices" class="row">
                     <p><b>Services included in above package :</b>
                        &nbsp;{{currentVendorDetails?.photographerFaq?.ph_mostBookedPackageServices}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.photographerFaq?.ph_cancellationPolicyUser" class="row">
                     <p><b>Cancellation policy(cancellation initiated by customer) :</b>
                        &nbsp;{{currentVendorDetails?.photographerFaq?.ph_cancellationPolicyUser}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.photographerFaq?.ph_cancellationPolicySelf" class="row">
                     <p><b>Cancellation policy(cancellation initiated by vendor) :</b>
                        &nbsp;{{currentVendorDetails?.photographerFaq?.ph_cancellationPolicySelf}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.photographerFaq?.ph_cancellationPolicyTermsnConditions" class="row">
                     <p><b>Cancellation terms and conditions :</b>
                        &nbsp;{{currentVendorDetails?.photographerFaq?.ph_cancellationPolicyTermsnConditions}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.photographerFaq?.ph_photographyDescription" class="row">
                     <p><b>Work description :</b>
                        &nbsp;{{currentVendorDetails?.photographerFaq?.ph_photographyDescription}}</p>
                  </div>
               </div>

               <div class="col-md-1"></div>

               <div class="col-md-3 ">

                  <div *ngIf="currentVendorDetails?.photographerFaq?.ph_citiesNumber" class="row">
                     <p><b>Number of cities where wedding was covered by vendor till date:</b>
                        &nbsp;{{currentVendorDetails?.photographerFaq?.ph_citiesNumber}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.photographerFaq?.ph_loveBecause" class="row">
                     <p><b>We love wedding photography because :</b>
                        &nbsp;{{currentVendorDetails?.photographerFaq?.ph_loveBecause}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.photographerFaq?.ph_smallEventprice" class="row">
                     <p><b>Price for covering a small event like engagement or roka (< 50 guests , 4 hrs max) :</b>
                              &nbsp;{{currentVendorDetails?.photographerFaq?.ph_smallEventprice}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.photographerFaq?.ph_advanceBookingWeeks" class="row">
                     <p><b>Weeks in advance booking needs to be made :</b>
                        &nbsp;{{currentVendorDetails?.photographerFaq?.ph_advanceBookingWeeks}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.photographerFaq?.ph_startYear" class="row">
                     <p><b>Year wedding photography started :</b>
                        &nbsp;{{currentVendorDetails?.photographerFaq?.ph_startYear}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.photographerFaq?.ph_weeksToDeliver" class="row">
                     <p><b>Time to deliver photos (in weeks) :</b>
                        &nbsp;{{currentVendorDetails?.photographerFaq?.ph_weeksToDeliver}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.photographerFaq?.ph_services" class="row">
                     <p><b>Main service type :</b> &nbsp;{{currentVendorDetails?.photographerFaq?.ph_services}}</p>
                  </div>

               </div>

               <div class="col-md-4 ">

                  <div *ngIf="currentVendorDetails?.photographerFaq?.ph_specialityType" class="row">
                     <p><b>Speciality:</b> &nbsp;{{currentVendorDetails?.photographerFaq?.ph_specialityType}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.photographerFaq?.ph_specialityNumber" class="row">
                     <p><b>Speciality Pax Number :</b>
                        &nbsp;{{currentVendorDetails?.photographerFaq?.ph_specialityNumber}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.photographerFaq?.ph_paymentTerms" class="row">
                     <p><b>Payment terms :</b> &nbsp;{{currentVendorDetails?.photographerFaq?.ph_paymentTerms}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.photographerFaq?.ph_travelLodgingCosts" class="row">
                     <p><b>Travel and lodging costs policy :</b>
                        &nbsp;{{currentVendorDetails?.photographerFaq?.ph_travelLodgingCosts}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.photographerFaq?.ph_candidTraditionalOneday" class="row">
                     <p><b>Price for candid photography and traditional photography one day package for wedding day
                           :</b> &nbsp;{{currency}}{{currentVendorDetails?.photographerFaq?.ph_candidTraditionalOneday}}
                     </p>
                  </div>
                  <div *ngIf="currentVendorDetails?.photographerFaq?.ph_candidTraditionalCinematic" class="row">
                     <p><b>Price for candid photography ,traditional photography and cinematic video one day package for
                           wedding day :</b>
                        &nbsp;{{currency}}{{currentVendorDetails?.photographerFaq?.ph_candidTraditionalCinematic}}</p>
                  </div>

               </div>
               <div class="col-md-1"></div>
            </div>

         </div>

         <!--====================== Decor Faq Information -------------------------------->
         <div *ngIf="currentVendor?.idcategory==8 && hasFaqInfo">

            <div class="row">
               <div class="col-md-4 ">
                  <div *ngIf="currentVendorDetails?.decorFaq?.dr_enlistedHotels" class="row">
                     <p><b>Hotels/ banquets where vendor is enlisted :</b>
                        &nbsp;{{currentVendorDetails?.decorFaq?.dr_enlistedHotels}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.decorFaq?.dr_serviceTypes" class="row">
                     <p><b>Main Service Type :</b> &nbsp;{{currentVendorDetails?.decorFaq?.dr_serviceTypes}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.decorFaq?.dr_indoorPriceRange" class="row">
                     <p><b>Price range for indoor banquet function (approx 250 pax) :</b>
                        &nbsp;{{currency}}{{currentVendorDetails?.decorFaq?.dr_indoorPriceRange}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.decorFaq?.dr_outdoorPriceRange" class="row">
                     <p><b>Price range for outdoor banquet function (approx 250 pax) :</b>
                        &nbsp;{{currency}}{{currentVendorDetails?.decorFaq?.dr_outdoorPriceRange}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.decorFaq?.dr_celebrityWeddings" class="row">
                     <p><b>Celebrity weddings done by the vendor :</b>
                        &nbsp;{{currentVendorDetails?.decorFaq?.dr_celebrityWeddings}}</p>
                  </div>

               </div>

               <div class="col-md-1"></div>

               <div class="col-md-3 ">

                  <div *ngIf="currentVendorDetails?.decorFaq?.dr_USP" class="row">
                     <p><b>USP:</b> &nbsp;{{currentVendorDetails?.decorFaq?.dr_USP}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.decorFaq?.dr_mostExperiencedIn" class="row">
                     <p><b>Most experienced in :</b> &nbsp;{{currentVendorDetails?.decorFaq?.dr_mostExperiencedIn}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.decorFaq?.dr_cancellationPolicyUser" class="row">
                     <p><b>Cancellation policy on user initiated cancellation :</b>
                        &nbsp;{{currentVendorDetails?.decorFaq?.dr_cancellationPolicyUser}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.decorFaq?.dr_cancellationPolicySelf" class="row">
                     <p><b>Cancellation policy on vendor initiated cancellations :</b>
                        &nbsp;{{currentVendorDetails?.decorFaq?.dr_cancellationPolicySelf}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.decorFaq?.dr_cancelationTermsnConsitions" class="row">
                     <p><b>Cancellations terms and conditions :</b>
                        &nbsp;{{currentVendorDetails?.decorFaq?.dr_cancelationTermsnConsitions}}</p>
                  </div>

               </div>

               <div class="col-md-4 ">

                  <div *ngIf="currentVendorDetails?.decorFaq?.dr_startYear" class="row">
                     <p><b>Started in:</b> &nbsp;{{currentVendorDetails?.decorFaq?.dr_startYear}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.decorFaq?.dr_advanceBookingWeeks" class="row">
                     <p><b>Weeks in advance bookings need to be done :</b>
                        &nbsp;{{currentVendorDetails?.decorFaq?.dr_advanceBookingWeeks}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.decorFaq?.dr_indoorStartingPrice" class="row">
                     <p><b>Starting price for indoor decor :</b>
                        &nbsp;{{currency}}{{currentVendorDetails?.decorFaq?.dr_indoorStartingPrice}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.decorFaq?.dr_homefunctionStartingPrice" class="row">
                     <p><b>Starting price for home decor (flowers and lights for haldi,dinners etc) </b>
                        &nbsp;{{currency}}{{currentVendorDetails?.decorFaq?.dr_homefunctionStartingPrice}}</p>
                  </div>


               </div>
               <div class="col-md-1"></div>
            </div>

         </div>

         <!--====================== Charter Faq Information -------------------------------->
         <div *ngIf="currentVendor?.idcategory==9 && hasFaqInfo">

            <div class="row">
               <div class="col-md-4 ">
                  <div *ngIf="currentVendorDetails?.charterFaq?.ch_primaryServiceType" class="row">
                     <p><b>Primary Service Type :</b> &nbsp;{{currentVendorDetails?.charterFaq?.ch_primaryServiceType}}
                     </p>
                  </div>
                  <div *ngIf="currentVendorDetails?.charterFaq?.ch_rentalCostYesNo" class="row">
                     <p><b>Rental cost present? :</b> &nbsp;{{currentVendorDetails?.charterFaq?.ch_rentalCostYesNo}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.charterFaq?.ch_startYear" class="row">
                     <p><b>Year started :</b> &nbsp;{{currentVendorDetails?.charterFaq?.ch_startYear}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.charterFaq?.ch_aircraftTypes" class="row">
                     <p><b>Aircrat types operated :</b> &nbsp;{{currentVendorDetails?.charterFaq?.ch_aircraftTypes}}</p>
                  </div>


               </div>

               <div class="col-md-1"></div>

               <div class="col-md-3 ">

                  <div *ngIf="currentVendorDetails?.charterFaq?.ch_startingPrice" class="row">
                     <p><b>Starting price for renting :</b> &nbsp;{{currentVendorDetails?.charterFaq?.ch_startingPrice}}
                     </p>
                  </div>
                  <div *ngIf="currentVendorDetails?.charterFaq?.ch_startingPriceBookingnTechnician" class="row">
                     <p><b>Starting price for booking along with technician assistance :</b>
                        &nbsp;{{currentVendorDetails?.charterFaq?.ch_startingPriceBookingnTechnician}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.charterFaq?.ch_partialRoundsYesNo" class="row">
                     <p><b>Partial rounds available? :</b>
                        &nbsp;{{currentVendorDetails?.charterFaq?.ch_partialRoundsYesNo}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.charterFaq?.ch_alcoholPolicy" class="row">
                     <p><b>Alcohol policy :</b> &nbsp;{{currentVendorDetails?.charterFaq?.ch_alcoholPolicy}}</p>
                  </div>

               </div>

               <div class="col-md-4 ">

                  <div *ngIf="currentVendorDetails?.charterFaq?.ch_recognitions" class="row">
                     <p><b>Recognitions :</b> &nbsp;{{currentVendorDetails?.charterFaq?.ch_recognitions}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.charterFaq?.ch_registeredYesNo" class="row">
                     <p><b>Services registered with the local authorities and government? :</b>
                        &nbsp;{{currentVendorDetails?.charterFaq?.ch_registeredYesNo}}</p>
                  </div>



               </div>
               <div class="col-md-1"></div>
            </div>

         </div>

         <!--====================== Makeup Artists Faq Information -------------------------------->
         <div *ngIf="currentVendor?.idcategory==10 && hasFaqInfo">

            <div class="row">
               <div class="col-md-4 ">
                  <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_hotelspanels" class="row">
                     <p><b>Hotels/ banquets where vendor is enlisted :</b>
                        &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_hotelspanels}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_usp" class="row">
                     <p><b>USP :</b> &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_usp}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_startyear" class="row">
                     <p><b>Year started :</b> &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_startyear}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_cities" class="row">
                     <p><b>Number of cities where wedding was covered by vendor till date :</b>
                        &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_cities}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_prominentclients" class="row">
                     <p><b>Celebrity weddings done by the vendor :</b>
                        &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_prominentclients}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_startingrate" class="row">
                     <p><b>Starting price for a wedding makeup engagement :</b>
                        &nbsp;{{currency}}{{currentVendorDetails?.makeupartistFaq?.mk_startingrate}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_addguests" class="row">
                     <p><b>Additional guests apart from bride and groom included in the above package :</b>
                        &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_addguests}}</p>
                  </div>


               </div>



               <div class="col-md-4 ">


                  <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_costpolicy" class="row">
                     <p><b>Travel and lodging costs policy :</b>
                        &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_costpolicy}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_daysadvance" class="row">
                     <p><b>Weeks in advance booking needs to be made :</b>
                        &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_daysadvance}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_cancellationuser" class="row">
                     <p><b>Cancellation policy(cancellation initiated by customer) :</b>
                        &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_cancellationuser}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_cancellationvendor" class="row">
                     <p><b>Cancellation policy(cancellation initiated by vendor) :</b>
                        &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_cancellationvendor}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_servicesoffered" class="row">
                     <p><b>Services Offerred :</b> &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_servicesoffered}}
                     </p>
                  </div>
                  <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_products" class="row">
                     <p><b>Products used for makeup :</b> &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_products}}
                     </p>
                  </div>
                  <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_traveltovenue" class="row">
                     <p><b>Vendor travels to the venue? :</b>
                        &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_traveltovenue}}</p>
                  </div>

               </div>

               <div class="col-md-4 ">

                  <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_travelprice" class="row">
                     <p><b>Amount charged for events outside hometown :</b>
                        &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_travelprice}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_priceincludes" class="row">
                     <p><b>Bridal make-up price includes :</b>
                        &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_priceincludes}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_airbrushservice" class="row">
                     <p><b>Air-brush service available? :</b>
                        &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_airbrushservice}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_trialspolicy" class="row">
                     <p><b>Trials policy :</b> &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_trialspolicy}}</p>
                  </div>
                  <div *ngIf="currentVendorDetails?.makeupartistFaq?.mk_awards" class="row">
                     <p><b>Awards won :</b> &nbsp;{{currentVendorDetails?.makeupartistFaq?.mk_awards}}</p>
                  </div>



               </div>

            </div>

         </div>


      </div>



   </div>

   <!-- Reviews Tab -->
   <div *ngIf="activeTab === 'reviews'" class="tab-pane fade show active" id="reviews-tab" role="tabpanel"
      aria-labelledby="reviews-tab">
      <div class="col-md-12 ml-8 mr-8">

         <!--==================================== Reviews Section ====================================-->

         <div class="row">
            <div class="col-md-2">
               <h1>Reviews</h1>
            </div>
            <div class="col-md-2">
               <button class="border button-style-5" (click)="addReview()">Write a review</button>
            </div>
         </div>

         <!-- Add Review Section -->
         <div *ngIf="showAddBlock">
            <div class="row">
               <div class="col-md-6">
                  <label for="description" class="form-label">Description</label>
                  <textarea class="form-control" id="description" [(ngModel)]="reviewText" maxlength="999"
                     rows="4"></textarea>
               </div>
               <div class="col-md-1 pt-4">
                  <button class="border button-style-5" id="submitbutton" (click)="addReviewEntry()">Submit</button>
               </div>
               <div class="col-md-1 pt-4">
                  <button class="border button-style-5" id="cancelbutton" (click)="cancelReview()">Cancel</button>
               </div>
            </div>
         </div>

         <!-- Edit Review Section -->
         <div *ngIf="showEditBlock">
            <div class="row">
               <div class="col-md-6">
                  <label for="description" class="form-label">Description</label>
                  <textarea class="form-control" id="description" [(ngModel)]="reviewText" maxlength="999"
                     rows="4"></textarea>
               </div>
               <div class="col-md-1 pt-4">
                  <button class="border button-style-5" id="submitbutton" (click)="editReviewEntry()">Submit</button>
               </div>
               <div class="col-md-1 pt-4">
                  <button class="border button-style-5" id="cancelbutton" (click)="cancelEditReview()">Cancel</button>
               </div>
            </div>
         </div>

         <!-- No Reviews Available Section -->
         <div class="row py-4 ml-2" *ngIf="reviews.length === 0">
            <p>No reviews available</p>
         </div>

         <!-- List of Reviews -->
         <div class="row pt-2" *ngFor="let review of reviews">
            <div class="row p-2">
               <b><span class="material-icons user-icon">person</span> {{review.username}}&nbsp;</b>
            </div>

            <div class="row">
               <div class="col-md-4">
                  <p class="p-2">{{review.description}}</p>
               </div>

               <!-- Edit/Delete buttons only for the current user's reviews -->
               <div class="col-md-8" *ngIf="review.idUser === currentUser?.iduser && review.idUser > 0">
                  <div class="row">
                     <div class="col-md-1">
                        <button class="border button-style-4" (click)="editReview(review)">Edit</button>
                     </div>
                     <div class="col-md-1">
                        <button class="border button-style-4" (click)="deleteReview(review)">Delete</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- Amenities section -->
   <div *ngIf="activeTab === 'amenities'" class="tab-pane fade show active" id="amenities-tab" role="tabpanel"
      aria-labelledby="amenities-tab">
      <div class="row" *ngIf="amenities.length>0">
         <div class="col-md-12 ml-8">

            <div class="row">
               <div class="col-md-3 flex items-center pt-2 mb-3" *ngFor="let amenity of amenities">
                  <div>
                     <span class="material-icons green-tick">check</span>{{amenity.amenityName}}
                  </div>
               </div>
            </div>

         </div>


      </div>
   </div>
   <div class="row">
      <!-- First 9 columns  -->
      <div class="map col-md-9 mb-2">
         <div class="row pt-4">
            <google-map height="400px" width="100%" [center]="center" [zoom]="zoom">
               <map-marker [position]="center"></map-marker>
            </google-map>
         </div>
      </div>

      <!-- Last 3 columns (visible, booking section placed here) -->
      <div class="paybutton col-md-3 pt-28 ">
         <div class="row">
            <div class="col-12 flex justify-items-center">
               <div class="ml-10 mr-10 pt-2">
                  <div class="text-center">
                     <div class="soulsWedSubheadingFont2">
                        <b>From: {{ currency }} {{ basePrice | number: '1.0-0' }} {{ currentVendor?.pricebasis
                           }}</b>
                     </div>
                  </div>
                  <br>
                  <div class="row justify-center mb-3">
                     <button class="p-2 button-style-2" (click)="payandbook()">Pay and Book</button>
                  </div>
                  <div class="row justify-center mb-3">
                     <div class="row">Confirm your booking by paying {{advancepercentage}}%</div>
                     <div class="row">Pay balance amount {{daysprior}} days before the event</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>



</div>



<app-footersection></app-footersection>