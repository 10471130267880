import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,of  } from 'rxjs';
import { BehaviorSubject } from 'rxjs'
import { VendorSummaryData,VendorDetails,VendorID, VendorCalendar, VendorReviews,Mailcontent,VendorImages, VendorVideos} from '../models/datamodels';
import { environment } from '../environments/environment';
import { TransactionData } from '../models/datamodels';
import { firstValueFrom } from 'rxjs';
import { catchError, map ,tap} from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})

export class VendorDataServiceService
{
  idVendorAdded :number=0;
  
  //private currentVendorDataSource = new BehaviorSubject<VendorSummaryData | null>(null);
  private currentVendorDataSource = new BehaviorSubject<VendorSummaryData | null>(this.getSessionData());
  currentVendor = this.currentVendorDataSource.asObservable();


  private currentTransactionDataSource = new BehaviorSubject<TransactionData | null>(this.getTransactionDataSessionData());
  currentTransaction = this.currentTransactionDataSource.asObservable();

  private mailedTransactionDataSource = new BehaviorSubject<TransactionData | null>(this.getMailedTransactionDataSessionData());
  mailedTransaction = this.mailedTransactionDataSource.asObservable();


  private apiHostUrl = environment.middletierhost;
  private apiUrl='';
  private imageUploadStatus=1;
  private videoUploadStatus=1;
  

  constructor(private http: HttpClient,private router:Router) 
  { }


  changeData(data: VendorSummaryData) 
  {
    this.currentVendorDataSource.next(data);
    this.setSessionData(data);
  }

  private getSessionData(): VendorSummaryData | null 
  {
    const data = sessionStorage.getItem('currentVendorData');
    return data ? JSON.parse(data) : null;
  }

  private setSessionData(data: VendorSummaryData) 
  {
    sessionStorage.setItem('currentVendorData', JSON.stringify(data));
  }
  

  //----------------- Transaction Data ------------------

  changeTransactionData(data: TransactionData) :Observable<TransactionData>
  {
    this.currentTransactionDataSource.next(data);
    this.setTransactionDataSessionData(data);
    return of(data);
    
  }

  private getTransactionDataSessionData(): TransactionData | null 
  {
    const data = sessionStorage.getItem('currentTransactionData');
    return data ? JSON.parse(data) : null;
  }

  private setTransactionDataSessionData(data: TransactionData) 
  {
    sessionStorage.setItem('currentTransactionData', JSON.stringify(data));
  }

   //----------------- Mailed Transaction Data ------------------

   changeMailedTransactionData(data: TransactionData) :Observable<TransactionData>
   {
     this.mailedTransactionDataSource.next(data);
     this.setMailedTransactionDataSessionData(data);
     return of(data);
     
   }
 
   private getMailedTransactionDataSessionData(): TransactionData | null 
   {
     const data = sessionStorage.getItem('mailedTransactionData');
     return data ? JSON.parse(data) : null;
   }
 
   private setMailedTransactionDataSessionData(data: TransactionData) 
   {
     sessionStorage.setItem('mailedTransactionData', JSON.stringify(data));
   }
  

  createOrder(amount: number, currency: string): Observable<any> 
  {
    console.log('Called create orders : amount,currency - ',amount,currency);

    this.apiUrl = this.apiHostUrl+'/vendors/createOrder'; 
    return this.http.post(this.apiUrl, {amount, currency});

  }

  verifyPayment(paymentData: any): Observable<any> 
  {
    console.log('Called verifyPayment. Payment Details :',paymentData);

    this.apiUrl = this.apiHostUrl+'/vendors/verifyPayment'; 
    return this.http.post(this.apiUrl,paymentData);
    
  }

  //-----------Function to return list of vendors for a category and country ------------------------------
  getAllVendors(): Observable<VendorSummaryData[]>
  {

    console.log('Called get all vendors');
          
    this.apiUrl = this.apiHostUrl+'/vendors'; 

    return this.http.get<VendorSummaryData[]>(this.apiUrl);

  }


  //-----------Function to return list of vendors for a category and country ------------------------------
  getVendors(idCountry:number,idCategory:number): Observable<VendorSummaryData[]>
  {

    console.log('Called get vendors');
      
    
    this.apiUrl = this.apiHostUrl+'/vendors/'+idCountry.toString()+'/'+idCategory.toString(); 

    return this.http.get<VendorSummaryData[]>(this.apiUrl);

  }


  //-----------Function to get details of a vendor ------------------------------
  getVendorDetails(idVendor:number):Observable<VendorDetails>
  {
    
    console.log('Called get vendordetails for vendor id :'+idVendor);
      
    this.apiUrl = this.apiUrl = this.apiHostUrl+'/vendors/'+idVendor.toString();

    return this.http.get<VendorDetails>(this.apiUrl);

   
  }

  
  //-----------Function to return list of vendors for a category and country ------------------------------
  getVendorTransactionsbyUser(idvendor:number,iduser:number): Observable<TransactionData[]>
  {

    console.log('Called get getVendorTransactionsbyUser');
      
    
    this.apiUrl = this.apiHostUrl+'/vendors/reviews/'+idvendor.toString()+'/'+iduser.toString(); 

    return this.http.get<TransactionData[]>(this.apiUrl);

  }

   //-----------Function to return list of vendors for a category and country ------------------------------
   getVendorImages(idvendor:number): Observable<VendorImages[]>
   {
 
     console.log('Called get getVendorImages in service');
       
     this.apiUrl = this.apiHostUrl+'/vendors/vendorimages/'+idvendor.toString(); 

     console.log("URL is :",this.apiUrl);
 
     return this.http.get<VendorImages[]>(this.apiUrl);
 
   }

   //-----------Function to return list of vendors for a category and country ------------------------------
   getVendorVideos(idvendor:number): Observable<VendorVideos[]>
   {
 
     console.log('Called get getVendorImages');
       
     
     this.apiUrl = this.apiHostUrl+'/vendors/vendorvideos/'+idvendor.toString(); 
 
     return this.http.get<VendorVideos[]>(this.apiUrl);
 
   }

  

  


  //-----------Function to return list of hotels for home screen listing  ------------------------------
  getHotels(): Observable<VendorSummaryData[]>
  {

    console.log('Called getHotels');      
    
    this.apiUrl = this.apiHostUrl+'/vendors/hotels'; 

    return this.http.get<VendorSummaryData[]>(this.apiUrl);

  }

   //-----------Function to return list of venues for home screen listing  ------------------------------
   getVenues(): Observable<VendorSummaryData[]>
   {
 
     console.log('Called getvenues');      
     
     this.apiUrl = this.apiHostUrl+'/vendors/venues'; 
 
     return this.http.get<VendorSummaryData[]>(this.apiUrl);
 
   }

   //-----------Function to return list of caterers for home screen listing  ------------------------------
   getCaterers(): Observable<VendorSummaryData[]>
   {
 
     console.log('Called getCaterers');      
     
     this.apiUrl = this.apiHostUrl+'/vendors/caterers'; 
 
     return this.http.get<VendorSummaryData[]>(this.apiUrl);
 
   }

   //-----------Function to return list of charters for home screen listing  ------------------------------
   getCharters(): Observable<VendorSummaryData[]>
   {
 
     console.log('Called getCharters');      
     
     this.apiUrl = this.apiHostUrl+'/vendors/charters'; 
 
     return this.http.get<VendorSummaryData[]>(this.apiUrl);
 
   }

   
   //-----------Function to return list of caterers for home screen listing  ------------------------------
   getPlanners(): Observable<VendorSummaryData[]>
   {
 
     console.log('Called getPlanners');      
     
     this.apiUrl = this.apiHostUrl+'/vendors/planners'; 
 
     return this.http.get<VendorSummaryData[]>(this.apiUrl);
 
   }

   
   //-----------Function to return list of Decorators for home screen listing  ------------------------------
   getDecorators(): Observable<VendorSummaryData[]>
   {
 
     console.log('Called getDecorators');      
     
     this.apiUrl = this.apiHostUrl+'/vendors/decorators'; 
 
     return this.http.get<VendorSummaryData[]>(this.apiUrl);
 
   }


   //-----------Function to return list of Photographers for home screen listing  ------------------------------
   getPhotographers(): Observable<VendorSummaryData[]>
   {
 
     console.log('Called getPhotographers');      
     
     this.apiUrl = this.apiHostUrl+'/vendors/photographers'; 
 
     return this.http.get<VendorSummaryData[]>(this.apiUrl);
 
   }

    //-----------Function to return list of hotels for home screen listing  ------------------------------
    getMakeupArtists(): Observable<VendorSummaryData[]>
    {

    console.log('Called getMakeupArtists');      
    
    this.apiUrl = this.apiHostUrl+'/vendors//makeupartists'; 

    return this.http.get<VendorSummaryData[]>(this.apiUrl);

    }

    //-----------Function to return list of vendors for a category and country ------------------------------
  getUserFavouriteVendors(idUser:number): Observable<VendorSummaryData[]>
  {

    console.log('Called get user favourite vendors');
      
    
    this.apiUrl = this.apiHostUrl+'/vendors/userfavourites/'+idUser.toString()+'/0'; 

    console.log('Get url is :', this.apiUrl)

    return this.http.get<VendorSummaryData[]>(this.apiUrl);

  }

     //-----------Function to return list of vendors for a category and country ------------------------------
     getUserVendors(idUser:number,idCategory:number,idCountry:number): Observable<VendorSummaryData[]>
     {
   
       console.log('Called get uservendors');
         
       
       this.apiUrl = this.apiHostUrl+'/vendors/uservendors/'+idUser.toString()+'/'+idCategory.toString()+'/'+idCountry.toString(); 
   
       console.log('Get url is :', this.apiUrl)
   
       return this.http.get<VendorSummaryData[]>(this.apiUrl);
   
     }



  addVendor(vendorDetails:VendorDetails,vendorImages:File[],vendorVideos:File[]):void
  {
    console.log('addVendor called');
    
    this.apiUrl = this.apiHostUrl+'/vendors/';
    this.idVendorAdded=0;

    this.http.post(this.apiUrl, vendorDetails).subscribe(
      {
 
          next: async (response:any) => {
            this.idVendorAdded=response.data;
            console.log('New Vendorid:',this.idVendorAdded);

            let uploadPromises: Promise<any>[] = [];

           

            if (this.idVendorAdded>0 && vendorImages.length>0) 
            {
              console.log('Upload Image called');
              this.imageUploadStatus=0;
              uploadPromises.push(this.uploadVendorImages(this.idVendorAdded,vendorImages));
            }

            if (this.idVendorAdded>0 && vendorVideos.length>0) 
            {
              this.videoUploadStatus=0;
              console.log('Upload Video called');
              uploadPromises.push(this.uploadVendorVideos(this.idVendorAdded,vendorVideos));
  
            }

            try 
            {
              await Promise.all(uploadPromises);

              if (this.idVendorAdded>0 && vendorImages.length>0) 
              {
                console.log('checkuploadstatus called');
                this.imageUploadStatus=await this.checkUploadStatus(this.idVendorAdded);
                console.log('Status :',this.imageUploadStatus);
              }

              if (this.idVendorAdded>0 && vendorVideos.length>0) 
                {
                  console.log('checkvideouploadstatus called');
                  this.videoUploadStatus=await this.checkVideoUploadStatus(this.idVendorAdded);
                  console.log('Status :',this.videoUploadStatus);
                }
              
              if(this.idVendorAdded>0 && this.imageUploadStatus==1 && this.videoUploadStatus==1)   // implement for video
                      alert('Vendor Registered !!!');
              else
              {
                      
                      this.deleteVendor(this.idVendorAdded).subscribe(data=>
                      {
                        alert('There was an error. Please retry');
                      })

                      
              }

            } catch (error) 
            {
              console.error('There was an error:', error);
            }

                

          }
          ,
          error: (error) => 
            {
            console.error('There was an error:', error)
            
          }
    });

    

  }

 
 async uploadNewImages(idVendor:number, vendorImagesNew:VendorImages[],vendorImageFilesNew:File[]): Promise<void>
 {

      return new Promise((resolve, reject) => 
      {
            this.apiUrl = this.apiHostUrl+'/vendors/newimages/'+idVendor.toString();

            this.http.put(this.apiUrl, vendorImagesNew).subscribe(
            {
                  next: async (response:any) => 
                  {
                        console.log('Response from server :',response);
              
                        this.idVendorAdded=response.data;
                        console.log('Updated Vendorid:',this.idVendorAdded);

                        let uploadPromises: Promise<any>[] = [];      
            
                        console.log('New Images array length:',vendorImageFilesNew.length);

                        if (this.idVendorAdded>0 && vendorImageFilesNew.length>0) 
                        {
                              console.log('Upload Image called');
                              this.imageUploadStatus=0;
                              uploadPromises.push(this.uploadVendorImages(this.idVendorAdded,vendorImageFilesNew));
                        }


                        try 
                        {
                              await Promise.all(uploadPromises);

                              if (this.idVendorAdded>0 && vendorImageFilesNew.length>0) 
                              {
                                    console.log('checkuploadstatus called');
                                    this.imageUploadStatus=await this.checkUploadStatus(this.idVendorAdded);
                                    console.log('Status :',this.imageUploadStatus);
                              }

             
                              if(this.idVendorAdded>0 && this.imageUploadStatus==1)   // implement for video
                                    alert('Images uploaded successfully');
                              else
                                    alert('There was an error. Please retry');

                              resolve(); // Resolving promise after upload process completes


                        } catch (error) 
                        {
                              alert('There was an error. Please retry');
                              resolve(); // Rejecting promise if an error occurs
                        }


                  }
                  ,
                  error: (error) => 
                  {
                    alert('There was an error. Please retry');
                    resolve(); // Rejecting promise if an error occurs
            
                  }

            });
      });

  }

  async uploadNewVideos(idVendor:number, vendorVideosNew:VendorVideos[],vendorVideoFilesNew:File[]): Promise<void>
 {

  return new Promise((resolve, reject) => 
  {
    this.apiUrl = this.apiHostUrl+'/vendors/newvideos/'+idVendor.toString();

    this.http.put(this.apiUrl, vendorVideosNew).subscribe(
    {
          next: async (response:any) => 
          {
              console.log('Response from server :',response);
              
              this.idVendorAdded=response.data;
              console.log('Updated Vendorid:',this.idVendorAdded);

              let uploadPromises: Promise<any>[] = [];      
            
              console.log('New Videos array length:',vendorVideoFilesNew.length);

              if (this.idVendorAdded>0 && vendorVideoFilesNew.length>0) 
              {
                  this.videoUploadStatus=0;
                  console.log('Upload Video called');
                  uploadPromises.push(this.uploadVendorVideos(this.idVendorAdded,vendorVideoFilesNew));
              }


              try 
              {
                await Promise.all(uploadPromises);

                if (this.idVendorAdded>0 && vendorVideoFilesNew.length>0) 
                {
                    console.log('checkvideouploadstatus called');
                    this.videoUploadStatus=await this.checkVideoUploadStatus(this.idVendorAdded);
                    console.log('Status :',this.videoUploadStatus);
                }

             
                if(this.idVendorAdded>0 && this.videoUploadStatus==1)   // implement for video
                        alert('Videos uploaded successfully');
                else
                        alert('There was an error. Please retry');

                resolve(); // Resolving promise after upload process completes

            } catch (error) 
            {
                  alert('There was an error. Please retry');
                  resolve(); // Rejecting promise if an error occurs
            }


          }
          ,
          error: (error) => 
          {
              alert('There was an error. Please retry');
              resolve(); // Rejecting promise if an error occurs
            
          }

          


    });

  });
      

  }


  updateVendor(vendorDetails:VendorDetails,vendorImages:File[],vendorVideos:File[]):void
  {
      console.log('updateVendor called for id :',vendorDetails.idvendor);
    
      this.apiUrl = this.apiHostUrl+'/vendors/'+vendorDetails.idvendor.toString();

      this.idVendorAdded=0;

      this.http.put(this.apiUrl, vendorDetails).subscribe(
      {
           next: async (response:any) => 
          {
            this.idVendorAdded=response.data;
            console.log('Updated Vendorid:',this.idVendorAdded);

            let uploadPromises: Promise<any>[] = [];      
            
            console.log('Images array length:',vendorImages.length);

            if (this.idVendorAdded>0 && vendorImages.length>0) 
            {
              console.log('Upload Image called');
              this.imageUploadStatus=0;
              uploadPromises.push(this.uploadVendorImages(this.idVendorAdded,vendorImages));
            }

            if (this.idVendorAdded>0 && vendorVideos.length>0) 
            {
              this.videoUploadStatus=0;
              console.log('Upload Video called');
              uploadPromises.push(this.uploadVendorVideos(this.idVendorAdded,vendorVideos));
  
            }

            try 
            {
              await Promise.all(uploadPromises);

              if (this.idVendorAdded>0 && vendorImages.length>0) 
              {
                console.log('checkuploadstatus called');
                this.imageUploadStatus=await this.checkUploadStatus(this.idVendorAdded);
                console.log('Status :',this.imageUploadStatus);
              }

              if (this.idVendorAdded>0 && vendorVideos.length>0) 
                {
                  console.log('checkvideouploadstatus called');
                  this.videoUploadStatus=await this.checkVideoUploadStatus(this.idVendorAdded);
                  console.log('Status :',this.videoUploadStatus);
                }
              
              if(this.idVendorAdded>0 && this.imageUploadStatus==1 && this.videoUploadStatus==1)   // implement for video
                      alert('Vendor Updated !!!');
              else
                      alert('There was an error. Please retry');

            } catch (error) 
            {
              console.error('There was an error:', error);
            }

            


          }
          ,
          error: (error) => 
            {
            console.error('There was an error:', error)
            
          }
    });

    

  }

  

  postTransaction(transaction: TransactionData): Observable<TransactionData[]> {
    console.log('Called post transaction with data :', transaction);
    this.apiUrl = this.apiHostUrl + '/vendors/transaction'; 
    console.log('url is :', this.apiUrl);
  
    return this.http.post<TransactionData[]>(this.apiUrl, transaction).pipe(
      tap((response: any) => {
        console.log('Transaction added :');
        //this.changeTransactionData(<TransactionData>response.data[0]);
      }),
      catchError((error) => {
        console.error('There was an error:', error);
        return of(error as TransactionData[]);
      })
    );
  }

  getTransactions(): Observable<TransactionData[]> 
  {
    
    this.apiUrl = this.apiHostUrl+'/vendors/transaction'; 
    return this.http.get<TransactionData[]>(`${this.apiUrl}`);
  }

  
  async uploadVendorImages(vendorid:number,vendorImages:File[]):Promise<any>
  {
   
  
    this.apiUrl = this.apiHostUrl+'/uploads/'+vendorid.toString();

    const formData: FormData = new FormData();  
    
    vendorImages.forEach(image => 
    {
        formData.append('vendorImages', image, image.name);
    });
    
    console.log( this.apiUrl);

    try 
    {
      const response = await firstValueFrom(this.http.post(this.apiUrl, formData));
      return response;
    } catch (error) 
    {
      // Handle the error as needed
      console.error('Upload failed', error);
      throw error;
    }

    //return firstValueFrom(this.http.post(this.apiUrl, formData));

   }

  checkUploadStatus(vendorid: number): Promise<number> 
  {
    this.apiUrl = this.apiHostUrl+'/vendors/vimagever/'+vendorid.toString()+"/"+vendorid.toString();
    return firstValueFrom(this.http.get<number>(this.apiUrl));
  }

  checkVideoUploadStatus(vendorid: number): Promise<number> 
  {
    this.apiUrl = this.apiHostUrl+'/vendors/videover/'+vendorid.toString()+"/"+vendorid.toString()+"/"+vendorid.toString();
    return firstValueFrom(this.http.get<number>(this.apiUrl));
  }

  
  async uploadVendorVideos(vendorid:number,vendorVideos:File[]):Promise<any>
  {
   
    this.apiUrl = this.apiHostUrl+'/uploadvideo/'+vendorid.toString();

    const formData: FormData = new FormData();  
    
    vendorVideos.forEach(video => 
    {
        formData.append('vendorVideos', video, video.name);
    });
    
    console.log( this.apiUrl);

    try 
    {
      const response = await firstValueFrom(this.http.post(this.apiUrl, formData));
      return response;
    } catch (error) 
    {
      // Handle the error as needed
      console.error('Upload failed', error);
      throw error;
    }

    //return firstValueFrom(this.http.post(this.apiUrl, formData));

   

  }


  deleteVendor(idvendor:number): Observable<any> 
  {
    
    console.log('Delete vendor called for idvendor:',idvendor)
    this.apiUrl = this.apiHostUrl+'/vendors/'+idvendor.toString(); 
    return this.http.delete(`${this.apiUrl}`);
  }

  addVendorCalendar(vendorCalendar: VendorCalendar): Observable<string> 
  {
      console.log('addVendorCalendar called');

      this.apiUrl = this.apiHostUrl+'/api/'; 
      console.log('URL:',this.apiUrl);
      console.log('Calendar details:',vendorCalendar);

      return this.http.post<any>(this.apiUrl, vendorCalendar).pipe(
      map(response => {
        this.idVendorAdded = response;
        console.log('New Vendor calendar added:', this.idVendorAdded);
        return 'Success';
      }),
      catchError(error => {
        console.error('There was an error:', error.error);
        return of(error.error || 'Failed');
      })
    );

  }

  updateVendorCalendar(vendorCalendar: VendorCalendar): Observable<string> 
  {
      console.log('updateVendorCalendar called');

      this.apiUrl = this.apiHostUrl+'/api/'; 
      console.log('URL:',this.apiUrl);
      console.log('Calendar details:',vendorCalendar);

      return this.http.put<any>(this.apiUrl, vendorCalendar).pipe(
      map(response => {
        this.idVendorAdded = response;
        console.log('Vendor updated response:', this.idVendorAdded);
        return 'Success';
      }),
      catchError(error => {
        console.error('There was an error:', error.error);
        return of(error.error || 'Failed');
      })
    );

  }

  deleteVendorCalendar(vendorCalendar: VendorCalendar): Observable<string> 
  {
      console.log('deleteVendorCalendar called');

      this.apiUrl = this.apiHostUrl+'/api/'+vendorCalendar.idvendorNonavailabilities.toString();
      console.log('URL:',this.apiUrl);
      console.log('Calendar details:',vendorCalendar);

      return this.http.delete<any>(this.apiUrl).pipe(
      map(response => {
        
        console.log('Vendor deleted response:', response);
        return 'Success';
      }),
      catchError(error => {
        console.error('There was an error:', error.error);
        return of(error.error || 'Failed');
      })
    );

  }

  sendReceiptMail(mailcontent:Mailcontent):void
  {
    console.log('sendReceiptMail called');

    this.apiUrl = this.apiHostUrl+'/api/sendreceiptemail/sendreceiptemail/sendreceiptemail/sendreceiptemail';
    console.log('URL:',this.apiUrl);
    console.log('Mail details:',mailcontent);

   
    this.http.put(this.apiUrl, mailcontent).subscribe(
      {
        next: (response:any) => 
          {
               console.log('response:',response);
          }
        ,
        error: (error) => 
          {
              console.error('There was an error:', error)
          
          }
  
      })
    

  }

  addReview(review: VendorReviews): Observable<VendorReviews[]>
  {
    console.log('Called addReview with data :',review);
    this.apiUrl = this.apiHostUrl+'/vendors/review'; 
    console.log('url is :',this.apiUrl);

    return this.http.post<VendorReviews[]>(this.apiUrl, review);
  }

  updateReview(review: VendorReviews): Observable<VendorReviews[]>
  {
    console.log('Called updateReview with data :',review);
    this.apiUrl = this.apiHostUrl+'/vendors/review'; 
    console.log('url is :',this.apiUrl);

    return this.http.put<VendorReviews[]>(this.apiUrl, review);
    
  }

  deleteReview(review: VendorReviews): Observable<VendorReviews[]>
  {

    console.log('Called deleteReview with data :',review);
    this.apiUrl = this.apiHostUrl+'/vendors/review/'+review.idvendorreviews.toString()+'/'+review.idvendor.toString(); 
    console.log('url is :',this.apiUrl);

    return this.http.delete<VendorReviews[]>(this.apiUrl);

    
  }


  getVendorCalendar(idvendor:number):Observable<VendorCalendar[]>
  {
    console.log('getVendorCalendar called');

      this.apiUrl = this.apiHostUrl+'/api/'+idvendor.toString();
      return this.http.get<VendorCalendar[]>(this.apiUrl);
  }

  checkVendorAvailability(idvendor:number,startdate:string,enddate:string):Observable<VendorCalendar[]>
  {
    console.log('checkVendorAvailability called');

    const criteria=
    {
      idvendor:idvendor,
      startdate:startdate,
      enddate:enddate
    }

      this.apiUrl = this.apiHostUrl+'/api/'+idvendor.toString()+'/'+startdate+'/'+enddate;
      console.log('Url formed :',this.apiUrl);

      return this.http.put<VendorCalendar[]>(this.apiUrl,criteria);
  }


}



