import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CountryData, CurrencyData, ExchangeRateData, VendorID, VendorSummaryData } from '../../../models/datamodels';
import { EnvironmentDataServiceService } from '../../../services/environment-data-service.service';
import { MasterdataserviceService } from '../../../services/masterdataservice.service';
import { UserServiceService } from '../../../services/user-service.service';
import { VendorDataServiceService } from '../../../services/vendor-data-service.service';
import { GobackComponent } from '../../goback/goback.component';
import { SignInComponent } from '../../usermgmt/sign-in/sign-in.component';

@Component({
  selector: 'app-makeupartistslisting',
  standalone: true,
  imports: [MatCardModule,CommonModule,RouterModule,FormsModule,GobackComponent],
  templateUrl: './makeupartistslisting.component.html',
  styleUrl: './makeupartistslisting.component.css'
})
export class MakeupartistslistingComponent implements OnInit
{

  vendorsListOriginal:VendorSummaryData[]=[];
  vendorsList:VendorSummaryData[]=[];
  serverpath= environment.middletierhost+'/uploads';  //---serverpath for images----   
  countriesList:CountryData[] =[];
  countriesListFiltered: CountryData[] =[]; 
  cityList:string[]=[];  
  currentIndex: number = 0;    
  favourites:VendorID[]=[];  
  iduser=0;  

  selectedVendor:VendorSummaryData|null=null;
  selectedCountry:CountryData|null=null;
  selectedCity:string|null=null;

  showSearch:boolean|null=false;
  searchButtonText:string='Search Makeup Artists';
  searchQuery: string|null = '';
  fxList:ExchangeRateData[]=[];
  selectedCurrency:CurrencyData|null=null;

  titleText:string='Makeup Artists';
  subTitleText:string='Beauty and the brushes';

  constructor(private router:Router,public dialog: MatDialog,private vendorDataService:VendorDataServiceService , private environmentDataService : EnvironmentDataServiceService,private masterDataService:MasterdataserviceService,private userDataService:UserServiceService){}

  ngOnInit(): void 
  {

    this.selectedCountry=this.environmentDataService.getSessionData<CountryData>('savedCountry');
    this.selectedCity=this.environmentDataService.getSessionData<string>('savedCity');
    this.selectedVendor=this.environmentDataService.getSessionData<VendorSummaryData>('savedVendor');
    this.searchQuery=this.environmentDataService.getSessionData<string>('savedSearchQuery');

    this.showSearch=this.environmentDataService.getSessionData<boolean>('savedShowSearch');
    if(this.showSearch)
      this.searchButtonText="Hide search panel"
    else
      this.searchButtonText="Search Makeup Artists"

    this.countriesList=[];
    this.cityList=[];
    this.vendorsList=[];
    
    this.environmentDataService.currentCurrency.subscribe(data=>
        {
            this.selectedCurrency=data;
  
            this.masterDataService.getExchangeRates().subscribe(data=>
            {
              this.fxList=data;
              console.log('Exchange rates fetched :',this.fxList);
            })
        });

        this.vendorDataService.getMakeupArtists().subscribe
        (data=>
          {
            this.vendorsListOriginal=data;       
            console.log('Hotels list revieved with number ' , data.length);
            console.log('Hotels list copied with number ' , this.vendorsListOriginal.length);
    
            this.vendorsList = this.vendorsListOriginal;
    
            this.masterDataService.getCountries().subscribe
            (
                data=>
                {
                  this.countriesList=data;
    
                  // Extract unique idcountry from vendorsList
                  const vendorCountries = new Set(this.vendorsList.map(vendor => vendor.idcountry));
    
                    // Filter countriesList based on idcountry in vendorCountries
                this.countriesListFiltered = this.countriesList.filter(country => vendorCountries.has(country.idcountry)).sort();
  
                //this.cityList= this.vendorsList.map(vendor => vendor.city);
                this.cityList = [...new Set(this.vendorsList.map(vendor => vendor.city).filter(city => city && city.trim() !== ''))].sort();
    
                //-------- set filters if already exists (in case ofback button) --------------------------------

                if(this.selectedCountry)
                  {
                      
                      this.vendorsList = this.vendorsListOriginal.sort((a, b) => 
                      {
                          return a.vendorname.localeCompare(b.vendorname);
                      });;
    
                      this.vendorsList=this.vendorsList.filter(v=>v.idcountry==this.selectedCountry?.idcountry).sort((a, b) => 
                      {
                          return a.vendorname.localeCompare(b.vendorname);
                      });;
            
                      // this.cityList= this.vendorsList.map(vendor => vendor.city);
                      this.cityList = [...new Set(this.vendorsList.map(vendor => vendor.city).filter(city => city && city.trim() !== ''))].sort();
                  
                  }
                      
                  
                  if(this.selectedCity)
                  {
                      this.vendorsList=this.vendorsList.filter(v=>v.city==this.selectedCity).sort((a, b) => 
                      {
                          return a.vendorname.localeCompare(b.vendorname);
                      });;
                  }
                      
                
                  if(this.selectedVendor)
                  {
                      this.vendorsList=this.vendorsList.filter(v=>v.idvendor==this.selectedVendor?.idvendor).sort((a, b) => 
                      {
                          return a.vendorname.localeCompare(b.vendorname);
                      });;
                  }
  
                  
  
                  if(this.searchQuery && this.searchQuery?.length>0)
                  {
                    this.searchVenuesByName();
                  }
                    
                })
            
          }) 
  
  
          
  
        //--------- get user favourites -------------
  
        this.environmentDataService.currentUser.subscribe(user=>
        {
            if (user)
            {
                this.iduser=user.iduser;
  
                this.userDataService.getUserFavourites(this.iduser).subscribe
                (data=>
                 {
                    this.favourites=data;
                })
            }
         })
        
        
  
    }
  
    
  
    //============== Function to updateselected country ============================
    onVendorSelect(selected: VendorSummaryData): void 
    {
  
      if(this.selectedCountry)
      {
        this.environmentDataService.setSessionData('savedCountry', this.selectedCountry);
      }
  
      if(this.selectedCity)
      {
        this.environmentDataService.setSessionData('savedCity', this.selectedCity);
      }
  
      if(this.selectedVendor)
      {
          this.environmentDataService.setSessionData('savedVendor', this.selectedVendor);
      }
  
      if(this.searchQuery && this.searchQuery.length>0)
      {
            this.environmentDataService.setSessionData('savedSearchQuery', this.searchQuery);
      }
  
     
      this.environmentDataService.setSessionData('savedShowSearch', this.showSearch);
    
        
    
      this.vendorDataService.changeData(selected);
      this.router.navigate(['/vendordetails']);
      
     // this.environmentDataService.changeCountryData(this.countriesList.find(c=>c.idcountry=selected.idcountry));
      
          
         
    }
  
    getStars(rating:string):string
    {
      if (rating=='5 star')
        return '*****'
        else if (rating=='4 star')
        return '****'
        else if (rating=='3 star')
          return '***'
        else if (rating=='2 star')
          return '**'
        else if (rating=='1 star')
          return '*'
        else
          return '';
    }
  
    toggleFavourites(idvendor:number)
    {
  
  
      //-------- Ask to sign in if not signed in --------------------
  
      this.environmentDataService.currentUser.subscribe(user=>
        {
          if (user)
          {
              this.iduser=user.iduser;
          }
          else
          {
            //-------- If not logged in, ask for user to log-in ------------------
            const dialogRef = this.dialog.open(SignInComponent, 
            {
              width: '250px'
            });
        
            dialogRef.afterClosed().subscribe(result => 
            {
              this.router.navigate(['/home']);
              console.log('The dialog was closed');
            });
  
          }
        }
      )
  
      if(this.iduser>0)
      {
          //--------------- Add or remove user favourites as applicable -------------------
      if(!this.favourites.find(c=>c.idvendor==idvendor))
        {
          //this.favourites.push(idvendor);
          this.userDataService.addUserFavourites({iduser:this.iduser,idvendor:idvendor}).subscribe
          (data=>
            {
              this.userDataService.getUserFavourites(this.iduser).subscribe
              (
                info=>
                  {
                    this.favourites=info;
                    console.log('favourites list :',this.favourites);
                  }
              )
            }
          )
        }
        else
        {
          //this.favourites=this.favourites.filter(n=>n!=idvendor)
          //this.favourites.push(idvendor);
          this.userDataService.deleteUserFavourites({iduser:this.iduser,idvendor:idvendor}).subscribe
          (data=>
            {
              this.userDataService.getUserFavourites(this.iduser).subscribe
              (
                info=>
                  {
                    this.favourites=info;
                    console.log('favourites list :',this.favourites);
                  }
              )
            }
          )
        }
      }
  
      
  
      
    }
  
    isFavourite(idvendor:number):boolean
    {
      if(this.favourites.find(c=>c.idvendor==idvendor))
        return true;
      else
      return false;
    }
  
  
     //============== Function to updateselected country ============================
     onVendorSelected(selected: VendorSummaryData): void 
     {
       this.selectedVendor = selected;
  
       if(this.selectedVendor)
        {
          this.vendorsList=this.vendorsList.filter(v=>v.idvendor==this.selectedVendor?.idvendor).sort((a, b) => {
            return a.vendorname.localeCompare(b.vendorname);
          });;
        }
  
  
      // this.updateDisplayedVendors();
        
     }
  
     //============== Function to updateselected country ============================
     onCountrySelected(selected: CountryData): void 
     {
       this.selectedCountry = selected;
      this.environmentDataService.changeCountryData(selected);
  
  
       this.selectedCity=null;
       this.selectedVendor=null;
  
       //this.vendorsList = this.vendorsListOriginal.slice(this.currentIndex, this.currentIndex + 3);
      this.vendorsList = this.vendorsListOriginal.sort((a, b) => 
      {
        return a.vendorname.localeCompare(b.vendorname);
      });;
  
      if(this.selectedCountry)
      {
          this.vendorsList=this.vendorsList.filter(v=>v.idcountry==this.selectedCountry?.idcountry).sort((a, b) => {
            return a.vendorname.localeCompare(b.vendorname);
          });;
          
         // this.cityList= this.vendorsList.map(vendor => vendor.city);
         this.cityList = [...new Set(this.vendorsList.map(vendor => vendor.city).filter(city => city && city.trim() !== ''))].sort();
        
         this.selectedCity=null;
         this.selectedVendor=null;
    
      }
      else
      {
        
          this.cityList = [];
          this.vendorsList = [];
        
      }
  
       //this.updateDisplayedVendors();    
     
       
       
        
     }
  
     
    //============== Function to updateselected city ============================
    onCitySelected(selected: string): void 
    {
      this.selectedCity = selected;
  
      this.selectedVendor=null;
  
        //this.vendorsList = this.vendorsListOriginal.slice(this.currentIndex, this.currentIndex + 3);
       this.vendorsList = this.vendorsListOriginal.sort((a, b) => 
       {
         return a.vendorname.localeCompare(b.vendorname);
       });;
   
       if(this.selectedCountry)
       {
           this.vendorsList=this.vendorsList.filter(v=>v.idcountry==this.selectedCountry?.idcountry).sort((a, b) => {
             return a.vendorname.localeCompare(b.vendorname);
           });;
       }
  
      if(this.selectedCity)
       {
         this.vendorsList=this.vendorsList.filter(v=>v.city==this.selectedCity).sort((a, b) => {
           return a.vendorname.localeCompare(b.vendorname);
         });;
  
       }
  
     // this.updateDisplayedVendors();
     
       
    }
  
     //============Clear filters ====================================
  
     clearFilters()
     {
  
      this.selectedCountry = null;
      this.selectedVendor=null;
      this.selectedCity=null;
      this.searchQuery='';
  
      
      this.environmentDataService.setSessionData('savedCountry', this.selectedCountry);
      this.environmentDataService.setSessionData('savedCity', this.selectedCity);
      this.environmentDataService.setSessionData('savedVendor', this.selectedVendor);
      this.environmentDataService.setSessionData('savedSearchQuery', this.searchQuery);
      this.environmentDataService.setSessionData('savedShowSearch', this.showSearch);
  
  
  
     // this.updateDisplayedVendors();
  
      
       //this.cityList= this.vendorsList.map(vendor => vendor.city);
       this.cityList = [...new Set(this.vendorsListOriginal.map(vendor => vendor.city).filter(city => city && city.trim() !== ''))].sort();
       this.vendorsList = this.vendorsListOriginal.sort((a, b) => {
        return a.vendorname.localeCompare(b.vendorname);
      });
  
      
  
      
     }
  
     enableSearch()
     {
       console.log('Enable Search Clicked');
       this.showSearch=!this.showSearch;
   
       if(this.showSearch)
         this.searchButtonText="Hide search panel"
       else
         this.searchButtonText="Search Makeup Artists"
       
     }
  
     convertCurrency(fromCurrency:string,toCurrency:string,amount:number):number
     {
       // console.log('Currency conversion from ' ,fromCurrency ,' to ',toCurrency);
  
       if(fromCurrency==toCurrency)
        return amount;
  
          const existingRatetoUSD = this.fxList.find(rate =>
            rate.fromCurrency == fromCurrency && rate.toCurrency == 'USD');
  
  
        //    console.log('existingRatetoUSD ' ,existingRatetoUSD , '. Rate :',existingRatetoUSD!.rate);
            
  
          if (existingRatetoUSD) 
          {
            const existingRatefromUSD = this.fxList.find(rate =>
              rate.fromCurrency == 'USD' && rate.toCurrency == toCurrency);
  
          //    console.log('existingRatefromUSD ' ,existingRatefromUSD , '. Rate :',existingRatefromUSD!.rate);
  
              if(existingRatefromUSD)
              {
                return amount*existingRatetoUSD.rate*existingRatefromUSD?.rate!
              }
              else
              return amount;
          
          } 
          else 
          {
              return amount;
              
          }
     }
  
     
  
    searchVenuesByName(): void 
     {
  
      if(this.searchQuery)
      {
        if (this.searchQuery.trim()) 
          {
            this.vendorsList = this.vendorsList.filter(vendor =>
              vendor.vendorname.toLowerCase().includes(this.searchQuery!.toLowerCase())
            );
          } 
      }
      
      
    }
  
     
    
    // Compare countries
    compareCountries(c1: CountryData, c2: CountryData): boolean {
      return c1 && c2 ? c1.idcountry === c2.idcountry : c1 === c2;
    }
  
  
  
    // Compare vendors
    compareVendors(v1: VendorSummaryData, v2: VendorSummaryData): boolean 
    {
      return v1 && v2 ? v1.idvendor === v2.idvendor : v1 === v2;
    }
  
  }
  
