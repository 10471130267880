<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
<!-- Include Bootstrap CSS -->  
<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">  

<div> 
  <!-- Dialog Header --> 
  <div class="modal-header border-3" style="background-color: rgb(252,203,17); border-radius: 10px 10px 0 0;"> 
    <h3 class="modal-title">Please sign in to proceed</h3> 
    <button mat-icon-button (click)="closeDialog()" style="float: right; background-color: white; border-radius: 50%;"> 
      <mat-icon>close</mat-icon> 
    </button> 
  </div> 

  <!-- Dialog Content -->
  <div class="modal-body" style="border-radius: 0 0 10px 10px;">
    <form (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="username">Registered Email</label>
        <input type="text" class="form-control" id="username" [(ngModel)]="username" name="username" required>
      </div>

      <div class="form-group position-relative">
        <label for="password">Password</label>
        <input [type]="showPassword ? 'text' : 'password'" class="form-control" id="password" [(ngModel)]="password" name="password" required>
        <button type="button" (click)="togglePasswordVisibility()" class="btn btn-outline-secondary toggle-password position-absolute" style="right: 10px; top: 35%;">
          <i [class]="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
        </button>
      </div>

      <div class="text-center">
        <button type="submit" class="btn btn-warning btn-block">Sign in</button>
      </div>
    </form>

    <p class="p-1 text-danger">{{errorMessage}}</p>

    <div class="mt-3 text-center">
      <a class="clickable" (click)="registerUser()">New User? Register here</a>
    </div>

    <div class="mt-3 text-center">
      <a class="clickable" (click)="forgotPassword()">Forgot password</a>
    </div>

    <div class="row p-2">
      <div class="col-md-5"></div>
      <div class="col-md-2 text-center">or</div>
      <div class="col-md-5"></div>
    </div>

  <!-- Google Sign-In and Facebook Sign-In Buttons -->
<div class="d-flex flex-column align-items-center mt-3">
  <!-- Google Sign-In Button -->
  <div id="google-signin-button" class="google-btn mb-2 d-flex align-items-center justify-content-center">
    <i class="fab fa-google mr-2"></i> Sign In with Google
  </div>

  
  <!-- Facebook Sign-In Button  
  <button class="btn btn-primary btn-block mb-1 mt-3" (click)="signInWithFacebook()">
    <i class="fab fa-facebook-f mr-2"></i> Sign In with Facebook
  </button>
-->
  </div>
</div>

<!-- Include Bootstrap JS and dependencies -->
<script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script> 
<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js"></script>   
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
