import { Component,OnInit } from '@angular/core';
import { GobackComponent } from '../../goback/goback.component';
import { Mailcontent, TransactionData } from '../../../models/datamodels';
import { VendorDataServiceService } from '../../../services/vendor-data-service.service';
import { CommonModule,Location } from '@angular/common';
import { Router } from '@angular/router';


@Component({
  selector: 'app-viewreceiptdetails',
  standalone: true,
  imports: [GobackComponent,CommonModule],
  templateUrl: './viewreceiptdetails.component.html',
  styleUrl: './viewreceiptdetails.component.css'
})


export class ViewreceiptdetailsComponent implements OnInit
{
  currentTransaction:TransactionData|null=null;
  mailedTransaction:TransactionData|null=null;
  isMailed:boolean=false;
  

  begdate:string|null=null;
  enddate:string|null=null;
  transactiondate:string|null=null;
  duedate:string|null=null;

  isNotSakhi:boolean=false;

  constructor(private vendorDataService:VendorDataServiceService,private router:Router){}

  ngOnInit(): void 
  {
      this.isNotSakhi=true;
      this.isMailed=false;

      this.vendorDataService.currentTransaction.subscribe(data=>
      {
          this.currentTransaction=data;          

          console.log('Current Transaction :',this.currentTransaction);

          this.begdate=this.convertUTCToLocal(this.currentTransaction?.bookingstart!);
          this.enddate=this.convertUTCToLocal(this.currentTransaction?.bookingend!);
          this.transactiondate=this.convertUTCToLocal(this.currentTransaction?.transactiondate!);
          this.duedate=this.convertUTCToLocal(this.currentTransaction?.balanceduedate!);

          if(this.currentTransaction?.vendorname=='Sakhi Service')
          {
            this.isNotSakhi=false;
          }


          this.vendorDataService.mailedTransaction.subscribe(mdata=>
          {

            this.mailedTransaction=mdata;


            if((this.mailedTransaction) && (this.currentTransaction?.idtransaction == this.mailedTransaction?.idtransaction))
            {
              this.isMailed=true;
              
            }

            console.log('isMailed :',this.isMailed);
        
            if (!this.isMailed)
            {

                let content = '<div style="text-align: center;">';
                content += '<img src="https://soulswed.com/assets/images/Logo/Logo2.jpeg" alt="SoulsWed " style="margin-bottom: 20px;">'; // Adjust the size if necessary
                content += '<div style="text-align: center; color: #f08401;"><h2>Receipt from SoulsWed</h2></div><br><br>';
          
                content += '<div style="text-align: center; margin: 0 auto;">'; // Keep this div centered for overall alignment
          
                // Adding a table with borders
                content += '<table style="margin: 0 auto; text-align: left; border: 1px solid black; border-collapse: collapse;">'; // Center the table and left-align text inside
                content += '<tr><td style="border: 1px solid black; padding: 8px;">Receipt No: </td><td style="border: 1px solid black; padding: 8px;">' + this.currentTransaction?.idtransaction.toString() + '</td></tr>';
               
                if(this.isNotSakhi)
                {
                  content += '<tr><td style="border: 1px solid black; padding: 8px;">Vendor Booked: </td><td style="border: 1px solid black; padding: 8px;">' + this.currentTransaction?.vendorname + '</td></tr>';
                  content += '<tr><td style="border: 1px solid black; padding: 8px;">Booking Start Date: </td><td style="border: 1px solid black; padding: 8px;">' + this.begdate + '</td></tr>';
                  content += '<tr><td style="border: 1px solid black; padding: 8px;">Booking End Date: </td><td style="border: 1px solid black; padding: 8px;">' + this.enddate + '</td></tr>';
                }
                else
                {
                  content += '<tr><td style="border: 1px solid black; padding: 8px;">Service Booked: </td><td style="border: 1px solid black; padding: 8px;">' + this.currentTransaction?.vendorname + '</td></tr>';
                }
                
                
                content += '<tr><td style="border: 1px solid black; padding: 8px;">Customer Name: </td><td style="border: 1px solid black; padding: 8px;">' + this.currentTransaction?.username + '</td></tr>';
                content += '<tr><td style="border: 1px solid black; padding: 8px;">Customer Email: </td><td style="border: 1px solid black; padding: 8px;">' + this.currentTransaction?.useremail + '</td></tr>';
                content += '<tr><td style="border: 1px solid black; padding: 8px;">Amount paid: </td><td style="border: 1px solid black; padding: 8px;">' + this.currentTransaction?.currency + this.currentTransaction?.paidamount.toFixed(2).toString() + '</td></tr>';
               
                if(this.isNotSakhi && this.currentTransaction?.balanceamount!>0)
                {
                  content += '<tr><td style="border: 1px solid black; padding: 8px;">Balance amount to pay: </td><td style="border: 1px solid black; padding: 8px;">' + this.currentTransaction?.currency + this.currentTransaction?.balanceamount.toFixed(2).toString() + '</td></tr>';
                  content += '<tr><td style="border: 1px solid black; padding: 8px;">Due date: </td><td style="border: 1px solid black; padding: 8px;">' + this.duedate + '</td></tr>';
                }
                
                content += '<tr><td style="border: 1px solid black; padding: 8px;">Transaction Date:</td><td style="border: 1px solid black; padding: 8px;">' + this.transactiondate + '</td></tr>';
                content += '</table>';

                content += '<br><br>Please reach out to info@soulswed.com for any queries';

                content += '</div>'; // Close centered div

          

                const mailContent:Mailcontent=
                {
                    email:this.currentTransaction?.useremail!,
                    htmlcontent:content
                }

                this.vendorDataService.sendReceiptMail(mailContent);
                this.vendorDataService.changeMailedTransactionData(this.currentTransaction!);

            }

          })

          
      })

      //const content = document.getElementById('content-to-print')!.innerHTML;

     
  }

   // Method to print the page
   printPage() 
   {
    window.print();
  }

  convertUTCToLocal(utcDateTime: string): string 
  {
    const utcDate = new Date(utcDateTime);
    return utcDate.toLocaleString(); // Converts back to the local time zone
  }


  goToHome()
  {
    this.router.navigate(['/home']);
  }

}
