<div class="container">
    <div class="card" (click)="goToDecorators()">
        <!-- <div class="tag">Great Offers !!!</div> -->
        <h3>Let's bring your vision to life</h3>
        <img src="../../../assets/images/newdesign/decorators.jpg" alt="Wedding Decoration">
        <button class="button" (click)="goToDecorators()">Explore Decorators →</button>
        
    </div>
    <div class="card" (click)="goToVenues()">
        <!-- <div class="tag">Great Offers !!!</div> -->
        <h3>Transforming Spaces with Elegance</h3>
        <img src="../../../assets/images/newdesign/venue.jpg" alt="Wedding Venue">
       
        <button class="button" (click)="goToVenues()">Explore Venues →</button>
    </div>
    <div class="card"  (click)="goToSakhi()">
        <!-- <div class="tag">Great Offers !!!</div> -->
        <h3>End-to-End Event Management</h3>
      <!--  <img src="../../../assets/images/newdesign/f_7_e_3567_c_491_e_85_ff_39_f_9_a_2_f_52_c_7333471.png" alt="Wedding Couple"> -->
      <img src="../../assets/images/Sakhi/sakhi4.png" alt="Wedding Couple">
        <button class="button" (click)="goToSakhi()">Explore Sakhi →</button>
    </div>
</div>

