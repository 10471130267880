<div class="full-width-wrapper">
    <div class="container-fluid ">


        <app-header2></app-header2>

        <!--
    <div class=" d-block d-md-none">
       <div class="row pt-16 pb-1">
          <img mat-card-image src="../../assets/images/PageImages/venues.jpg">
       </div>
    </div>
 
    <div class=" d-none d-md-block">
       <div class="row pb-3">
          <img mat-card-image src="../../assets/images/PageImages/venues.jpg">
       </div>
    </div>
 
    -->

        <div class="d-block d-md-none">
            <div class="row pt-16 pb-1">
                <video mat-card-image autoplay muted class="custom-video-mobile">
                    <source src="../../assets/videos/PageVideos/venues.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>

        <div class="d-none d-md-block">
            <div class="row pb-3">
                <video mat-card-image autoplay muted class="custom-video">
                    <source src="../../assets/videos/PageVideos/venues.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>



        <app-toppickslisting></app-toppickslisting>
    

            <app-footersection></app-footersection>


    </div>
</div>