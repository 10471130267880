<div class="container">

  <!-- For larger screens -->
  <div class="d-none d-md-block row justify-center mr-3">
    <!-- Container for the search form -->
    <div class="search-box-container">
      <div class="search-box d-flex flex-column p-3 border rounded">
        <!-- Horizontal alignment for the dropdowns -->
        <div class="row mb-3">
          <!-- Category Dropdown -->
          <div class="col-3 d-flex flex-column align-items-center">
            <label for="categorySelect" class="form-label soulsWedSearchFont">Category</label>
            <select class="form-select rounded-select" id="categorySelect" [(ngModel)]="selectedCategory" (ngModelChange)="onCategorySelect($event)" [compareWith]="compareCategories">
              <option *ngFor="let category of categoriesFiltered" [ngValue]="category">{{ category.categoryName }}</option>
            </select>
          </div>
  
          <!-- Country Dropdown -->
          <div class="col-3 d-flex flex-column align-items-center">
            <label for="countrySelect" class="form-label soulsWedSearchFont">Country</label>
            <select class="form-select rounded-select" id="countrySelect" [(ngModel)]="selectedCountry" (ngModelChange)="onCountrySelect($event)" [compareWith]="compareCountries">
              <option value=""></option>
              <option *ngFor="let country of countriesFiltered" [ngValue]="country">{{ country.countryName }}</option>
            </select>
          </div>
  
          <!-- City Dropdown -->
          <div class="col-3 d-flex flex-column align-items-center">
            <label for="citySelect" class="form-label soulsWedSearchFont">City</label>
            <select class="form-select rounded-select" id="citySelect" [(ngModel)]="selectedCity" (ngModelChange)="onCitySelect($event)" [compareWith]="compareCities">
              <option value=""></option>
              <option *ngFor="let city of citiesFiltered" [ngValue]="city">{{ city.cityname }}</option>
            </select>
          </div>
  
          <!-- Name Dropdown -->
          <div class="col-3 d-flex flex-column align-items-center">
            <label for="vendorName" class="form-label soulsWedSearchFont2">Name</label>
            <select class="form-select rounded-select" id="vendorName" [(ngModel)]="selectedVendor" (ngModelChange)="onVendorSelect($event)" [compareWith]="compareVendors">
              <option value=""></option>
              <option *ngFor="let vendor of vendorsListFiltered" [ngValue]="vendor">{{ vendor.vendorname }}</option>
            </select>
          </div>
        </div>
  
        <!-- Text field and search button aligned horizontally -->
        <div class="row mb-3">
          <div class="col-8 d-flex flex-column ">
            <label for="search-input" class="soulsWedSearchFont2">Search your favourite vendor</label>
            <input
              class="rounded-select"
              id="search-input"
              type="text"
              [(ngModel)]="searchQuery"
              (input)="searchVenuesByName()"
              (focus)="showDropdown = true"
            />
        
            <!-- Dropdown to show filtered vendor names -->
            <ul class="dropdown" *ngIf="showDropdown && filteredVendors.length > 0">
              <li *ngFor="let vendor of filteredVendors" (click)="selectVendor(vendor)">
                {{ vendor.vendorname }}
              </li>
            </ul>
          </div>
          <div class="col-2 d-flex align-items-end justify-content-center">
            <!-- Search Button with margin adjustment -->
            <button class="button-style-7 ml-3" (click)="search()">Search</button>
          </div>

          <div class="col-2 d-flex align-items-end justify-content-center">
            <!-- Search Button with margin adjustment -->
            <button class="button-style-7 ml-3" (click)="clearFilters()">Clear</button>
          </div>

        </div>
  
        <!-- Error Message -->
        <div class="row mt-3">
          <div class="col-md-2"></div>
          <div class="col-md-8 text-red-800 mb-0 pb-0 bg-yellow-200">
            {{errorMessage}}
          </div>
          <div class="col-md-2"></div>
        </div>
      </div> <!-- End of search-box -->
    </div> <!-- End of search-box-container -->
  </div>  <!-- End of d-none d-md-block -->
  
  
    <!-- For smaller screens -->
    <div class="d-block d-md-none row flex justify-center py-1" style="text-align: center">
      <div class="col-12">
  
          <!-- Category Dropdown -->
        <div class="col-12">
            <label for="categorySelect" class="form-label soulsWedSearchFontSmall">Category</label>
            <select class="form-select rounded-select" id="categorySelect" [(ngModel)]="selectedCategory" (ngModelChange)="onCategorySelect($event)" [compareWith]="compareCategories">
              <option *ngFor="let category of categoriesFiltered" [ngValue]="category">{{ category.categoryName }}</option>
            </select>
        </div>
      
        <div class="row pt-3">
        <!-- Country Dropdown -->
        <div class="col-12">
          <label for="countrySelect" class="form-label soulsWedSearchFontSmall">Country</label>
          <select class="form-select rounded-select" id="countrySelect" [(ngModel)]="selectedCountry" (ngModelChange)="onCountrySelect($event)" [compareWith]="compareCountries">
            <option value=""></option>
            <option *ngFor="let country of countriesFiltered" [ngValue]="country">{{ country.countryName }}</option>
          </select>
        </div>
  
        <!-- City Dropdown -->
        <div class="col-12 pt-3">
          <label for="citySelect" class="form-label soulsWedSearchFontSmall">City</label>
          <select class="form-select rounded-select" id="citySelect" [(ngModel)]="selectedCity" (ngModelChange)="onCitySelect($event)" [compareWith]="compareCities">
            <option value=""></option>
            <option *ngFor="let city of citiesFiltered" [ngValue]="city">{{ city.cityname }}</option>
          </select>
        </div>
      </div>
  
      
  
      <div class="row pt-3">
        <!-- Name Dropdown -->
        <div class="col-12">
          <label for="vendorName" class="form-label soulsWedSearchFontSmall">Name</label>
          <select class="form-select rounded-select" id="vendorName" [(ngModel)]="selectedVendor" (ngModelChange)="onVendorSelect($event)" [compareWith]="compareVendors">
            <option value=""></option>
            <option *ngFor="let vendor of vendorsListFiltered" [ngValue]="vendor">{{ vendor.vendorname }}</option>
          </select>
        </div>
      </div>
  
      
    <!-- Search your favourite vendor Label and Textbox -->
    <div class="row pt-3">
      <div class="col-12">
        <label for="search-input-mobile" class="soulsWedSearchFontSmall">Search your favourite vendor</label>
        <input
          class="rounded-select"
          id="search-input-mobile"
          type="text"
          [(ngModel)]="searchQuery"
          (input)="searchVenuesByName()"
          (focus)="showDropdown = true"
        />
    
        <!-- Dropdown to show filtered vendor names -->
        <ul class="dropdown" *ngIf="showDropdown && filteredVendors.length > 0">
          <li *ngFor="let vendor of filteredVendors" (click)="selectVendor(vendor)">
            {{ vendor.vendorname }}
          </li>
        </ul>
      </div>
    </div>
  
      <div class="row pt-4">
        <!-- Search Button -->
        <div class="col-12">
          <button class="button-style-2" (click)="search()">Search</button>
        </div>  
      </div>

      <div class="row pt-4">
        <!-- Search Button -->
        <div class="col-12">
          <button class="button-style-2" (click)="clearFilters()">Clear</button>
        </div>  
      </div>
  
      <!-- Error Message -->
      <div class="row mt-3">
        <div class="col-2"></div>
        <div class="col-8 text-red-800 mb-0 pb-0 bg-yellow-200">
          {{errorMessage}}
        </div>
        <div class="col-2"></div>
      </div>
    </div>
  </div>
  