<div class="box">
    <div class="dialog-container">
        <!-- Close Button -->
        <button class="dialog-close-btn" (click)="closeDialog()">×</button>

        <h2 class="dialog-heading">Having Doubts?</h2>
        <p>We’re here to help! Reach out to us via email or chat.</p>

        <div class="dialog-actions">
            <!-- Email button -->
            <a class="dialog-button email" (click)="sendEmail()">
                <i class="fas fa-envelope"></i> Email Us
            </a>

            <!-- Chat button -->
            <a class="dialog-button" (click)="startChat()">
                <i class="fab fa-whatsapp"></i> Chat with Us
            </a>
        </div>
    </div>
</div>