<head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
</head>
<div class="d-none d-md-block banner-section" style="margin-bottom: 0px;">
    <img src="../../assets/images/newdesign/headerbanner.png" alt="Banner" class="banner-image">
</div>
<div class="d-block d-md-none banner-sm" style="position: relative;">
    <img  src="../../../assets/images/newdesign/headerbanner.png" alt="">
    </div>
<!-- For large screens -->
<div class="d-none d-md-block row footer-gradient-style ">

 

        <div class="row pt-4" > 
            <!-- Logo Section -->
            <div class="col-md-2">
                <div class="mb-3"style="margin-left: 50px;">
                    <img src="../../assets/images/newdesign/footerlogo.png" alt="Logo" class="footer-logo" style="margin-top: 50px;"/>
                </div>
            </div>
    
            <!-- Contact Us Section -->
            <div class="col-md-2" style="margin-left: 80px;">
                <div class="pb-3" style="color: white; font-size: 17px; font-weight: 200px;">Contact Us</div>
                <div>
                    <a href="mailto:info@soulswed.com">
                        <i class="fas fa-envelope fa-lg email-icon"></i>
                    </a>
                </div>
                <div class="pt-3">
                    <a href="https://wa.me/919445266640?text=Hello%20there!%20I%20would%20like%20to%20inquire%20about...">
                        <i class="fab fa-whatsapp fa-lg whatsapp-icon"></i>
                    </a>
                </div>
            </div>
    
            <!-- Categories Section -->
            <div class="col-md-2" style="margin-left: 40px;">
                <div class="pb-2" style="color: white;font-size: 17px; font-weight: 200px;">Categories</div>
                <div class="clickable  soulsWedFooterFont" (click)="goToVenues()">Venues</div>
                <div class="clickable  soulsWedFooterFont" (click)="goToPlanners()">Planners</div>
                <div class="clickable soulsWedFooterFont" (click)="goToPhotograhers()">Photographers</div>
                <div class="clickable soulsWedFooterFont" (click)="goToMakeupArtists()">Makeup Artists</div>
                <div class="clickable soulsWedFooterFont" (click)="goToDecorators()">Decorators</div>
            </div>
    
            <!-- Services Section -->
            <div class="col-md-2" style="margin-left: 50px;">
                <div class="pb-2" style="color: white;font-size: 17px; font-weight: 200px;">Services</div>
                <div class="clickable soulsWedFooterFont" (click)="goToSakhiService()">Sakhi Service</div>
                <div class="pt-1 clickable soulsWedFooterFont" (click)="goToUserFavourites()">View Favourites</div>
            </div>
    
            <!-- Documents Section -->
            <div class="col-md-2" style="margin-left: 50px;">
                <div class="pb-2" style="color: white; font-size: 17px; font-weight: 200px;">Documents</div>
                <div class="clickable soulsWedFooterFont" (click)="gotoTermsandConditions()">Terms & Conditions</div>
                <div class="pt-1 clickable soulsWedFooterFont" (click)="gotoPrivacyPolicy()">Privacy Policy</div>
                <div class="pt-1 clickable soulsWedFooterFont" (click)="gotoRefundPolicy()">Refund Policy</div>
                <div class="pt-1 clickable soulsWedFooterFont" (click)="gotoUserdeletionPolicy()">User Deletion Policy</div>
            </div>
    

        <div class="col-md-1"></div>
    </div>

    <!-- Copyright Section centered at the bottom -->
    <div class="row pt-6" >
        <div class="col-md-2"></div>
        <div class="col-md-8 d-flex justify-content-center">
            <p class="soulsWedFooterFont text-center">Created with <span style=" font-size: 20px;">&#9829;</span> by SoulsWed</p>
        </div>
        <div class="col-md-2"></div>
    </div>
</div>

<!-- For smaller screens -->
<div class="d-block d-md-none row footer-gradient-style px-4">

    <div class="row pt-4">
        <div class="col-1"></div>
        <div class="col-7">
            <div class="clickable soulsWedFooterFont" (click)='gotoPrivacyPolicy()'>Privacy Policy</div>
            <div class="clickable soulsWedFooterFont pt-1" (click)='gotoRefundPolicy()'>Refund Policy</div>
            <div class="clickable soulsWedFooterFont pt-1" (click)='gotoTermsandConditions()'>Terms & Conditions</div>
            <div class="clickable soulsWedFooterFont pt-1" (click)='gotoUserdeletionPolicy()'>User Deletion Policy</div>
        </div>

        <div class="col-2"></div>
        <div class="col-2">
            <div>
                <a href="mailto:info@soulswed.com">
                    <i class="fas fa-envelope fa-2x email-icon"></i>
                </a>
            </div>
            <div class="pt-3">
                <a href="https://wa.me/919445266640?text=Hello%20there!%20I%20would%20like%20to%20inquire%20about...">
                    <i class="fab fa-whatsapp fa-2x whatsapp-icon"></i>
                </a>
            </div>
        </div>
    </div>

    <div class="row pt-6">
        <div class="col-12 d-flex justify-content-center">
            <p class="soulsWedFooterFont text-center">Created with <span style=" font-size: 20px;">&#9829;</span> by SoulsWed</p>
        </div>
    </div>
</div>
