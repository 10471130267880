import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { Router, RouterModule } from '@angular/router';
import { CategoryData, CountryData, CurrencyData, SearchData } from '../../models/datamodels';
import { EnvironmentDataServiceService } from '../../services/environment-data-service.service';
import { FilterDataServiceService } from '../../services/filter-data-service.service';
import { MasterdataserviceService } from '../../services/masterdataservice.service';
import { SignInComponent } from '../usermgmt/sign-in/sign-in.component';



@Component({
  selector: 'app-header2',
  standalone: true,
  imports: [RouterModule,MatMenuTrigger,MatMenu,CommonModule,MatIconModule,FormsModule],
  templateUrl: './header2.component.html',
  styleUrl: './header2.component.css'
})


export class Header2Component implements OnInit
{


  constructor(private envData:EnvironmentDataServiceService,public dialog: MatDialog,private router:Router,private masterDataService:MasterdataserviceService,private filterservicedata:FilterDataServiceService){}

  storedCurrencyData:CurrencyData|null=null;
  selectedCurrencyData:CurrencyData|null=null;
  selectedCurrency: string|null = null; // Default currency
  userName:string='Sign in';// Logged in User
  usertype:string='';
  isCustomer:boolean=false;
  isVendor:boolean=false;
  isEmployee:boolean=false;
  isAdmin:boolean=false;
  showScroll:boolean=true;
  


  categoriesList:CategoryData[]=[];
  countriesList:CountryData[]=[];
  currencyList:CurrencyData[]=[];
  userCountry:string='';
  userCurrency:string|null=null;

  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  

  ngOnInit(): void 
  {

    this.isCustomer=false;
    this.isVendor=false;
    this.isEmployee=false;
    this.isAdmin=false;

    const sessionData = this.getSessionData<boolean>('showScroll');
    this.showScroll = sessionData !== null ? sessionData : true;

    
    //this.categoriesList=this.masterDataService.getCategories();
    this.masterDataService.getCategories().subscribe
    (
     data=>this.categoriesList=data
    );

    //this.categoriesList=this.masterDataService.getCategories();
    this.masterDataService.getCountries().subscribe
    (
     data=>this.countriesList=data
    );

    //---------- Get list of currencies ---------------------------
    this.masterDataService.getCurrencies().subscribe
    (data=>

      {
          this.currencyList=data;
          this.storedCurrencyData=this.envData.getSessionData<CurrencyData>('currentCurrencyData');
          
          if(!this.storedCurrencyData)
          {

              fetch('https://ipapi.co/json/')
                .then(response => response.json())
                .then(data => 
               {
                  this.userCountry = data.country;
                  this.userCurrency = <string>data.currency;
               
                  // convertPrices(userCurrency);
                  console.log('Location fetched from ipapi:',this.userCountry);
                  console.log('Currency fetched from ipapi:',this.userCurrency);

                  this.selectedCurrencyData=this.currencyList.find(c=>c.currencycode==this.userCurrency)!;
                   this.envData.changeCurrencyData(this.selectedCurrencyData);

                  this.selectedCurrency=this.selectedCurrencyData.currencycode;
   
   
              })
              .catch(error => console.log('Error fetching user location:', error));
          }
          else
          {
            this.selectedCurrencyData=this.currencyList.find(c=>c.currencycode==this.storedCurrencyData?.currencycode)!;
            this.envData.changeCurrencyData(this.selectedCurrencyData);
            this.selectedCurrency=this.selectedCurrencyData.currencycode;
          }

   
          
      });


    //------- Sbscribe for user login ---------------

    this.envData.currentUser.subscribe(data=>
      {
        console.log('Change user in header invoked with data :',data);

        if (data)
          {

            console.log('Inside setting user settings');
      
            this.userName=data.username;

         
            this.usertype=data.usertype;

            if(this.usertype=='Vendor')
                this.isVendor=true;
            else if(this.usertype=='Customer')
                this.isCustomer=true;
            else if(this.usertype=='Employee')
                this.isEmployee=true;
            else if(this.usertype=='Admin')
                this.isAdmin=true;

           
          }
      }

    )


    
        
      
  }

  public getSessionData<T>(key: string): T | null 
  {
    const data = sessionStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }

  private setSessionData<T>(key: string, data: T) 
  {
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  closeScroll()
  {
    this.showScroll=false;
    this.setSessionData('showScroll', this.showScroll);
  }

  viewScroll()
  {
    this.showScroll=true;
    this.setSessionData('showScroll', this.showScroll);
  }

  setCurrency(currency: string): void 
  {
    this.selectedCurrency = currency;
  }

  openSignInDialog() 
  {
    if(this.userName=='Sign in')
    {
      const dialogRef = this.dialog.open(SignInComponent, {
        width: '300px'
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });

    }
    else
    {
      alert('User already logged in');
      this.trigger.openMenu();
    }
    
  }


  logOff()
  {
    this.router.navigate(['/home']);
    this.envData.changeUserData(null);
    this.userName='Sign in';

    this.isCustomer=false;
    this.isVendor=false;
    this.isEmployee=false;
    this.isAdmin=false;

      

  }

  changePassword()
  {

  }

  //----------Functions for menu items ------------------------

  goToVenues()
  {

    const data : SearchData=
    {
      country: null,//this.countriesList[0],
      category: <CategoryData>this.categoriesList.find(c=>c.idcategory=3),
      city:null,vendor:null

    };   

    this.envData.setSessionData('savedCountry', null);
    this.envData.setSessionData('savedCity', null);
    this.envData.setSessionData('savedVendor', null);
    this.envData.setSessionData('savedSearchQuery', null);
    this.envData.setSessionData('savedShowSearch', null);
    
      
    this.filterservicedata.changeData(data);
    this.router.navigate(['/venues']);

    
  }

  goToHotels()
  {
       const data : SearchData=
       {
        country: null,//this.countriesList[0],,
        category: <CategoryData>this.categoriesList.find(c=>c.idcategory=4),
         city:null,
         vendor:null
   
       };

    this.envData.setSessionData('savedCountry', null);
    this.envData.setSessionData('savedCity', null);
    this.envData.setSessionData('savedVendor', null);
    this.envData.setSessionData('savedSearchQuery', null);
    this.envData.setSessionData('savedShowSearch', null);
          
         
       this.filterservicedata.changeData(data);
   
       this.router.navigate(['/hotels']);
  }

  goToPlanners()
  {

    console.log('Planners clicked');

    const data : SearchData=
    {
      country: null,//this.countriesList[0],,
      category: <CategoryData>this.categoriesList.find(c=>c.idcategory=5),
      city:null,
      vendor:null

    };       

    this.envData.setSessionData('savedCountry', null);
    this.envData.setSessionData('savedCity', null);
    this.envData.setSessionData('savedVendor', null);
    this.envData.setSessionData('savedSearchQuery', null);
    this.envData.setSessionData('savedShowSearch', null);
      
    this.filterservicedata.changeData(data);
    this.router.navigate(['/planners']);
  }

  goToCaterers()
  {

    console.log('Caterers clicked');

    const data : SearchData=
    {
      country: null,//this.countriesList[0],,
      category: <CategoryData>this.categoriesList.find(c=>c.idcategory=6),
      city:null, 
      vendor:null

    };    
    
    this.envData.setSessionData('savedCountry', null);
    this.envData.setSessionData('savedCity', null);
    this.envData.setSessionData('savedVendor', null);
    this.envData.setSessionData('savedSearchQuery', null);
    this.envData.setSessionData('savedShowSearch', null);
      
    this.filterservicedata.changeData(data);
    this.router.navigate(['/caterers']);
  }


  goToCharters()
  {
    const data : SearchData=
    {
      country: null,//this.countriesList[0],,
      category: <CategoryData>this.categoriesList.find(c=>c.idcategory=9),
      city:null,
      vendor:null

    };  
    
    this.envData.setSessionData('savedCountry', null);
    this.envData.setSessionData('savedCity', null);
    this.envData.setSessionData('savedVendor', null);
    this.envData.setSessionData('savedSearchQuery', null);
    this.envData.setSessionData('savedShowSearch', null);
      
    this.filterservicedata.changeData(data);
    this.router.navigate(['/charters']);
  }

  goToDecorators()
  {
    const data : SearchData=
    {
      country: null,//this.countriesList[0],,
      category: <CategoryData>this.categoriesList.find(c=>c.idcategory=9),
      city:null,
      vendor:null

    };     
    
    this.envData.setSessionData('savedCountry', null);
    this.envData.setSessionData('savedCity', null);
    this.envData.setSessionData('savedVendor', null);
    this.envData.setSessionData('savedSearchQuery', null);
    this.envData.setSessionData('savedShowSearch', null);
      
    this.filterservicedata.changeData(data);
    this.router.navigate(['/decorators']);
  }

  goToPhotograhers()
  {
    const data : SearchData=
    {
      country: null,//this.countriesList[0],,
      category: <CategoryData>this.categoriesList.find(c=>c.idcategory=9),
      city:null,
      vendor:null

    };  
    
    this.envData.setSessionData('savedCountry', null);
    this.envData.setSessionData('savedCity', null);
    this.envData.setSessionData('savedVendor', null);
    this.envData.setSessionData('savedSearchQuery', null);
    this.envData.setSessionData('savedShowSearch', null);
      
    this.filterservicedata.changeData(data);
    this.router.navigate(['/photographers']);
  }

  goToMakeupArtists()
  {

    
    const data : SearchData=
    {
      country: null,//this.countriesList[0],,
      category: <CategoryData>this.categoriesList.find(c=>c.idcategory=10),
      city:null,
      vendor:null
    };   
    
    this.envData.setSessionData('savedCountry', null);
    this.envData.setSessionData('savedCity', null);
    this.envData.setSessionData('savedVendor', null);
    this.envData.setSessionData('savedSearchQuery', null);
    this.envData.setSessionData('savedShowSearch', null);
      
    this.filterservicedata.changeData(data);
    this.router.navigate(['/makeupartists']);

  }

  goToUserFavourites()
  {
    this.envData.setSessionData('savedCountry', null);
    this.envData.setSessionData('savedCity', null);
    this.envData.setSessionData('savedVendor', null);
    this.envData.setSessionData('savedSearchQuery', null);
    this.envData.setSessionData('savedShowSearch', null);
    
    this.router.navigate(['/userfavourites']);
  }

  goToUserVendors()
  {
    this.envData.setSessionData('savedCountry', null);
    this.envData.setSessionData('savedCity', null);
    this.envData.setSessionData('savedVendor', null);
    this.envData.setSessionData('savedSearchQuery', null);
    this.envData.setSessionData('savedShowSearch', null);
    
    this.router.navigate(['/uservendor']);
  }

  registerUser()
  {
    this.router.navigate(['/register']);
  }

  onCurrencySelected(currency:CurrencyData)
  {
    this.selectedCurrencyData=currency;
    this.envData.changeCurrencyData(this.selectedCurrencyData);

    this.selectedCurrency=this.selectedCurrencyData.currencycode;

  }

  goToSakhiService()
  {
    this.router.navigate(['/sakhiservice']);
  }

  goToVendorCalendar()
  {
    this.envData.setSessionData('savedCountry', null);
    this.envData.setSessionData('savedCity', null);
    this.envData.setSessionData('savedVendor', null);
    this.envData.setSessionData('savedSearchQuery', null);
    this.envData.setSessionData('savedShowSearch', null);
    
    this.router.navigate(['/uservendorcalendar']);
  }

  goToChangePassword()
  {
    this.router.navigate(['/change-password']);
  }

}
