import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

//--- import project components -----
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { CountryData } from '../../models/datamodels';
import { EnvironmentDataServiceService } from '../../services/environment-data-service.service';
import { MasterdataserviceService } from '../../services/masterdataservice.service';
import { AccordianComponent } from '../accordian/accordian.component';
import { FootersectionComponent } from '../footersection/footersection.component';
import { Header2Component } from '../header2/header2.component';
import { InquiryComponent } from '../inquiry/inquiry.component';
import { SakhibannerComponent } from "../sakhibanner/sakhibanner.component";
import { SakhiserviceComponent } from '../sakhiservice/sakhiservice.component';
import { SearchComponent } from '../search/search.component';
import { SearchmobileComponent } from '../searchmobile/searchmobile.component';
import { ToppicksComponent } from '../toppicks/toppicks.component';
import { CategoriesComponent } from "./categories/categories.component";
import { CharterslistinghomeComponent } from './charterslistinghome/charterslistinghome.component';
import { DecoratorslistinghomeComponent } from './decoratorslistinghome/decoratorslistinghome.component';
import { HotelslistinghomeComponent } from './hotelslistinghome/hotelslistinghome.component';
import { ImagesliderComponent } from './imageslider/imageslider.component';
import { ImgcarouselComponent } from './imgcarousel/imgcarousel.component';
import { MakeupartistslistinghomeComponent } from './makeupartistslistinghome/makeupartistslistinghome.component';
import { OffersComponent } from "./offers/offers.component";
import { PhotographerslistinghomeComponent } from './photographerslistinghome/photographerslistinghome.component';
import { VendorslistingComponent } from './vendorslisting/vendorslisting.component';
import { VenueslistinghomeComponent } from './venueslistinghome/venueslistinghome.component';
import { WeddingstorieslistingComponent } from './weddingstorieslisting/weddingstorieslisting.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [AccordianComponent, SakhiserviceComponent, CommonModule, DecoratorslistinghomeComponent, MakeupartistslistinghomeComponent,InquiryComponent, SearchComponent,ToppicksComponent, HttpClientModule, Header2Component, ImagesliderComponent, VenueslistinghomeComponent, HotelslistinghomeComponent, VendorslistingComponent, WeddingstorieslistingComponent, FootersectionComponent, ImgcarouselComponent, SearchmobileComponent, CharterslistinghomeComponent, PhotographerslistinghomeComponent, CategoriesComponent, OffersComponent, SakhibannerComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})


export class HomeComponent implements OnInit 
{
  @ViewChild('homeContainer', { static: true }) homeContainer?: ElementRef;

  countries:CountryData[]|null=null;
  showSearch:boolean=false;
  searchButtonText:string='Search Vendors';
  featuredButtonText:string='Top Picks';


  // Variables for drag functionality
  isDragging = false;
  offsetX = 0;
  offsetY = 0;
  activeElement: HTMLElement | null = null;

  constructor(private environmentDataService:EnvironmentDataServiceService,private masterDataService:MasterdataserviceService, private router:Router,private elementRef: ElementRef)
  {

  };

  ngOnInit(): void 
  {
      this.initializeComponent();
  }

  initializeComponent()
  {
    this.masterDataService.getCountries().subscribe
    (data=>
      {
        this.countries=data;

       // this.environmentDataService.changeCountryData(this.countries[0]);
  
      }
    );
  }

  handleVisibilityChange() 
  {
    if (this?.homeContainer?.nativeElement.offsetParent !== null) 
      {
        // The component is now visible
        this.initializeComponent();
      }
  }

  enableSearch()
  {
    console.log('Enable Search Clicked');
    this.showSearch=!this.showSearch;

    if(this.showSearch)
      this.searchButtonText="Hide search panel"
    else
      this.searchButtonText="Search Vendors"
    
  }
  goToToppicks()
  {
    this.router.navigate(['/toppicks'])
  }

  // Drag functionality methods
  startDrag(event: MouseEvent | TouchEvent, elementId: string): void {
    this.isDragging = true;
    this.activeElement = this.elementRef.nativeElement.querySelector(`.floating-${elementId}`);

    if (!this.activeElement) return;

    const { clientX, clientY } = this.getPointerEvent(event);
    const rect = this.activeElement.getBoundingClientRect();
    this.offsetX = clientX - rect.left;
    this.offsetY = clientY - rect.top;
  }

  drag(event: MouseEvent | TouchEvent, elementId: string): void {
    if (!this.isDragging || !this.activeElement) return;

    const { clientX, clientY } = this.getPointerEvent(event);

    // Calculate new position
    const x = clientX - this.offsetX;
    const y = clientY - this.offsetY;

    // Update the element's position
    this.activeElement.style.left = `${x}px`;
    this.activeElement.style.top = `${y}px`;
    this.activeElement.style.right = 'auto'; // Reset right to allow positioning
    this.activeElement.style.bottom = 'auto'; // Reset bottom to allow positioning
  }

  stopDrag(): void {
    this.isDragging = false;
    this.activeElement = null;
  }

  private getPointerEvent(event: MouseEvent | TouchEvent): { clientX: number; clientY: number } {
    if (event instanceof MouseEvent) {
      return { clientX: event.clientX, clientY: event.clientY };
    } else {
      const touch = event.touches[0];
      return { clientX: touch.clientX, clientY: touch.clientY };
    }
  }
}
