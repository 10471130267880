import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InquiryDialogComponent } from './inquiry-dialog/inquiry-dialog.component';

@Component({
  selector: 'app-inquiry',
  standalone: true,
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.css'],
})
export class InquiryComponent {
  constructor(private dialog: MatDialog) {}

  openDialog() {
    this.dialog.open(InquiryDialogComponent, {
      width: '400px',
    });
  }
}
