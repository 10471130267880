<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">

<div class="container-fluid">
  <!-- Heading Section -->
  <div class="row pt-3">
    <!-- Desktop View -->
    <div class="d-none d-md-block col-md-12">
      <div class="d-flex justify-content-between align-items-center">
        <div></div>
        <div class="text-center d-flex flex-column" style="margin-right: -250px;">
          <span class="soulsWedHeadingFontalt">Decorators</span>
       
        <div class="soulsWedSubheadingFont pt-3">
          Let's bring your vision to life
        </div>
      </div>
        <div class="d-flex header-menu-wrapper">
          <div class="header-menu ms-auto" style="margin-right: 50px;">
            <p class="clickable soulsWedMenuFontalt" [ngClass]="{'active': currentSection === 'new'}" (click)="selectSection('new')">New</p>
            <p class="clickable soulsWedMenuFontalt ms-3" [ngClass]="{'active': currentSection === 'featured'}" (click)="selectSection('featured')">Featured</p>
            <p class="clickable soulsWedMenuFontalt ms-3" [ngClass]="{'active': currentSection === 'top-rated'}" (click)="selectSection('top-rated')">Top Rated</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile View -->
    <div class="d-block d-md-none">
      <div class="row pt-3">
        <div class="col-12 text-center">
          <span class="soulsWedHeadingFont">Decorators</span>
        </div>
      </div>
      <div class="row pt-3"></div>
      <div class="col-12 text-center">
        <div class="soulsWedSubheadingFont ">
          Let's bring your vision to life
        </div>
      </div>

      <div class="row pt-3">
        <div class="col-12 text-center">
          <!-- Added a container for horizontal alignment -->
          <div class="header-menu-mobile">
            <p class="clickable soulsWedMenuFont" [ngClass]="{'active': currentSection === 'new'}"
              (click)="selectSection('new')">New</p>
            <p class="clickable soulsWedMenuFont" [ngClass]="{'active': currentSection === 'featured'}"
              (click)="selectSection('featured')">Featured</p>
            <p class="clickable soulsWedMenuFont" [ngClass]="{'active': currentSection === 'top-rated'}"
              (click)="selectSection('top-rated')">Top Rated</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4 mx-4 justify-content-center">
    <div class=" col-md-4 mb-4 " *ngFor="let vendor of vendorsList">
      <mat-card class="venue-mat-card">
        <img mat-card-image [src]="serverpath + vendor.imagespath" [alt]="vendor.vendorname" class="venue-image clickable" (click)="onVendorSelect(vendor)" loading="lazy"/>
        <div class="fav-icon-container">
          <div class="col-md-6 text-right" *ngIf="!isFavourite(vendor.idvendor)"><i class="fas fa-heart heart-icon-notfavourite clickable" data-toggle="tooltip" data-placement="top" title="Add to favourites" (click)="toggleFavourites(vendor.idvendor)"></i></div>
          <div class="col-md-6 text-right" *ngIf="isFavourite(vendor.idvendor)"><i class="fas fa-heart heart-icon-favourite clickable" data-toggle="tooltip" data-placement="top" title="Remove from favourites" (click)="toggleFavourites(vendor.idvendor)"></i></div>
        </div>
        <mat-card-content class="venue-info">
          <div class="pt-2 text-left">
            <div class="row">
              <div class="col-md-9">
                <div class="soulsWedSubTitleFont clickable" routerLink="/vendordetails" (click)="onVendorSelect(vendor)">{{vendor.vendorname}}</div>
              </div>
              <div class="col-md-3 text-right">
                <div class="soulsWedStarFont pt-2 clickable" routerLink="/vendordetails" (click)="onVendorSelect(vendor)">{{getStars(vendor.vendorrating)}}</div>
              </div>
            </div>
            <div class="row clickable" routerLink="/vendordetails" (click)="onVendorSelect(vendor)">
              <div class="col-md-12 soulsWedNormalFont pt-1 pb-1">{{vendor.city ? vendor.city + ', ' : ''}}{{getCountryName(vendor.idcountry)}}</div>
            </div>
            <div class="row">
              <div class="col-md-10 soulsWedPriceFont2 pt-2"><b>from {{selectedCurrency?.currencycode}} {{convertCurrency(vendor.currency, selectedCurrency?.currencycode!, vendor.startingprice) | number:'1.0-0'}} {{vendor.pricebasis}}</b></div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="row pl-5 pb-4 d-none d-md-block">
    <div class="row">
      <div class="col-md-10"></div>
      <div class="col-md-2"> 
          <button class="border button-style-2 " type="button" (click)="goToDecorators()" >Search more</button>
       </div>
    </div>
        
  </div>
  
  
  <div class="row pt-2 px-12 d-block d-md-none">
    <div class="row flex justify-center">
        
            <button class="border button-style-2 " type="button" (click)="goToDecorators()" >Search more</button>
  
    </div>
  </div>
  