import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { FootersectionComponent } from '../footersection/footersection.component';
import { GobackComponent } from '../goback/goback.component';
import { Header2Component } from '../header2/header2.component';
import { ToppickslistingComponent } from './toppickslisting/toppickslisting.component';

@Component({
  selector: 'app-toppicks',
  standalone: true,
  imports: [GobackComponent,Header2Component,ToppickslistingComponent, FootersectionComponent,MatCardModule],
  templateUrl: './toppicks.component.html',
  styleUrl: './toppicks.component.css'
})
export class ToppicksComponent {

}
