import { Component,OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EnvironmentDataServiceService } from '../../../services/environment-data-service.service';
import { CategoryData,CountryData, CurrencyData, SideBarFilters,ExchangeRateData } from '../../../models/datamodels';
import { CommonModule } from '@angular/common';
import { FilterDataServiceService } from '../../../services/filter-data-service.service';
import { MasterdataserviceService } from '../../../services/masterdataservice.service';

@Component({
  selector: 'app-listing-sidebar',
  standalone: true,
  imports: [FormsModule,CommonModule],
  templateUrl: './listing-sidebar.component.html',
  styleUrl: './listing-sidebar.component.css'
})


export class ListingSidebarComponent implements OnInit
{

  category:CategoryData|null=null;
  country:CountryData|null=null;
  currency:CurrencyData|null=null;
  fxList:ExchangeRateData[]=[];


  showPriceRange:boolean=true;
  showRatingCheckboxes:boolean=false;
  showCapacityRanges:boolean=false;


    //----- Variables for price range selector --------------------------
    price :number=2000000;
    minValue:number=0;
    maxValue:number=4000000;
    currencyCode:string='INR';
  
      
    //------------ Variables for hotel ratings -------------------
    threeStar: boolean=true;
    fourStar:  boolean=true;
    fiveStar:  boolean=true;
  
  
    
    //------------ Variables for capacity -------------------
    lessthan500: boolean=true;
    between500and1000:  boolean=true;
    between1000nd2000:  boolean=true;
    greaterthan2000:  boolean=true;

  constructor(private environmentDataService:EnvironmentDataServiceService,private filterDataService:FilterDataServiceService ,private masterDataService:MasterdataserviceService) {}

  ngOnInit(): void 
  {
    this.environmentDataService.currentCategory.subscribe(data=>
    {
       this.category=data;
       this.resetFilters();
     })
 
    this.environmentDataService.currentCountry.subscribe(data=>
    {
      this.country=data;
      this.resetFilters();
    })

    this.environmentDataService.currentCurrency.subscribe(data=>
    {
      this.currency=data;
      this.currencyCode=this.currency?.currencycode!;

      this.masterDataService.getExchangeRates().subscribe(data=>
      {
          this.fxList=data;

      });

    }
    )

   
 
  }
 

  onFilterChange(event: any): void 
  {
    console.log("Slider Value: ", event.target.value);
    // You can perform further actions based on the new value here
 
    const data : SideBarFilters=
    {
      minPrice:0,
      maxPrice:this.maxValue,
      threeStar:this.threeStar,
      fourStar:this.fourStar,
      fiveStar:this.fiveStar,
      lessthan500: this.lessthan500,
      between500and1000:  this.between500and1000,
      between1000nd2000:  this.between1000nd2000,
      greaterthan2000:  this.greaterthan2000,
      showPriceRange:this.showPriceRange,
      showRatingCheckboxes:this.showRatingCheckboxes,
      showCapacityRanges:this.showCapacityRanges
    };

    this.filterDataService.changeSidebarData(data);
  
  }

  resetFilters()
  {
     //---------Set side filters for each category ------------

    if (this.category?.categoryName=='Venues / Banquet Halls')
    {
        this.showPriceRange=true;
        this.showCapacityRanges=true;
        this.showRatingCheckboxes=true;
     }

    if (this.category?.categoryName=='Rooms')
    {
        this.showPriceRange=true;
        this.showCapacityRanges=false;
        this.showRatingCheckboxes=true;
    }

    if (this.category?.categoryName==='Wedding Planners / Event Managers')
    {
        this.showPriceRange=true;
        this.showCapacityRanges=false;
        this.showRatingCheckboxes=false;
    }

    if (this.category?.categoryName==='Makeup Artists')
    {
        this.showPriceRange=true;
        this.showCapacityRanges=false;
        this.showRatingCheckboxes=false;
    }

    if (this.category?.categoryName==='Photographers / Videographers')
    {
        this.showPriceRange=true;
        this.showCapacityRanges=false;
        this.showRatingCheckboxes=false;
    }

    if (this.category?.categoryName==='Decorators')
    {
        this.showPriceRange=true;
        this.showCapacityRanges=false;
        this.showRatingCheckboxes=false;
    }

    if (this.category?.categoryName==='Chartered Airlines')
    {
        this.showPriceRange=true;
        this.showCapacityRanges=false;
        this.showRatingCheckboxes=false;
    }


    //--------------Reset the variables to store side filter values ----------------
    this.price=2000000; // change based on logic
    this.minValue=0;
    this.maxValue=4000000;
    
    this.threeStar=true;
    this.fourStar=true;
    this.fiveStar=true;

    this.lessthan500=true;
    this.between500and1000=true;
    this.between1000nd2000=true;
    this.greaterthan2000=true;

    const data : SideBarFilters=
    {
      minPrice:0,
      maxPrice:this.price,
      threeStar:this.threeStar,
      fourStar:this.fourStar,
      fiveStar:this.fiveStar,
      lessthan500: this.lessthan500,
      between500and1000:  this.between500and1000,
      between1000nd2000:  this.between1000nd2000,
      greaterthan2000:  this.greaterthan2000,
      showPriceRange:this.showPriceRange,
      showRatingCheckboxes:this.showRatingCheckboxes,
      showCapacityRanges:this.showCapacityRanges
    };

    this.filterDataService.changeSidebarData(data);


  }

  convertCurrency(fromCurrency:string,toCurrency:string,amount:number):number
  {
    // console.log('Currency conversion from ' ,fromCurrency ,' to ',toCurrency);

       const existingRatetoUSD = this.fxList.find(rate =>
         rate.fromCurrency == fromCurrency && rate.toCurrency == 'USD');


      //   console.log('existingRatetoUSD ' ,existingRatetoUSD , '. Rate :',existingRatetoUSD!.rate);
         

       if (existingRatetoUSD) 
       {
         const existingRatefromUSD = this.fxList.find(rate =>
           rate.fromCurrency == 'USD' && rate.toCurrency == toCurrency);

         //  console.log('existingRatefromUSD ' ,existingRatefromUSD , '. Rate :',existingRatefromUSD!.rate);

           if(existingRatefromUSD)
           {
             return amount*existingRatetoUSD.rate*existingRatefromUSD?.rate!
           }
           else
           return amount;
       
       } 
       else 
       {
           return amount;
           
       }
  }
}
