<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
<div class="container-fluid">
    <div class="row px-4 position-relative pb-2">
        <div class="d-none d-md-block pt-3">
            <div class="col-md-12">
                <span class="flex justify-center soulsWedHeadingFontalt">Wedding Categories</span>
            </div>
            <div class="row py-6" style="height: 130px;">
                <div class="col-1"></div>
                <div class="col-md-5 border-1">
                    <div class="row clickable" (click)="goToVenues()" style="height: 130px; background-color: rgb(246, 211, 86);">
                        <div class="col-md-5 d-flex align-items-center justify-content-center">
                            <h1>Venues</h1>
                        </div>
                        <div class="col-md-7 d-flex align-items-center justify-content-end">
                            <div class="image-container">
                                <img src="../../assets/images/Accordian/Venue.jpg" alt="Venues">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 border-1 ml-2">
                    <div class="row clickable" (click)="goToPlanners()" style="height: 130px; background-color: #EED9C4;">
                        <div class="col-md-5 d-flex align-items-center justify-content-center">
                            <h1>Planners</h1>
                        </div>
                        <div class="col-md-7 d-flex align-items-center justify-content-end">
                            <div class="image-container">
                                <img src="../../assets/images/Accordian/Planners.jpg" alt="Planners">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-1"></div>
            </div>
            <div class="row pt-8" style="height: 130px;">
                <div class="col-1"></div>
                <div class="col-md-5 border-1">
                    <div class="row clickable" (click)="goToPhotograhers()" style="height: 130px; background-color: rgb(192, 242, 247);">
                        <div class="col-md-5 d-flex align-items-center justify-content-center">
                            <h1>Photographers</h1>
                        </div>
                        <div class="col-md-7 d-flex align-items-center justify-content-end">
                            <div class="image-container">
                                <img src="../../assets/images/Accordian/Photographers.jpg" alt="Photographers">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 border-1 ml-2">
                    <div class="row clickable" (click)="goToMakeupArtists()" style="height: 130px; background-color: rgb(246, 211, 86);">
                        <div class="col-md-5 d-flex align-items-center justify-content-center">
                            <h1>Makeup Artists</h1>
                        </div>
                        <div class="col-md-7 d-flex align-items-center justify-content-end">
                            <div class="image-container">
                                <img src="../../assets/images/Accordian/Makeupartists.jpg" alt="Makeup Artists">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-1"></div>
            </div>
            <!-- <div class="row pt-10" style="height: 130px;">
                <div class="col-1"></div>
                <div class="col-md-5 border-1">
                    <div class="row clickable" (click)="goToDecorators()" style="height: 130px; background-color: #EED9C4;">
                        <div class="col-md-5 d-flex align-items-center justify-content-center">
                            <h1>Decorators</h1>
                        </div>
                        <div class="col-md-7 d-flex align-items-center justify-content-end">
                            <div class="image-container">
                                <img src="../../assets/images/Accordian/decorators.jpg" alt="Decorators">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 border-1 pl-2 ml-2">
                    <div class="row clickable" (click)="goToCharters()" style="height: 130px; background-color: rgb(237, 155, 105);">
                        <div class="col-md-5 d-flex align-items-center justify-content-center">
                            <h1>Charters</h1>
                        </div>
                        <div class="col-md-7 d-flex align-items-center justify-content-end">
                            <div class="image-container">
                                <img src="https://images.unsplash.com/photo-1682065079226-fe24e254a9f2?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE3MjYyNDM2NjF8&ixlib=rb-4.0.3&q=85" alt="Charters">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-1"></div>
            </div> -->
        </div>

        <!-- For Smaller Screens -->
        <div class="d-block d-md-none pt-3">
            <div class="col-md-12">
                <span class="flex justify-center soulsWedHeadingFont">Wedding Categories</span>
            </div>
            <div class="row py-6" style="height: 100px;">
                <div class="col-12 border-1">
                    <div class="row clickable" (click)="goToVenues()" style="height: 100px; background-color: rgb(246, 211, 86);">
                        <div class="col-7 d-flex align-items-center justify-content-center">
                            <h1>Venues</h1>
                        </div>
                        <div class="col-5 d-flex align-items-center justify-content-end">
                            <div class="image-container-sm">
                                <img src="../../assets/images/Accordian/Venue.jpg"  alt="Rooms">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row py-6 pt-8" style="height: 100px;">
                <div class="col-12 border-1">
                    <div class="row clickable" (click)="goToPlanners()" style="height: 100px; background-color: #EED9C4;">
                        <div class="col-7 d-flex align-items-center justify-content-center">
                            <h1>Planners</h1>
                        </div>
                        <div class="col-5 d-flex align-items-center justify-content-end">
                            <div class="image-container-sm">
                                <img src="../../assets/images/Accordian/Planners.jpg"  alt="Venues">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row py-6 pt-10" style="height: 100px;">
                <div class="col-12 border-1">
                    <div class="row clickable" (click)="goToPhotograhers()" style="height: 100px; background-color: #a2d2ff;">
                        <div class="col-7 d-flex align-items-center justify-content-center">
                            <h1>Photographers</h1>
                        </div>
                        <div class="col-5 d-flex align-items-center justify-content-end">
                            <div class="image-container-sm">
                                <img src="../../assets/images/Accordian/Photographers.jpg"  alt="Photographers">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row py-6 pt-12" style="height: 100px;">
                <div class="col-12 border-1">
                    <div class="row clickable" (click)="goToMakeupArtists()" style="height: 100px; background-color: #ddbdfc;">
                        <div class="col-7 d-flex align-items-center justify-content-center">
                            <h1>Makeup Artists</h1>
                        </div>
                        <div class="col-5 d-flex align-items-center justify-content-end">
                            <div class="image-container-sm">
                                <img src="../../assets/images/Accordian/Makeupartists.jpg"  alt="Planners">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row py-6 pt-14" style="height: 100px;">
                <div class="col-12 border-1">
                    <div class="row clickable" (click)="goToDecorators()" style="height: 100px; background-color: #fec5bb;">
                        <div class="col-7 d-flex align-items-center justify-content-center">
                            <h1>Decorators</h1>
                        </div>
                        <div class="col-5 d-flex align-items-center justify-content-end">
                            <div class="image-container-sm">
                                <img src="../../assets/images/Accordian/decorators.jpg" class="clickable rounded-left" alt="Decorators">
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>