
// import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
// import {provideHttpClient} from "@angular/common/http";
// import { provideFirebaseApp } from '@angular/fire/app';
// import { getAuth, provideAuth } from '@angular/fire/auth';
// import { initializeApp } from 'firebase/app';



// const firebaseConfig = {
//   apiKey: "AIzaSyAp6v6tggBq6Z_SVVlGqvRnMyzgPEOVa2I",
//   authDomain: "soulswed-7bad8.firebaseapp.com",
//    projectId: "soulswed-7bad8",
//   storageBucket: "soulswed-7bad8.appspot.com",
//   messagingSenderId: "579847020011",
//   appId: "1:579847020011:web:0e075c2c99cfd480668dae"
// };


/*

const firebaseConfig = {
  apiKey: "AIzaSyCPakULA5lO-igHdHxHJfh8vmrs83WqGAY",
  //authDomain: "soulswed-a082a.firebaseapp.com",
  authDomain: "soulswed.com",
  projectId: "soulswed-a082a",
  storageBucket: "soulswed-a082a.appspot.com",
  messagingSenderId: "352252733193",
  appId: "1:352252733193:web:5e4242cf713febb61c55d8",
  measurementId: "G-X6ZYJBQT7E"
};

*/


// export const appConfig: ApplicationConfig = {
//   providers: [provideRouter(routes), 
//     {
//       provide: DateAdapter,
//       useFactory: adapterFactory
//     },
//     {
//       provide: CalendarUtils,
//       useClass: CalendarUtils
//     },
//     provideAnimationsAsync(),provideHttpClient(),provideFirebaseApp(() => initializeApp(firebaseConfig)),
//     provideAuth(() => getAuth())]
// };


import { FacebookLoginProvider, SocialAuthService, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { provideHttpClient } from '@angular/common/http';
import { ApplicationConfig, Injector, NgZone } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { CalendarUtils, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { routes } from './app.routes';

// Google and Facebook OAuth Configurations  
const GOOGLE_CLIENT_ID = '645717240118-rv3utbuphd96h0ss87l4s6e9m542ncnl.apps.googleusercontent.com'; // Replace with your Google OAuth Client ID  
const FACEBOOK_APP_ID = '2007355936380038';  // Replace with your Facebook App ID  

const config: SocialAuthServiceConfig = {
  autoLogin: false, // Set to true if you want to automatically log in users
  providers: [
    // {
    //   id: GoogleLoginProvider.PROVIDER_ID,
    //   provider: new GoogleLoginProvider(GOOGLE_CLIENT_ID, { oneTapEnabled: true }) // Enables Google One Tap
    // },
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider(FACEBOOK_APP_ID)
    }
  ]
};

export const appConfig: ApplicationConfig = { 
  providers: [
    provideRouter(routes), 
    {
      provide: DateAdapter,
      useFactory: adapterFactory
    },
    {
      provide: CalendarUtils,
      useClass: CalendarUtils
    },
    provideAnimations(),
    provideHttpClient(),
    {
      provide: SocialAuthService,
      useFactory: (ngZone: NgZone, injector: Injector) => {
        return new SocialAuthService(config, ngZone, injector);
      },
      deps: [NgZone, Injector]  // Declare dependencies
    } 
  ] 
};
