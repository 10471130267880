import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';


//----import required angular material components -----
import { MatCardModule } from '@angular/material/card';
import { MatDatepicker, MatDatepickerToggle, MatDateRangeInput, MatDateRangePicker } from '@angular/material/datepicker';
import { MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';


//----------import used services ------------------

import { MasterdataserviceService } from '../../../services/masterdataservice.service';
import { SearchComponent } from '../../search/search.component';

 
@Component({
  selector: 'app-imageslider',
  standalone: true,
  imports: [CommonModule,SearchComponent,FormsModule,MatCardModule,MatTabsModule,MatDatepicker,MatFormField,MatLabel,MatDateRangePicker,MatHint,MatDateRangeInput,MatDatepickerToggle],
  templateUrl: './imageslider.component.html',
  styleUrl: './imageslider.component.css'
})


export class ImagesliderComponent implements OnInit
{

  countries :any[] =['United States','India','UK','Australia','Canada'];

  selectedCountry:string;

  constructor (private masterDataService:MasterdataserviceService)
  {
    this.selectedCountry="India";
  }

   ngOnInit(): void 
   {
    /*
      this.masterDataService.getCountries().subscribe(
        {
          next:(data)=>
            {
              this.countries=data;
            },
            error:(err)=>console.error('There was an error: '+err)
        }
      )    
      */
      // Autoplay video when component is initialized
    const video: HTMLVideoElement | null = document.querySelector('.custom-video');
    if (video) {
      // Ensures that the video is muted (which should help bypass autoplay restrictions)
      video.muted = true; 
      video.play();
    }
   } 


}

