
import { CommonModule, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { differenceInDays } from 'date-fns';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { environment } from '../../environments/environment';
import { CharterFaq, CountryData, CurrencyData, DecorFaq, ExchangeRateData, HotelFaq, MakeupArtistFaq, PhotographersFaq, PlannerFaq, TransactionData, UserData, VendorAmenities, VendorDetails, VendorImages, VendorReviews, VendorSummaryData, VendorVideos, VenueFaq } from '../../models/datamodels';
import { EnvironmentDataServiceService } from '../../services/environment-data-service.service';
import { FilterDataServiceService } from '../../services/filter-data-service.service';
import { MasterdataserviceService } from '../../services/masterdataservice.service';
import { VendorDataServiceService } from '../../services/vendor-data-service.service';
import { FootersectionComponent } from '../footersection/footersection.component';
import { GobackComponent } from '../goback/goback.component';
import { Header2Component } from '../header2/header2.component';
import { SignInComponent } from '../usermgmt/sign-in/sign-in.component';
import { PaymodalComponent } from './paymodal/paymodal.component';


@Component({
  selector: 'app-vendordetails',
  standalone: true,
  imports: [FormsModule,GoogleMapsModule,GobackComponent,Header2Component,FootersectionComponent,CommonModule,PdfViewerModule,PaymodalComponent],
  templateUrl: './vendordetails.component.html',
  styleUrl: './vendordetails.component.css'
})


export class VendordetailsComponent implements OnInit 
{
    currentVendor:VendorSummaryData|null=null;    
    currentVendorDetails:VendorDetails|null=null;
    currentUser:UserData|null=null;
    countries :CountryData[]=[]; // Placeholder for countries data
    selectedCountry:CountryData|null=null;
    // Manage active tab here
    activeTab: string = 'description';
    setActiveTab(tab: string): void {
      this.activeTab = tab;
    }
    
    startDateRange :Date|null=null;
    endDateRange:Date|null=null;
    days:number=0;
    guests:number=0;

    imagesPath:string= environment.middletierhost+'/uploads/images/';    
    videosPath:string= environment.middletierhost+'/uploads/videos/';  
    headerImagePath: string = '';                         
    vendorImages:VendorImages[]=[];
    selectedImage: string = '';
    vendorVideos:VendorVideos[]=[];
    reviews:VendorReviews[]=[];
    amenities:VendorAmenities[]=[];
    venueFaq:VenueFaq|null=null;
    plannerFaq:PlannerFaq|null=null;
    hotelsFaq:HotelFaq|null=null;
    photographersFaq:PhotographersFaq|null=null;
    decorFaq:DecorFaq|null=null;
    charterFaq:CharterFaq|null=null;
    makeupartistFaq:MakeupArtistFaq|null=null;
    hasFaqInfo:boolean=false;

    fxList:ExchangeRateData[]=[];
    selectedCurrency:CurrencyData|null=null;
    vendortransactions:TransactionData[]=[];


    basePrice:number=0;
    currency:string='';
    totalBeforeTaxAmount:number=0;
    taxAmount:number=0;
    finalAmount=0;

    advancepercentage:number=10;
    daysprior:number=5;
  

    price_basis:string='';
    isDaysApplicable :boolean=false;
    isGuestsApplicable:boolean=false;
    isEngagemenApplicable:boolean=false;

    showModal = false;

    modalData = 
    {
        baseprice: 100,
        guests: 2,
        days: 3,
        tax: 10,
        pricebasis:'per guest',
        currency:'USD',
        idvendor:0,
        vendorname:'',
        iduser:0,
        username:'',
        useremail:'',
        transactiondate:'',
        amount:0

    };

    

    //------ For Google Map ------------------------------

   display: any;
   center: google.maps.LatLngLiteral = 
   {
         lat: 20.5937,//22.2736308,
         lng: 78.9629 //70.7512555
   };
   zoom = 5;

   showAddBlock:boolean=false;
   showEditBlock:boolean=false;
   reviewText:String='';
   idreview:number=0;

    openModal() 
    {
      this.showModal = true;
    }

    handleModalAction(event: { action: string, data?: any }) 
    {
      if (event.action === 'pay') 
      {
          console.log('Payment processed: ', event.data);
      }
      this.showModal = false;
    }

    constructor(private masterDataService:MasterdataserviceService,private location:Location,private vendorDataService:VendorDataServiceService,private filterDataService:FilterDataServiceService,private envdataservice:EnvironmentDataServiceService,private router:Router,public dialog: MatDialog)
    {}

    ngOnInit(): void 
    {
      this.hasFaqInfo=false;
      this.selectedCountry=null;
      
      this.envdataservice.currentCurrency.subscribe(data=>
        {
              this.selectedCurrency=data; 
              this.currency=this.selectedCurrency?.currencycode!;
    
              this.masterDataService.getExchangeRates().subscribe(data=>
              {
                this.fxList=data;

                this.vendorDataService.currentVendor.subscribe(data=>
                {
                  this.currentVendor=data;
                  this.price_basis=this.currentVendor!.pricebasis;
                  this.basePrice=Math.round(this.convertCurrency(this.currentVendor!.currency,this.currency,this.currentVendor!.startingprice));

                  if(this.currentVendor?.idcategory==3)
                    this.headerImagePath='../../assets/images/PageImages/venues.jpg';
                  else if (this.currentVendor?.idcategory==4)
                    this.headerImagePath='../../assets/images/PageImages/hotels.jpg';
                  else if (this.currentVendor?.idcategory==5)
                    this.headerImagePath='../../assets/images/PageImages/planners.jpg';
                  else if (this.currentVendor?.idcategory==6)
                    this.headerImagePath='../../assets/images/PageImages/caterers.jpg';
                  else if (this.currentVendor?.idcategory==7)
                    this.headerImagePath='../../assets/images/PageImages/photographers.jpg';
                  else if (this.currentVendor?.idcategory==8)
                    this.headerImagePath='../../assets/images/PageImages/decorators.jpg';
                  else if (this.currentVendor?.idcategory==9)
                    this.headerImagePath='../../assets/images/PageImages/charters.jpg';
                  else if (this.currentVendor?.idcategory==10)
                    this.headerImagePath='../../assets/images/PageImages/makeup.jpg';

                                    
                  if(this.currentVendor?.advancepercentage)
                  {
                    this.advancepercentage=this.currentVendor?.advancepercentage;
                  }

                  if(this.currentVendor?.balancepriordays)
                  {
                      this.daysprior=this.currentVendor.balancepriordays;
                  }

                  
                })
               
              })
          });

        //--------------Current vendor data ------------------- 
        this.vendorDataService.currentVendor.subscribe(data=>
          {
            if (data)
              {
                this.currentVendor=data;
                this.imagesPath=this.imagesPath+this.currentVendor.idvendor.toString()+'/';
                this.videosPath=this.videosPath+this.currentVendor.idvendor.toString()+'/';

                this.masterDataService.getCountries().subscribe(countries=>
                {
                  this.countries=countries;
                  this.selectedCountry=null;
                  this.selectedCountry = this.countries.find(c => c.idcountry === this.currentVendor!.idcountry)!;

                  console.log('Selected country :',this.selectedCountry);
                  console.log('Vendor details :',this.currentVendor);

                  if(this.currentVendor!.latitude==0 && this.currentVendor!.longitude==0)
                  {
                      console.log("Inside this.currentVendor.latitude==0 && this.currentVendor.longitude==0")
  
                      this.center=
                      {
                          lat: this.currentVendor?.ctrylat!,
                          lng: this.currentVendor?.ctrylng!
                      }
                  }
                   else
                  {
                      console.log("Inside else of this.currentVendor.latitude==0 && this.currentVendor.longitude==0")
  
                      this.center=
                      {
                          lat: this.currentVendor!.latitude,
                          lng: this.currentVendor!.longitude
                      }
                  }
                  
                })
              }
              else
              {
                
                this.location.back();
              }
          }
        )

        //--------------Current User Data ----------------------
        this.envdataservice.currentUser.subscribe(user=>
          {
            this.currentUser=user;
          }
        )

        if(this.currentVendor)
        {
          
              this.vendorDataService.getVendorDetails(this.currentVendor.idvendor).subscribe
              (
                data=>
                {
                    this.currentVendorDetails=data;
                    console.log('Vendor Details:', this.currentVendorDetails);

                    this.vendorImages = this.currentVendorDetails.vendorImages;
        
        // Use imagename to set the selected image
        this.selectedImage = this.vendorImages.length > 0 ? `${this.imagesPath}${this.vendorImages[0].imagename}` : '';
                    this.vendorVideos=this.currentVendorDetails.vendorVideos;
                    this.reviews=this.currentVendorDetails.vendorReviews;


                    this.venueFaq=this.currentVendorDetails.venueFaq;
                    this.hotelsFaq=this.currentVendorDetails.hotelsFaq;
                    this.plannerFaq=this.currentVendorDetails.plannerFaq;
                    this.photographersFaq=this.currentVendorDetails.photographerFaq;
                    this.decorFaq=this.currentVendorDetails.decorFaq;
                    this.charterFaq=this.currentVendorDetails.charterFaq;
                    this.makeupartistFaq=this.currentVendorDetails.makeupartistFaq;

                    console.log('images:',this.vendorImages);
                    console.log('videos :',this.vendorVideos);
                    console.log('amenities:',this.currentVendorDetails.vendorAmenities);
                    console.log('venue faq:',this.venueFaq);
                    console.log('hotels faq:',this.hotelsFaq);
                    console.log('planner faq:',this.plannerFaq);
                    console.log('photographers faq:',this.photographersFaq);
                    console.log('decorFaq faq:',this.decorFaq);
                    console.log('charterFaq faq:',this.charterFaq);
                    console.log('makeupartistFaq faq:',this.makeupartistFaq);;
                    console.log('hasFaqInfo:',this.hasFaqInfo);


                    if(this.currentVendorDetails.vendorSummary.idcategory==3 && this.venueFaq)
                      this.hasFaqInfo=Object.values(this.venueFaq).some(value =>value &&  value.trim() !== '');                    
                    else if(this.currentVendorDetails.vendorSummary.idcategory==4 && this.hotelsFaq)
                      this.hasFaqInfo=Object.values(this.hotelsFaq).some(value =>value &&  value.trim() !== '');
                    else if(this.currentVendorDetails.vendorSummary.idcategory==5)
                      this.hasFaqInfo=Object.values(this.plannerFaq).some(value =>value &&  value.trim() !== '');
                    else if(this.currentVendorDetails.vendorSummary.idcategory==7)
                      this.hasFaqInfo=Object.values(this.photographersFaq).some(value =>value &&  value.trim() !== '');
                    else if(this.currentVendorDetails.vendorSummary.idcategory==8)
                      this.hasFaqInfo=Object.values(this.decorFaq).some(value =>value &&  value.trim() !== '');
                    else if(this.currentVendorDetails.vendorSummary.idcategory==9)
                      this.hasFaqInfo=Object.values(this.charterFaq).some(value => value && value.trim() !== '');
                    else if(this.currentVendorDetails.vendorSummary.idcategory==10)
                      this.hasFaqInfo=Object.values(this.makeupartistFaq).some(value =>value && value.trim() !== '');

                    console.log('hasFaqInfo after test:',this.hasFaqInfo);



                  


                    this.amenities=this.currentVendorDetails.vendorAmenities.filter
                    (
                      amenity=>amenity.available==true
                    );

                    console.log('filtered amenities:',this.amenities)

                    if(this.price_basis=='Per Day')
                    {
                        this.isDaysApplicable=true;
                        this.totalBeforeTaxAmount=this.basePrice*this.days;
                    }

                    if(this.price_basis=='Per Person')
                    {
                        this.isGuestsApplicable=true;
                        this.totalBeforeTaxAmount=this.basePrice*this.guests;
                    }

                    if(this.price_basis=='Per Engagement')
                    {
                      this.isEngagemenApplicable=true;
                      this.totalBeforeTaxAmount=this.basePrice;
                    }

                    this.calculateFinalAmount();

                  }) 
        }


        

}

    //-------------Functions to implement payment gateway -------------------

    payandbook()
    {
      if(!this.currentUser)
      {
           //-------- If not logged in, ask for user to log-in ------------------
           const dialogRef = this.dialog.open(SignInComponent, 
            {
              width: '300px'
            });
        
            dialogRef.afterClosed().subscribe(result => 
            {
              //this.router.navigate(['/home']);
              console.log('The dialog was closed');
            });
      }
      else
      {

        /*
        this.modalData = 
        {
          baseprice: this.basePrice,
          guests: this.guests,
          days: this.days,
          tax: 18,
          pricebasis:this.price_basis,
          currency:this.currency,
          idvendor:this.currentVendor?.idvendor??0,
          vendorname:this.currentVendor?.vendorname!,
          iduser:this.currentUser.iduser,
          username:this.currentUser.username,
          useremail:this.currentUser.email,
          transactiondate:'',
          amount:0
        };
      
      this.openModal();
      */

      this.router.navigate(['/paynbook']);

      }
      
    }

    

    calculateFinalAmount()
    {
      this.taxAmount=this.totalBeforeTaxAmount*18/100;
      this.finalAmount=this.totalBeforeTaxAmount+this.taxAmount;
    }

    convertCurrency(fromCurrency:string,toCurrency:string,amount:number):number
   {
     // console.log('Currency conversion from ' ,fromCurrency ,' to ',toCurrency);

        const existingRatetoUSD = this.fxList.find(rate =>
          rate.fromCurrency == fromCurrency && rate.toCurrency == 'USD');


          //console.log('existingRatetoUSD ' ,existingRatetoUSD , '. Rate :',existingRatetoUSD!.rate);
          

        if (existingRatetoUSD) 
        {
          const existingRatefromUSD = this.fxList.find(rate =>
            rate.fromCurrency == 'USD' && rate.toCurrency == toCurrency);

            //console.log('existingRatefromUSD ' ,existingRatefromUSD , '. Rate :',existingRatefromUSD!.rate);

            if(existingRatefromUSD)
            {
              return amount*existingRatetoUSD.rate*existingRatefromUSD?.rate!
            }
            else
            return amount;
        
        } 
        else 
        {
            return amount;
            
        }
   }
   getStars(rating:string):string
   {
     if (rating=='5 star')
       return '*****'
       else if (rating=='4 star')
       return '****'
       else if (rating=='3 star')
         return '***'
       else if (rating=='2 star')
         return '**'
       else if (rating=='1 star')
         return '*'
       else
         return '';
   }

   addReview()
   {
      if(!this.currentUser)
      {
           //-------- If not logged in, ask for user to log-in ------------------
           const dialogRef = this.dialog.open(SignInComponent, 
            {
              width: '300px'
            });
        
            dialogRef.afterClosed().subscribe(result => 
            {
              this.router.navigate(['/home']);
              console.log('The dialog was closed');
            });
      }
      else
      {
        //-------- Check if user is a customer of the vendor -----------------------
        this.vendorDataService.getVendorTransactionsbyUser(this.currentVendor?.idvendor!,this.currentUser.iduser).subscribe(data=>
        {
          this.vendortransactions=data;

          if(this.vendortransactions.length>0)
          {
            this.showAddBlock=true;
          }
          else
          {
            alert('Reviews can be written only for services availed. Kindly contact our helpdesk in case you have purchased this service through SoulsWed');
          }
        })
        
      }
      
   }

   addReviewEntry()
   {
      if(this.reviewText.length>0)
      {
        const review:VendorReviews=
        {
          idvendorreviews:0 ,
          idvendor:this.currentVendor?.idvendor!,
          description: this.reviewText,
          reviewrating:0,
          idUser:this.currentUser?.iduser!,
          username:this.currentUser?.username!,
          usercity:'',
          usercountry:this.currentUser?.idcountry.toString()!
        }
        this.vendorDataService.addReview(review).subscribe(data=>
        {
          this.reviews=data;
        }
        )
        
        this.showAddBlock=false;
        this.reviewText="";
      }
   }

   cancelReview()
  {
    this.showAddBlock=false;
    this.reviewText='';
  }

  editReview(review:VendorReviews)
  {
    this.reviewText=review.description;
    this.idreview=review.idvendorreviews;
    this.showEditBlock=true;
  }

  editReviewEntry()
  {
     const review:VendorReviews=
        {
          idvendorreviews:this.idreview ,
          idvendor:this.currentVendor?.idvendor!,
          description: this.reviewText,
          reviewrating:0,
          idUser:this.currentUser?.iduser!,
          username:this.currentUser?.username!,
          usercity:'',
          usercountry:this.currentUser?.idcountry.toString()!
        }

        this.vendorDataService.updateReview(review).subscribe(data=>
        {
          this.reviews=data;
        })

        this.showEditBlock=false;
        this.reviewText="";
     
  }

  cancelEditReview()
  {
    this.reviewText='';
    this.idreview=0;
    this.showEditBlock=false;

  }

  deleteReview(review:VendorReviews)
  {
    
    this.vendorDataService.deleteReview(review).subscribe(data=>
    {
      this.reviews=data;
    })

  }

  changeImage(imagename: string): void {
    this.selectedImage = `${this.imagesPath}${imagename}`;
  }

  disableManualInput(event: KeyboardEvent) {
    event.preventDefault(); // Prevent manual keyboard input
  }
  
}
