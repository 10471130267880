<div class="full-width-wrapper">
  <div class="container-fluid ">

<app-header2></app-header2>

<!-- user-form.component.html (renamed from user-modal.component.html) -->
<div class="container-fluid mt-3  pt-20 ml-20" >
   
    <div class="row ">
      
      <div class="col-md-6 mb-10">

        <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
          <div class="soulsWedSubTitleFont mt-2 pb-4" style="text-align: center;"><b>User Registration</b></div>

          <div class="row pb-3">
            <div class="col-md-10">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="username">User Type</label>
                  <div class="select-container pt-1">
                    <select id="usertype" formControlName="usertype" class="form-control" (blur)="onBlur('usertype')" [class.is-invalid]="usertype?.invalid && usertype?.touched">
                      <option *ngFor="let type of usertypeList" [value]="type">{{ type }}</option>
                    </select>
                  </div>
                  <div *ngIf="usertype?.invalid && usertype?.touched" class="invalid-feedback">
                    <div *ngIf="usertype?.errors?.['required']">User type is required.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- First row for username and email id-->
          <div class="row ">

            <div class="col-md-6">
              <div class="form-group">
                <label for="username">Name</label>
                <input type="text" id="username" formControlName="username" class="form-control" maxlength="99" (blur)="onBlur('username')" [class.is-invalid]="username?.invalid && username?.touched">
                <div *ngIf="username?.invalid && username?.touched" class="invalid-feedback">
                  <div *ngIf="username?.errors?.['required']">Name is required.</div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="email">Email</label>
                <input type="email" id="email" formControlName="email" class="form-control" maxlength="99" (blur)="onBlur('email')" [class.is-invalid]="email?.invalid && email?.touched">
                <small id="emailHelp" class="form-text text-muted"><i>Your email-id will be your user id for Soulswed</i></small>
                <div *ngIf="email?.invalid && email?.touched" class="invalid-feedback">
                  <div *ngIf="email?.errors?.['required']">Email is required.</div>
                  <div *ngIf="email?.errors?.['email']">Please enter a valid email address.</div>
                </div>
              </div>
            </div>

          </div>

          <!-- Second row for passwords-->
          <div class="row pt-6">

            <div class="col-md-6">
              <div class="form-group">
                <label for="password">Password</label>
                <input type="password" id="password" formControlName="password" class="form-control" (blur)="onBlur('password')" [class.is-invalid]="password?.invalid && password?.touched">
                <small id="pwdHelp" class="form-text text-muted"><i> Password must be at least 8 characters long, include a number and a special character, and not include single or double quotes</i></small>
                <div *ngIf="password?.invalid && password?.touched" class="invalid-feedback">
                  <div *ngIf="userForm.get('password')?.errors && userForm.get('password')?.touched">
                    <p *ngIf="userForm.get('password')?.errors?.['required']">Password is required.</p>
                    <p *ngIf="userForm.get('password')?.errors?.['invalidPassword']">{{userForm.get('password')?.errors?.['invalidPassword'] }}</p>
                  </div>
                </div>  
              </div>
            </div>
           

            <div class="col-md-6">
              <div class="form-group">
                <label for="confirmPassword" class="form-label" >Confirm Password</label>
                <input type="password" class="form-control" id="confirmPassword" formControlName="confirmPassword" (blur)="onBlur('confirmPassword')" [class.is-invalid]="confirmPassword?.invalid && confirmPassword?.touched">
                <div *ngIf="userForm.errors?.['notSame'] && userForm.get('confirmPassword')?.['touched']" style="color: red;font-size: 12px;font-style: italic;">Passwords do not match.</div>
                </div>
            </div>

          </div>
          
          

         <!-- Last row for country and phone -->

         <div class="row pt-6">

          <div class="col-md-4">
            <div class="form-group pt-1">
              <label for="idcountry">Country</label>
              <select id="idcountry" formControlName="idcountry" class="form-control">
                <option *ngFor="let country of countries" [value]="country.idcountry">{{country.countryName}}</option>
              </select>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group pt-1">
              <label for="phonecountrycode">Phone Country Code</label>
              <select id="phonecountrycode" formControlName="phonecountrycode" class="form-control">
                <option *ngFor="let phoneCode of phoneCountryCodes" [value]="phoneCode.phoneCode">{{ phoneCode.countryName}}&nbsp;&nbsp;{{phoneCode.phoneCode }}</option>
              </select>
            </div>
          </div>


          <div class="col-md-4">
            
          <div class="form-group pt-1">
            <label for="phonenumber">Phone Number</label>
            <input type="text" id="phonenumber" formControlName="phonenumber" class="form-control" (blur)="onBlur('phonenumber')" [class.is-invalid]="phonenumber?.invalid && phonenumber?.touched">
            <div *ngIf="phonenumber?.invalid && phonenumber?.touched" class="invalid-feedback">
              <div *ngIf="phonenumber?.errors?.['pattern']">Please enter a valid mobile number.</div>
            </div>
          </div>
          </div>
         </div>

         <div class="d-flex justify-content-center pt-4 mb-6">
          <button type="submit" class="button-style-2 my-3" >Submit</button>
          <button class="button-style-2 my-3 ml-10" (click)="resetForm()">Cancel</button>
         </div>
          

        </form>

      </div>

      

      <div class="col-md-6 p-1 d-flex items-justify-center">
        <img src="../../../assets/images/PageImages/newuser.png">
      </div>
    </div>
  </div>
    

  
  <app-footersection></app-footersection>
</div></div>