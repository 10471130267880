import { CommonModule, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CalendarA11y, CalendarDateFormatter, CalendarEvent, CalendarEventTitleFormatter, CalendarModule, CalendarView } from 'angular-calendar';
import { endOfDay, startOfDay } from 'date-fns';
import { VendorCalendar, VendorSummaryData } from '../../../models/datamodels';
import { VendorDataServiceService } from '../../../services/vendor-data-service.service';
import { GobackComponent } from '../../goback/goback.component';




@Component({
  selector: 'app-managevendorcalendardetails',
  standalone: true,
  imports: [GobackComponent,CommonModule,FormsModule,MatDatepickerModule,MatNativeDateModule,MatFormFieldModule,CalendarModule],
  templateUrl: './managevendorcalendardetails.component.html',
  styleUrl: './managevendorcalendardetails.component.css',
  providers: [DatePipe,CalendarA11y,CalendarDateFormatter,CalendarEventTitleFormatter  ]  // Add DatePipe here
})


export class ManagevendorcalendardetailsComponent implements OnInit
{
  titleText:string=' Calendar';

  currentVendor:VendorSummaryData|null=null;
  calendarList:VendorCalendar[]=[];
  calendarListLocal:VendorCalendar[]=[];
  calendarListLocalFiltered:VendorCalendar[]=[];

  showAddBlock:boolean=false;
  errorMessage:string|null=null;

  //startDate:Date|null=null;
  startDateString:string|null=null;
  startDateStr:string|null=null;
  startTimeString:string|null=null;

 // endDate:Date|null=null;
  endDateString:string|null=null;
  endDateStr:string|null=null;

  endTimeString:string|null=null;
  description:string|null=null;

  minStartDate: string='';
  minEndDate:string='';

  showEditBlock:boolean=false;
  editCalendarid:number|null=null;
  editCalendarVendorid:number|null=null;
  editStartDate:Date|null=null;
  editStartDateString:string|null=null;
  editStartTimeString:string|null=null;
  editEndDate:Date|null=null;
  editEndDateString:string|null=null;
  editEndTimeString:string|null=null;
  editDescription:string|null=null;

  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  events: CalendarEvent[] = [];


  constructor(private vendordataservice:VendorDataServiceService,private datePipe:DatePipe) {}

  ngOnInit(): void 
  {
    this.setMinStartDate();

    this.vendordataservice.currentVendor.subscribe(data=>
    {
      this.currentVendor=data;
      
      this.vendordataservice.getVendorCalendar(data?.idvendor!).subscribe(list=>
      {
        this.calendarList=list;
        this.calendarListLocal=this.convertToLocalTimeArray(this.calendarList);
        this.calendarListLocalFiltered=this.calendarListLocal;

        this.loadBlockedDates();
        console.log('Events :',this.events);
      }
        
      )
    }
      
    );

   
      
  }

 

  loadBlockedDates(): void 
  {
    // Example data: Replace with your data from the backend
    const blocks = [
      { date: new Date('2024-09-01'), hoursBlocked: 2 },
      { date: new Date('2024-09-02'), hoursBlocked: 7 },
      { date: new Date('2024-09-10'), hoursBlocked: 5 },
      { date: new Date('2024-09-10'), hoursBlocked: 2 }
    ];

    this.events = this.calendarListLocal.map(block => 
      {
      let color = '#00ff00'; // Green by default

      /*
      if (block.hoursBlocked > 6) {
        color = '#ff0000'; // Red for more than 6 hours blocked
      } else if (block.hoursBlocked > 0) {
        color = '#ffa500'; // Orange for 1-6 hours blocked
      }*/

      /*return {
        start: startOfDay(block.startDate),
        end: endOfDay(block.endDate),
        title: `${block.description}`,
      color: {  primary: color, secondary: color  }
      };*/

      return {
        start: startOfDay(block.startDate),
        end: endOfDay(block.endDate),
        title: `${block.description}`,
      color: {  primary: color, secondary: color  }
      };
    });
  }

  dayClicked(event:any)
  {
      console.log('Day clicked date:',event.date);
      console.log('Day clicked day:',event.day);
      console.log('Day clicked today:',event.isToday);
      //console.log('Day clicked weekend:',event.events[0].isWeekend);
      console.log('Day clicked full:',event);

      console.log('Array :',this.calendarListLocal);
      console.log('Event date transformed :',this.datePipe.transform(event.date, 'dd-MMM-yyyy, h:mm a'));

      this.calendarListLocalFiltered=this.calendarListLocal.filter(c=>
        c.startDate.substring(0,10)==this.datePipe.transform(event.date, 'dd-MMM-yyyy, h:mm a')?.substring(0,10) || 
          c.endDate.substring(0,10)==this.datePipe.transform(event.date, 'dd-MMM-yyyy, h:mm a')?.substring(0,10) ||
          (new Date(event.date) >= new Date(c.startDate) && new Date(event.date) <= new Date(c.endDate))
      );

     
  }

  clearFilters()
  {
    this.calendarListLocalFiltered=this.calendarListLocal;
    
  }

  setView(view: CalendarView) 
  {
    this.view = view;
  }

  closeOpenMonthViewDay() 
  {
    this.viewDate = new Date();
  }

  setMinStartDate() 
  {
    const now = new Date();
    this.minStartDate = now.toISOString().slice(0, 10);
   // this.minStartDate=this.formatDateForInput(this.minStartDate);
  }

  addEntry()
  {
    this.showAddBlock=true;
  }

  onStartDateChange()
  {
     // If the selected end date is before the new start date, reset the end date
     if (this.endDateString && this.startDateString && new Date(this.endDateString) < new Date(this.startDateString)) 
      {
        this.endDateString = null;
  
      }

      this.minEndDate=new Date(this.startDateString!).toISOString().slice(0, 16);

  }

  combineDateAndTime(date: string, time: string): string 
  {
    return `${date}T${time}`;
  }

  convertUTCToLocal(utcDateTime: string): string 
  {
    const utcDate = new Date(utcDateTime);
    // return utcDate.toLocaleString(); // Converts back to the local time zone
    return this.datePipe.transform(utcDate, 'dd/MM/yyyy, HH:mm:ss')!;
  }

  convertToLocalTimeArray(vendorCalendarArray: VendorCalendar[]): VendorCalendar[] 
  {
    return vendorCalendarArray.map(item => {

      console.log('Start date passed :',item.startDate);
      const localStartDate = this.convertUTCToLocal(item.startDate);
      console.log('Local date got :',localStartDate);
      const [datePart, timePart] = localStartDate.split(', ');
      const [day, month, year] = datePart.split('/').map(part => parseInt(part, 10));
      const [hours, minutes, seconds] = timePart.split(':').map(part => parseInt(part, 10));  
      const startdate = new Date(year, month - 1, day, hours, minutes, seconds);

      const localEndDate = this.convertUTCToLocal(item.endDate);
      const [dateParte, timeParte] = localEndDate.split(', ');
      const [daye, monthe, yeare] = dateParte.split('/').map(part => parseInt(part, 10));
      const [hourse, minutese, secondse] = timeParte.split(':').map(part => parseInt(part, 10));  
      const enddate = new Date(yeare, monthe - 1, daye, hourse, minutese, secondse);
  
      return {
        ...item, // Copy the other fields as is
        startDate: this.datePipe.transform(startdate, 'dd-MMM-yyyy, h:mm a')!,
        endDate: this.datePipe.transform(enddate, 'dd-MMM-yyyy, h:mm a')!
      };
    });
  }



  addCalendarEntry()
  {

    const combinedStartDate= this.combineDateAndTime(this.startDateString!, this.startTimeString!);
    const combinedEndDate=this.combineDateAndTime(this.endDateString!, this.endTimeString!);

    console.log('Combined Start date:',combinedStartDate);
    console.log('Combined End Date: ',combinedEndDate);

    this.startDateStr=new Date(combinedStartDate).toISOString();
    this.endDateStr=new Date(combinedEndDate).toISOString();

    console.log('Start date:',this.startDateStr);
    console.log('End Date: ',this.endDateStr);

    if(!this.startDateStr)
    {
      this.errorMessage='Please select start date';
      return;
    }

    if(!this.endDateStr)
    {
      this.errorMessage='Please select end date';
      return;
    }

    if(new Date(combinedStartDate) >= new Date(combinedEndDate))
    {
      this.errorMessage='End date and time should be greater than start date and time';
      return;
    }

    if(!this.description)
    {
      this.errorMessage='Please enter description';
      return;
    }

    this.errorMessage=null;

   

    const vendorCalendar:VendorCalendar=
    {
        idvendorNonavailabilities:0,
        idvendor:this.currentVendor?.idvendor!,
        startDate:this.startDateStr!,
        endDate:this.endDateStr!,
        description:this.description!
    }

    
    this.vendordataservice.addVendorCalendar(vendorCalendar).subscribe(data=>

      {
        console.log('Returned response :',data);
        
        if(data==='Success')
        {
            alert('Calendar entry added successfully !!!');

            this.showAddBlock=false;
            this.startDateString=null;
            this.startTimeString=null;
            this.endDateString=null;
            this.endTimeString=null;
            this.description=null;

            this.vendordataservice.getVendorCalendar(this.currentVendor?.idvendor!).subscribe(list=>
              {
                this.calendarList=list;
                this.calendarListLocal=this.convertToLocalTimeArray(this.calendarList);
                this.calendarListLocalFiltered=this.calendarListLocal;
                this.loadBlockedDates();
              }
            )
        }
        else
        {
          alert('Failed to add :'+data);
        }

      }
    )

    
  }

  cancelAdd()
  {
      this.showAddBlock=false;
      this.startDateString=null;
      this.endDateString=null;
      this.description=null;
  }



  editEntry(calendar:VendorCalendar)
  {
    this.editCalendarid=calendar.idvendorNonavailabilities;
    this.editCalendarVendorid=calendar.idvendor;
    this.editStartDateString=this.formatDateForInput(calendar.startDate);
    this.editStartTimeString=this.formatTimeForInput(calendar.startDate);
    this.editEndDateString=this.formatDateForInput(calendar.endDate);
    this.editEndTimeString=this.formatTimeForInput(calendar.endDate);
    this.editDescription=calendar.description;

    this.showEditBlock=true;
  }

  formatDateForInput(dateString: string): string 
  {
    const [datePart, timePart] = dateString.split(', ');
    const [day, monthStr, year] = datePart.split('-');

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames.indexOf(monthStr) + 1;

    return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
  }

  formatTimeForInput(dateString: string): string 
  {
    const [datePart, timePart] = dateString.split(', ');

    const [time, period] = timePart.split(' ');
    let [hours, minutes] = time.split(':').map(Number);

    if (period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  }

  editCalendarEntry()
  {

    const combinedStartDate= this.combineDateAndTime(this.editStartDateString!, this.editStartTimeString!);
    const combinedEndDate=this.combineDateAndTime(this.editEndDateString!, this.editEndTimeString!);

    console.log('Combined Start date:',combinedStartDate);
    console.log('Combined End Date: ',combinedEndDate);

    this.startDateStr=new Date(combinedStartDate).toISOString();
    this.endDateStr=new Date(combinedEndDate).toISOString();

    console.log('Start date:',this.startDateStr);
    console.log('End Date: ',this.endDateStr);

    if(!this.startDateStr)
    {
      this.errorMessage='Please select start date';
      return;
    }

    if(!this.endDateStr)
    {
      this.errorMessage='Please select end date';
      return;
    }

    if(new Date(combinedStartDate) >= new Date(combinedEndDate))
      {
        this.errorMessage='End date-time should be greater than start date-time';
        return;
      }

    if(!this.editDescription)
    {
      this.errorMessage='Please enter description';
      return;
    }

    this.errorMessage=null;

    const vendorCalendar:VendorCalendar=
    {
        idvendorNonavailabilities:this.editCalendarid!,
        idvendor:this.editCalendarVendorid!,
        startDate:this.startDateStr,
        endDate:this.endDateStr,
        description:this.editDescription!
    }

    this.vendordataservice.updateVendorCalendar(vendorCalendar).subscribe(data=>
    {
          console.log('Returned response :',data);
          
          if(data==='Success')
          {
              alert('Calendar entry updated successfully !!!');
  
              this.showEditBlock=false;
              this.editStartDate=null;
              this.editEndDate=null;
              this.editDescription=null;
  
              this.vendordataservice.getVendorCalendar(this.currentVendor?.idvendor!).subscribe(list=>
              {
                this.calendarList=list;
                this.calendarListLocal=this.convertToLocalTimeArray(this.calendarList);
                this.calendarListLocalFiltered=this.calendarListLocal;
                this.loadBlockedDates();
              }
                
              )
          }
          else
          {
            alert('Update failed :'+data);
          }
  
        }
      )

  }
  

  cancelEdit()
  {
    this.showEditBlock=false;
      this.editStartDateString=null;
      this.editStartTimeString=null;
      this.editEndDateString=null;
      this.editEndTimeString=null;
  }


  deleteCalendarEntry(calendar:VendorCalendar)
  {
     
      this.vendordataservice.deleteVendorCalendar(calendar).subscribe(data=>
      {
          console.log('Returned response :',data);
          
          if(data==='Success')
          {
              alert('Calendar entry deleted successfully !!!');
  
              this.vendordataservice.getVendorCalendar(this.currentVendor?.idvendor!).subscribe(list=>
                {
                  this.calendarList=list;
                  this.calendarListLocal=this.convertToLocalTimeArray(this.calendarList);
                  this.calendarListLocalFiltered=this.calendarListLocal;
                  this.loadBlockedDates();
                }
              )
          }
          else
          {
            alert('Delete failed :'+data);
          }
  
        }
      )

    

  }

  convertToUTC(date: Date): Date {
    // Convert the date to UTC
    const utcDate = new Date(Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    ));
  
    // Format the UTC date as a string if needed
    return utcDate;
  }

  disableManualInput(event: KeyboardEvent) {
    event.preventDefault(); // Prevent manual keyboard input
  }
  
  

}
